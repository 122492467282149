import { checkNamespace } from 'helpers';
import { MortgageInterface, MortgageState } from './../../mortgage/types';
import * as actionTypes from '../actions/mortgage';
import { getDerivedMortgage } from 'redux/selectors/mortgage';

export const initialMortgageState: MortgageState = {
    previousReports: [],
    previousReportsLoading: false,
    currentReportId: '',
    currentReport: { mortgages: [{ expanded: true }], discountRate: 0.05, },
};

export default function (state = initialMortgageState, action): MortgageState {
    if (!checkNamespace(action, 'mortgage')) return state;

    switch (action.type) {
        case actionTypes.RESET_REPORT: {
            return initialMortgageState;
        }
        case actionTypes.FETCH_MORTGAGE_REPORTS: {
            return {
                ...state,
                previousReportsLoading: true,
            };
        }
        case actionTypes.FETCH_MORTGAGE_REPORTS_SUCCEEDED: {
            const { data } = action.payload;
            return {
                ...state,
                previousReports: [...data],
                previousReportsLoading: false,
            };
        }
        case actionTypes.FETCH_MORTGAGE_REPORTS_FAILED: {
            return {
                ...state,
                previousReportsLoading: false,
            };
        }
        case actionTypes.POPULATE_MORTGAGE_CARD: {
            const { reportId } = action.payload;
            if (!reportId || reportId.length === 0) {
                return initialMortgageState;
            } else if (reportId === state.currentReportId) {
                return { ...state };
            } else {
                // const previousLst = state.previousReports;
                // const reportInfo: MortgageReportInterface = _.find(previousLst, { _id: reportId });
                // if (!reportInfo) {
                //     return initialMortgageState;
                // }
                return {
                    ...state,
                    // reportTitle: reportInfo.reportTitle,
                    // currentReportId: reportId,
                };
            }
        }
        case actionTypes.SAVE_REPORT_SUCCEEDED: {
            const { id } = action.payload.data;
            return {
                ...state,
                currentReportId: id,
            };
        }
        case actionTypes.ADD_NEW_MORTGAGE: {
            const currentReport = state.currentReport;
            let newMortgages: MortgageInterface[] = currentReport?.mortgages == null ? [] : currentReport.mortgages;

            newMortgages = newMortgages.map((mortgage) => ({
                ...mortgage,
                expanded: false,
            }));

            if (newMortgages.length > 0 && newMortgages[newMortgages.length - 1].purchasePrice !== 0) {
                const purchasePrice = newMortgages[newMortgages.length - 1].purchasePrice;
                newMortgages = [...newMortgages, { expanded: true, purchasePrice } ];
            } else {
                newMortgages = [...newMortgages, { expanded: true } ];
            }

            return {
                ...state,
                currentReport: {
                    ...currentReport,
                    mortgages: newMortgages,
                },
            };
        }
        case actionTypes.REMOVE_MORTGAGE: {
            const currentReport = state.currentReport;
            const { index } = action.payload;
            if (currentReport.mortgages == null) {
                return state;
            }
            return {
                ...state,
                currentReport: {
                    ...currentReport,
                    mortgages: [
                        ...currentReport.mortgages?.slice(0, index),
                        ...currentReport.mortgages?.slice(index + 1)
                    ],
                },
            };
        }
        case actionTypes.UPDATE_MORTGAGE: {
            const { index, mortgage } = action.payload;
            const currentReport = state.currentReport;
            const maxYears = currentReport.mortgages == null ? 0 : currentReport.mortgages.reduce((max, mortgage) => Math.max(max, mortgage.amortizedYears != null ? mortgage.amortizedYears : 0), 0);
            const newMortgages =
                currentReport?.mortgages == null
                    ? [mortgage]
                    : currentReport.mortgages.map((_mortgage, _index) => {
                        if (_index === index) {
                            return getDerivedMortgage({ ..._mortgage, ...mortgage }, index, maxYears);
                        } else {
                            return _mortgage;
                        }
                    });
            return {
                ...state,
                currentReport: {
                    ...currentReport,
                    mortgages: newMortgages,
                },
            };
        }
        case actionTypes.TOGGLE_MORTGAGE_EXPANDED: {
            const { index, expanded } = action.payload;
            return {
                ...state,
                currentReport: {
                    ...state.currentReport,
                    mortgages: state.currentReport.mortgages?.map((_mortgage, _index) => {
                        if (_index === index) {
                            return { ..._mortgage, expanded };
                        } else {
                            return _mortgage;
                        }
                    }),
                }
            };
        }
        case actionTypes.UPDATE_DISCOUNT_RATE: {
            const { discountRate } = action.payload;
            return {
                ...state,
                currentReport: {
                    ...state.currentReport,
                    discountRate,
                }
            };
        }
        default:
            return state;
    }
}
