import React from 'react';
import { connect } from 'react-redux';
import { Row, Button, Tooltip, notification } from 'antd';
import { AppState } from 'redux/store';
import { withRouter, RouteComponentProps } from 'react-router';
import SignInOnlyButton from 'components/SignInOnlyButton';
import WatchlistButton from 'components/WatchlistButton';

interface Props extends StoreState, RouteComponentProps {
    reportId?: string;
    graphqlId?: string;
    mobile?: boolean;
    onSave?: any;
    onReset?: any;
    applyDefault: any;
    watchlistStatus: string | null;
    enableRentalTypeButton?: boolean;
    rentalTypeLabel?: string;
    onChangeRentalType?: any;
    enableBRRRRButton?: boolean;
    brrrrLabel?: string;
    onChangeBRRRR?: any;
}

declare const location;

const ReportButtons = (props: Props) => {
    const onShare = () => {
        if (props.reportId) {
            const url = location.origin + '/rental/shared/' + props.reportId;
            const el = document.createElement('textarea');
            el.value = url;
            el.setAttribute('readonly', '');
            el.style.height = '0px';
            el.style.width = '0px';
            el.style.display = ' hidden';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            notification.success({
                message: 'Shareable link copied!',
                description: <a href={url}>{url}</a>,
                placement: 'bottomRight',
                style: { wordWrap: 'break-word' },
                bottom: 80,
            });
        }
    };

    const loggedIn = props.firebaseAuth.isLoaded && !props.firebaseAuth.isEmpty;

    return (
        <Row
            className="report-buttons"
            justify="end"
        >
            {props.enableRentalTypeButton && (
                <Button className="report-button" onClick={props.onChangeRentalType}>
                    {props.rentalTypeLabel}
                </Button>
            )}
            {/* {props.enableBRRRRButton && (
                <Button className="report-button" onClick={props.onChangeBRRRR}>
                    {props.brrrrLabel}
                </Button>
            )} */}
            {loggedIn && (
                <Tooltip title="You can change your default strategy in your user profile">
                    <Button className="report-button" onClick={props.applyDefault}>
                        Apply Default
                    </Button>
                </Tooltip>
            )}
            <Button className="report-button" onClick={props.onReset}>
                Reset Report
            </Button>
            <SignInOnlyButton className="report-button" onClick={onShare}>
                Share Report
            </SignInOnlyButton>
            {loggedIn && (
                <WatchlistButton reportId={props.reportId} graphqlId={props.graphqlId} watchlistStatus={props.watchlistStatus} />
            )}
            <SignInOnlyButton type="primary" className="report-button" onClick={props.onSave}>
                Save Report
            </SignInOnlyButton>
        </Row>
    );
};

interface StoreState {
    firebaseAuth: any;
}

const mapStateToProps = (state: AppState): StoreState => ({
    firebaseAuth: state.firebase.auth,
});

export default connect(mapStateToProps)(withRouter(ReportButtons));
