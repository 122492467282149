import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button } from 'antd';
import { getMonthlyExpensesInfo } from 'redux/selectors';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/budget';
import { mapPropsToFields, transformFormFieldsToExpenses, transformExpensesToFormFields } from 'helpers';
import { connect } from 'react-redux';
import { expensesFormWrapper, ExpensesStoreState, ExpensesDispatch } from './ExpensesFormWrapper';
import FormInputNumber from 'components/FormInputNumber';
import { budgetDisabledStyle } from 'consts/budget';
import { getExpenseFormItems, getOnClickAdd } from './ExpenseFormItems';

interface MonthlyExpensesFormProps extends WrappedMonthlyExpensesFormProps, ExpensesStoreState, ExpensesDispatch {}

const MonthlyExpenses = (props: MonthlyExpensesFormProps) => {
    const [form] = Form.useForm();
    const getFields = () => {
        const fields = mapPropsToFields(transformExpensesToFormFields(props.expensesInfo.expenses));
        fields.push({ name: ['totalExpenses'], value: props.expensesInfo.totalExpenses });
        return fields;
    };

    return (
        <Form
            layout="vertical"
            form={form}
            fields={getFields()}
            onValuesChange={(changedValues) => {
                props.setExpenses(transformFormFieldsToExpenses(props.expensesInfo.expenses, changedValues));
            }}
            scrollToFirstError
        >
            <Row>
                <h2 style={{ float: 'left' }}>Monthly Expenses</h2>
            </Row>

            {getExpenseFormItems(props)}

            <Row style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Col
                    lg={{
                        span: 12,
                        offset: 3,
                    }}
                    sm={24}
                >
                    <Button type="dashed" onClick={getOnClickAdd(form, props.add)}>
                        <PlusOutlined /> Add Expense Item
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item label="Total Monthly Expenses" name="totalExpenses">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

interface WrappedMonthlyExpensesFormProps {
    expenses: any;
    remove: any;
    add: any;
    expensesLength: number;
    handleFormChange: any;
}

const mapStateToProps = (state: AppState): ExpensesStoreState => ({
    expensesInfo: getMonthlyExpensesInfo(state),
});

const mapDispatchToProps = (dispatch): ExpensesDispatch => {
    return {
        setExpenses: (annnualExpenses) => {
            dispatch(actions.setMonthlyExpenses(annnualExpenses));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(expensesFormWrapper(MonthlyExpenses));
