import { Form, Col } from 'antd';
import _ from 'lodash';
import React from 'react';
import BasicRow from './components/BasicRow';
import FormInputNumber from './components/FormInputNumber';
import { stateIncomeTaxBuckets, stateIncomeTaxStandardDeduction } from 'consts/budget';

export function round(num?: any) {
    if (!num || Number.isNaN(num)) {
        return 0;
    } else {
        try {
            const converted = +num.toFixed(2);
            return converted;
        } catch (e) {
            return 0;
        }
    }
}

export interface FieldData {
    name: string[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

export function mapPropsToFields(data): FieldData[] {
    const fields: FieldData[] = [];
    if (data == null) {
        return fields;
    }
    for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'object' && value != null) {
            for (const [nestedKey, nestedValue] of Object.entries(value)) {
                fields.push({
                    name: [nestedKey],
                    value: nestedValue,
                });
            }
        } else {
            fields.push({
                name: [key],
                value: value,
            });
        }
    }
    return fields;
}

export function createFormFromArray(nameArray, numColsPerRow) {
    // if (24 % numColsPerRow != 0) {
    //     throw "num of cols per row should be divisible by 24";
    // }
    const arrayLen = nameArray.length;
    const numRows = Math.ceil(arrayLen / numColsPerRow);
    const colLen = 24 / numColsPerRow;
    let indexOfArray = 0;
    const res: any[] = [];
    for (let r = 0; r < numRows; r++) {
        const oneRow: any[] = [];
        for (let c = 0; c < numColsPerRow; c++, indexOfArray++) {
            if (indexOfArray < arrayLen) {
                const labelName = nameArray[indexOfArray];
                const name = _.camelCase(labelName);
                oneRow.push(
                    <Col span={colLen} key={c}>
                        <Form.Item label={labelName} name={name}>
                            <FormInputNumber dollar width="145px" />
                        </Form.Item>
                    </Col>,
                );
            } else {
                oneRow.push(<Col span={colLen} key={c} />);
            }
        }
        res.push(<BasicRow key={r}>{oneRow}</BasicRow>);
    }
    return res;
}

export function getColorForStatus(status) {
    switch (status) {
        case 'success':
            return '#52c41a';
        case 'warning':
            return '#faad14';
        case 'failed':
            return '#f5222d';
        default:
            return 'rgba(0, 0, 0, 0.85)';
    }
}

export function getStatusForCashflow(cashflow) {
    if (cashflow < 100) {
        return 'failed';
    } else if (cashflow < 200) {
        return 'warning';
    } else {
        return 'success';
    }
}

export function addThousandSeparatorForNumber(num) {
    return ('' + num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getStatusForCOC(coc) {
    if (coc < 5) {
        return 'failed';
    } else if (coc < 7) {
        return 'warning';
    } else {
        return 'success';
    }
}

export function getStatusForRTP(rtp) {
    if (rtp < 0.085) {
        return 'failed';
    } else if (rtp < 0.1) {
        return 'warning';
    } else {
        return 'success';
    }
}

export function checkNamespace(action, namespace) {
    return action.type.split('/')[0] === namespace;
}

export const transformExpensesToFormFields = (expenses: { key?: string; value?: number }[]) => {
    if (!expenses) return {};
    const transformed = {};
    for (let i = 0; i < expenses.length; i++) {
        transformed[`keys_${i}`] = expenses[i].key;
        transformed[`values_${i}`] = expenses[i].value;
    }
    return transformed;
};

export const transformFormFieldsToExpenses = (expenses, changedValues) => {
    const transformed = [...expenses];

    _.forEach(changedValues, (fieldValue, fieldKey) => {
        if (fieldKey.startsWith('keys_')) {
            const i = parseInt(fieldKey.split('keys_')[1]);
            transformed[i].key = fieldValue;
        } else if (fieldKey.startsWith('values_')) {
            const i = parseInt(fieldKey.split('values_')[1]);
            transformed[i].value = Number(fieldValue);
        }
    });
    return transformed;
};

export const calculateIncomeTaxForState = (state: string, income: number): number => {
    const adjustedIncome = Math.max(income - stateIncomeTaxStandardDeduction[state], 0);
    const taxBuckets = stateIncomeTaxBuckets[state];
    let totalTax = 0;
    for (let i = 1; i < taxBuckets.length; i++) {
        const bracket = taxBuckets[i];
        if (adjustedIncome < bracket.cap || bracket.cap === -1) {
            return totalTax + (adjustedIncome - taxBuckets[i - 1].cap) * bracket.rate;
        } else {
            totalTax += (bracket.cap - taxBuckets[i - 1].cap) * bracket.rate;
        }
    }
    return totalTax;
};

export const getTimeAgoString = (time) => {
    const diff = new Date().getTime() / 1000 - time;
    if (diff < 24 * 60 * 60) {
        return 'today';
    } else if (diff < 30 * 24 * 60 * 60) {
        return 'about ' + Math.round(diff / (24 * 60 * 60)) + ' days ago';
    } else if (diff < 2 * 30 * 24 * 60 * 60) {
        return 'about 1 month ago';
    } else {
        return 'about ' + Math.round(diff / (30 * 24 * 60 * 60)) + ' months ago';
    }
};

export const formatDollar = (number) => {
    return '$' + round(number).toLocaleString();
};

export const formatDollarRounded = (number) => {
    return '$' + Math.round(number).toLocaleString();
};

export const formatPercentage = (number) => {
    return round(number * 100) + '%';
};

export const states = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

export enum WindowWidth {
    XS,
    SM,
    MD,
    LG,
}

export const getWindowWidth = (window): WindowWidth => {
    if (window.innerWidth >= 1500) {
        return WindowWidth.LG;
    } else if (window.innerWidth >= 767) {
        return WindowWidth.MD;
    } else if (window.innerWidth >= 480) {
        return WindowWidth.SM;
    } else {
        return WindowWidth.XS;
    }
};

export const getWindowWidthPx = (window): number => {
    return window.innerWidth;
};

export const unwrapPropertyInfo = ({
    reportTitle, address, city, state, commnity, county, zipcode, propertyType, annualTax, mlsNumber, photos, description, bedrooms, bathrooms, totalSqft, lotSize, yearBuilt, yearRenovated, units, stories, countyAppraisedValue, pool, heating, cooling, fireplace, garage, construction, roofing, flooringTypes, wiringCondition, plumbingCondition, sidingMaterial, otherInformation, location, }) => ({
    reportTitle, address, city, state, commnity, county, zipcode, propertyType, annualTax, mlsNumber, photos, description, bedrooms, bathrooms, totalSqft, lotSize, yearBuilt, yearRenovated, units, stories, countyAppraisedValue, pool, heating, cooling, fireplace, garage, construction, roofing, flooringTypes, wiringCondition, plumbingCondition, sidingMaterial, otherInformation, location });

export const unwrapPurchaseInfo = ({
    purchasePrice, afterRepairValue, closingCost, repairCost, isCashPurchase, downPayment, loanInterestRate, pointsChargedByLender, otherChargesFromLender, wrapLoanFeesIntoLoan, isInterestOnly, amortizedYears, typicalCapRate, listingPrice
}) => ({
    purchasePrice, afterRepairValue, closingCost, repairCost, isCashPurchase, downPayment, loanInterestRate, pointsChargedByLender, otherChargesFromLender, wrapLoanFeesIntoLoan, isInterestOnly, amortizedYears, typicalCapRate, listingPrice });


export const unwrapRentalInfo = ({
    monthlyRent,
    dailyRate,
    occupancyRate,
    otherMonthlyIncome,
    electricity,
    waterAndSewer,
    pmi,
    garbage,
    hoa,
    monthlyInsurance,
    otherMonthlyExpenses,
    vacancy,
    maintenance,
    capex,
    managementFees,
    cleaning,
    supplies,
    internet,
    annualIncomeGrowth,
    annualPropertyValueGrowth,
    annualExpensesGrowth,
    salesExpenses,
}) => ({
    monthlyRent,
    dailyRate,
    occupancyRate,
    otherMonthlyIncome,
    electricity,
    waterAndSewer,
    pmi,
    garbage,
    hoa,
    monthlyInsurance,
    otherMonthlyExpenses,
    vacancy,
    maintenance,
    capex,
    managementFees,
    cleaning,
    supplies,
    internet,
    annualIncomeGrowth,
    annualPropertyValueGrowth,
    annualExpensesGrowth,
    salesExpenses,
});

export const unwrapClosingCostBreakdown = ({
    points, prepaidHazardInsurance, prepaidFloodInsurance, prepaidPropertyTaxes, annualAssesements, titleAndEscrowFees, attorneyCharges, inspectionCosts, recordingFees, appraisalFees, otherFees, totalClosingCost,
}) => ({
    points, prepaidHazardInsurance, prepaidFloodInsurance, prepaidPropertyTaxes, annualAssesements, titleAndEscrowFees, attorneyCharges, inspectionCosts, recordingFees, appraisalFees, otherFees, totalClosingCost,
});

export const unwrapRepairCostBreakdown = ({
    roof, concrete, guttersSoffitFascia, garage, siding, landscaping, exteriorPainting, septic, decks, foundation, demo, sheerstock, plumbing, carpentryWindowsDoors, electrical, interiorPainting, hvac, cabinetsCounterTops, framing, flooring, permits, termites, mold, miscellaneous, totalRepairCost,
}) => ({
    roof, concrete, guttersSoffitFascia, garage, siding, landscaping, exteriorPainting, septic, decks, foundation, demo, sheerstock, plumbing, carpentryWindowsDoors, electrical, interiorPainting, hvac, cabinetsCounterTops, framing, flooring, permits, termites, mold, miscellaneous, totalRepairCost,
});
