import { AnnualRepaymentInterface } from './../../rental/types';
import { MortgageCalculationsInterface } from './../../mortgage/types';
import { MortgageInterface } from 'mortgage/types';
import { createSelector } from 'reselect';
import { checkNumber } from './rental';
import { MonthlyRepaymentInterface } from 'rental/types';

const getCurrentMortgage = (mortgage: MortgageInterface, index: number, maxYears, discountRate) => {
    return { ...mortgage, index, maxYears, discountRate };
};

const getLoanCalculations = createSelector(
    getCurrentMortgage,
    (mortgage): MortgageCalculationsInterface | null => {
        if (mortgage == null) {
            return null;
        }
        const purchasePrice = checkNumber(mortgage.purchasePrice);
        const years = checkNumber(mortgage.amortizedYears);
        if (mortgage.isCashPurchase || years === 0) {
            return { totalCash: purchasePrice };
        }

        let payment = purchasePrice;
        const fees = checkNumber(mortgage.fees);
        const points = checkNumber(mortgage.pointsChargedByLender) / 100;
        if (mortgage.wrapLoanFeesIntoLoan) {
            payment += fees;
        }

        const downPaymentAmount = payment * (checkNumber(mortgage.downPayment) / 100);
        const loanAmount = payment - downPaymentAmount;

        const interestRate = checkNumber(mortgage.loanInterestRate) / 100;
        const monthlyInterestRate = interestRate / 12;
        const numberOfPayments = years * 12;
        const discount =
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1) /
            (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments));

        const monthlyMortgage = loanAmount / discount;
        const monthlyRepaymentSchedule = getMonthlyRepaymentSchedule(
            loanAmount,
            monthlyInterestRate,
            numberOfPayments,
            monthlyMortgage,
        );

        const apr = years === 0 ? 0 : interestRate + (fees + points * purchasePrice) / loanAmount / years;

        const closingCosts = points * loanAmount + (mortgage.wrapLoanFeesIntoLoan ? 0 : fees);
        const totalCash = downPaymentAmount + closingCosts;

        return {
            loanAmount,
            monthlyPayments: Math.round(monthlyMortgage * 10) / 10,
            totalCash: Math.round(totalCash),
            monthlyRepaymentSchedule,
            annualRepaymentSchedule: getAnnualRepaymentSchedule(monthlyRepaymentSchedule),
            apr: Math.round(apr * 100 * 1000) / 1000,
            closingCosts: Math.round(closingCosts * 10) / 10,
            downPaymentAmount: Math.round(downPaymentAmount * 10) / 10,
        };
    },
);

const getTitle = createSelector([getCurrentMortgage, getLoanCalculations], (mortgage, calculations) => {
    if (mortgage.mortgageName != null && mortgage.mortgageName.length > 0) {
        return mortgage.mortgageName;
    } else {
        let title = '';
        if (mortgage.amortizedYears) {
            title += 'Fixed ' + mortgage.amortizedYears + ' years';
        }
        if (mortgage.downPayment) {
            if (title.length > 0) {
                title += ', ';
            }
            title += mortgage.downPayment + '% Down';
        }
        if (calculations?.apr) {
            if (title.length > 0) {
                title += ', ';
            }
            title += 'APR ' + calculations.apr + '%';
        }
        if (title.length === 0) {
            title = 'Mortgage ' + (mortgage.index + 1);
        }
        return title;
    }
});

const getMonthlyRepaymentSchedule = (
    loanAmount: number,
    monthlyRate: number,
    numberOfPayments: number,
    monthlyPayment: number,
): MonthlyRepaymentInterface[] => {
    let balance = loanAmount;
    const schedule: MonthlyRepaymentInterface[] = [];
    for (let month = 1; month <= numberOfPayments; month++) {
        const interest = +(balance * monthlyRate).toFixed(2);
        const principal = +Math.min(monthlyPayment - interest, balance).toFixed(2);
        balance -= principal;
        schedule.push({ month, interest, principal, balance });
    }
    return schedule;
};

const getAnnualRepaymentSchedule = (repaymentSchedule: MonthlyRepaymentInterface[]) : AnnualRepaymentInterface[] => {
    return repaymentSchedule.reduce((schedule, monthlyRepayment) => {
        if ((monthlyRepayment.month + 11) % 12 === 0) {
            return [...schedule, { year: Math.ceil(monthlyRepayment.month + 11) / 12, interest: monthlyRepayment.interest, principal: monthlyRepayment.principal, balance: monthlyRepayment.balance }];
        } else {
            return schedule.map((repayment, index) => {
                if (index < schedule.length - 1) {
                    return repayment;
                } else {
                    return {
                        year: repayment.year,
                        interest: repayment.interest + monthlyRepayment.interest,
                        principal: repayment.principal + monthlyRepayment.principal,
                        balance: monthlyRepayment.balance
                    };
                }
            });
        }
    }, [] as AnnualRepaymentInterface[]);
};

export const getDerivedMortgage = createSelector([getCurrentMortgage, getLoanCalculations, getTitle], (mortgage, loanDetails, title) => {
    return {
        ...mortgage,
        ...loanDetails,
        title,
        calculations: loanDetails,
    };
});