/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Report = {
    readonly propertyUrl: string | null;
    readonly isShortTermRental: boolean | null;
    readonly isBRRRR: boolean | null;
    readonly propertyInfo: {
        readonly reportTitle: string | null;
        readonly address: string | null;
        readonly city: string | null;
        readonly state: string | null;
        readonly zipcode: string | null;
        readonly bedrooms: number | null;
        readonly bathrooms: number | null;
        readonly totalSqft: number | null;
        readonly annualTax: number | null;
    } | null;
    readonly purchaseInfo: {
        readonly purchasePrice: number | null;
        readonly listingPrice: number | null;
        readonly closingCost: number | null;
        readonly repairCost: number | null;
        readonly isCashPurchase: boolean | null;
        readonly pointsChargedByLender: number | null;
        readonly wrapLoanFeesIntoLoan: boolean | null;
        readonly otherChargesFromLender: number | null;
        readonly loanInterestRate: number | null;
        readonly downPayment: number | null;
        readonly amortizedYears: number | null;
        readonly afterRepairValue: number | null;
    } | null;
    readonly rentalInfo: {
        readonly monthlyRent: number | null;
        readonly dailyRate: number | null;
        readonly occupancyRate: number | null;
        readonly otherMonthlyIncome: number | null;
        readonly vacancy: number | null;
        readonly capex: number | null;
        readonly maintenance: number | null;
        readonly managementFees: number | null;
        readonly monthlyInsurance: number | null;
        readonly pmi: number | null;
        readonly hoa: number | null;
        readonly electricity: number | null;
        readonly waterAndSewer: number | null;
        readonly garbage: number | null;
        readonly internet: number | null;
        readonly cleaning: number | null;
        readonly supplies: number | null;
        readonly otherMonthlyExpenses: number | null;
        readonly annualExpensesGrowth: number | null;
        readonly annualIncomeGrowth: number | null;
        readonly annualPropertyValueGrowth: number | null;
        readonly salesExpenses: number | null;
    } | null;
    readonly " $refType": "Report";
};
export type Report$data = Report;
export type Report$key = {
    readonly " $data"?: Report$data;
    readonly " $fragmentRefs": FragmentRefs<"Report">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isShortTermRental",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBRRRR",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportPropertyInfo",
      "kind": "LinkedField",
      "name": "propertyInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reportTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bedrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bathrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSqft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualTax",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportPurchaseInfo",
      "kind": "LinkedField",
      "name": "purchaseInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "purchasePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "listingPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closingCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repairCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCashPurchase",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pointsChargedByLender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wrapLoanFeesIntoLoan",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherChargesFromLender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loanInterestRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downPayment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amortizedYears",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "afterRepairValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportRentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyRent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dailyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "occupancyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherMonthlyIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vacancy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maintenance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managementFees",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyInsurance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pmi",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hoa",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "electricity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waterAndSewer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "garbage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cleaning",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supplies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherMonthlyExpenses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualExpensesGrowth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualIncomeGrowth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualPropertyValueGrowth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesExpenses",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RentalReport",
  "abstractKey": null
};
(node as any).hash = 'd655316d682393ab0f0a4c15ebebb87c';
export default node;
