import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Input, InputNumber, Form } from 'antd';
import _ from 'lodash';
import { FormInstance } from 'antd/lib/form';

const InputGroup = Input.Group;

export const getLiabilitiesFormItems = ({ liabilitiesLength, removeLiability }: { liabilitiesLength: number; removeLiability }) => {
    return _.range(liabilitiesLength).map((id) => (
        <Row justify="space-between" key={id} style={{ marginBottom: '12px' }}>
            <Col lg={12} sm={16} xs={20}>
                <InputGroup compact style={{ float: 'left' }}>
                    <Form.Item
                        name={`liability_keys_${id}`}
                        rules={[{ required: true, message: 'Please enter liability category' }]}
                        noStyle
                    >
                        <Input className="expense-input-group-left" placeholder="Liability category" />
                    </Form.Item>

                    <Form.Item
                        name={`liability_values_${id}`}
                        rules={[{ required: true, message: 'Please enter liability amount' }]}
                        noStyle
                    >
                        <InputNumber
                            className="expense-input-group-right"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => (value == null ? '' : value.replace(/\$\s?|(,*)/g, ''))}
                        />
                    </Form.Item>
                </InputGroup>
            </Col>
            <Col span={4}>
                <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ float: 'right', marginTop: '8px' }}
                    onClick={() => removeLiability(id)}
                />
            </Col>
        </Row>
    ));
};

export const getOnClickAddLiability = (form: FormInstance, addLiability: () => void) => {
    return () => {
        form.validateFields()
            .then(() => {
                addLiability();
            })
            .catch((errors) => {
                form.scrollToField(errors.errorFields[0].name);
            });
    };
};
