import React from 'react';
import { ContainerOutlined, DeleteOutlined } from '@ant-design/icons';
import { Card, Row, Col, Button, message, Timeline } from 'antd';
import { formatDollar, round, WindowWidth } from 'helpers';
import { getColorForStatus, getStatusForRTP, addThousandSeparatorForNumber } from 'helpers';
import graphql from 'babel-plugin-relay/macro';
import { useFragment, commitMutation, ConnectionHandler, commitLocalUpdate } from 'react-relay';
import { RedfinEmailListingItemFragment$key } from './__generated__/RedfinEmailListingItemFragment.graphql';
import { RedfinEmailListingItemDeleteMutation } from './__generated__/RedfinEmailListingItemDeleteMutation.graphql';
import { RedfinEmailListingItemSetArchivedMutation } from './__generated__/RedfinEmailListingItemSetArchivedMutation.graphql';
import RelayEnvironment from '../RelayEnvironment';
import { useContext } from 'react';
import { WindowWidthContext } from 'AppLayout';
import environment from '../RelayEnvironment';
import { getRedfinStatusColor } from 'components/RedfinStatusTag';

interface Props {
    queryData: RedfinEmailListingItemFragment$key;
    connectionID?: string;
    showArchived: boolean;
}

const fragment = graphql`
    fragment RedfinEmailListingItemFragment on RedfinEmailListing {
        _id
        id
        address
        city
        state
        zipcode
        listingPrice
        status
        url
        date
        totalSqft
        bedrooms
        bathrooms
        estimatedRent
        rentToPrice
        photo
        filterLabel
        archived
        listingHistory {
            status
            listingPrice
            date
        }
    }
`;

const deleteMutation = graphql`
    mutation RedfinEmailListingItemDeleteMutation(
        $id: MongoID!,
        $connections: [ID!]!,
    ) {
        RedfinEmailListingRemoveById(input: {_id: $id }) {
            recordId
            record {
                id @deleteEdge(connections: $connections)
            }
        }
    }
`;

const setArchivedMutation = graphql`
    mutation RedfinEmailListingItemSetArchivedMutation(
        $id: MongoID!,
        $archived: Boolean,
    ) {
        RedfinEmailListingUpdateById(input: {
            _id: $id, 
            record: { archived: $archived }
        }) {
            recordId
            record {
                id
                archived
            }
        }
    }
`;

const RedfinEmailListingItem = (props: Props) => {
    const windowWidth = useContext(WindowWidthContext);

    const listing = useFragment(fragment, props.queryData);

    let formattedAddress = listing.address;
    if (listing.city != null && listing.city.length > 0) {
        formattedAddress += ', ' + listing.city;
    }
    if (listing.state != null && listing.state.length > 0) {
        formattedAddress += ', ' + listing.state;
    }
    if (listing.zipcode != null && listing.zipcode.length > 0) {
        formattedAddress += ' ' + listing.zipcode;
    }

    let formattedRooms = '';
    if (listing.bedrooms != null) {
        formattedRooms += listing.bedrooms + ' beds';
    }
    if (listing.bathrooms != null) {
        formattedRooms += ', ' + listing.bathrooms + ' baths';
    }
    if (listing.totalSqft != null) {
        if (formattedRooms.length > 0) {
            formattedRooms += ', ';
        }
        formattedRooms += listing.totalSqft + ' sqft';
    }
    const date = listing.date == null ? null : new Date(listing.date as string);
    const dateString = date == null ? null : date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const onDeleteFailure = (e) => {
        const msg = 'Delete listing of ' + listing.address + ' failed: ' + e.message;
        message.error(msg);
    };

    const onDelete = () => {
        if (props.connectionID != null) {
            commitMutation<RedfinEmailListingItemDeleteMutation>(RelayEnvironment, {
                mutation: deleteMutation,
                variables: {
                    id: listing._id,
                    connections: [props.connectionID],
                },
                optimisticResponse: {
                    RedfinEmailListingRemoveById: {
                        recordId: listing._id,
                        record: {
                            id: listing._id as string,
                        },
                    }
                },
                onCompleted: null,
                onError: onDeleteFailure,
            });
        }
    };

    const onSetArchivedFailure = (e) => {
        const msg = 'Set archived of listing ' + listing.address + ' failed: ' + e.message;
        message.error(msg);
    };

    const setArchived = (archived) => {
        commitMutation<RedfinEmailListingItemSetArchivedMutation>(RelayEnvironment, {
            mutation: setArchivedMutation,
            variables: {
                id: listing._id,
                archived,
            },
            optimisticResponse: {
                RedfinEmailListingUpdateById: {
                    recordId: listing._id,
                    record: {
                        id: listing._id as string,
                        archived: archived,
                    },
                }
            },
            onCompleted: (res) => {
                const connectionID = props.connectionID;
                if (!props.showArchived
                    && archived
                    && connectionID != null
                    && res.RedfinEmailListingUpdateById?.record?.archived ) {
                    commitLocalUpdate(environment, store => {
                        const connection = store.get(connectionID);
                        if (connection != null) {
                            ConnectionHandler.deleteNode(connection, listing.id);
                        }
                    });
                }
            },
            onError: onSetArchivedFailure,
        });
    };

    const getTimeline = (size) => {
        const listingHistory = listing.listingHistory;
        if (listingHistory == null || !Array.isArray(listingHistory) || listingHistory.length <= 1) {
            return null;
        }
        if (size === 'SM') {
            return (
                <div>
                    <Timeline mode="left" style={{ width: '100%', marginTop: '12px' }} reverse>
                        {[...listingHistory].map((pastListing) => {
                            if (pastListing == null) return null;
                            else {
                                const date = new Date(pastListing.date as string).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                });
                                return (
                                    <Timeline.Item
                                        key={date + pastListing.status + Math.random() * 100}
                                        color={getRedfinStatusColor(pastListing.status)}
                                    >
                                        <span>
                                            {date}
                                            <span style={{ position: 'absolute', left: '110px' }}>
                                                {pastListing.status}
                                            </span>
                                            <span style={{ position: 'absolute', right: '8px' }}>
                                                {formatDollar(pastListing?.listingPrice)}
                                            </span>
                                        </span>
                                    </Timeline.Item>
                                );
                            }
                        })}
                    </Timeline>
                </div>);
        }
        return (
            <div>
                <Timeline mode="left" style={{ width: 'calc(100% + 90px)', marginTop: '12px', marginLeft: '-90px' }} reverse>
                    {[...listingHistory].map((pastListing) => {
                        if (pastListing == null) return null;
                        else {
                            const date = new Date(pastListing.date as string).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            });
                            return (
                                <Timeline.Item
                                    key={date + pastListing.status + Math.random() * 100}
                                    label={<span style={{ fontSize: '16px' }}>{date}</span>}
                                    color={getRedfinStatusColor(pastListing.status)}
                                >
                                    <span style={{ fontSize: '16px' }}>{pastListing.status}</span>
                                    <span style={{ left: size === 'LG' ? '140px' :' 120px', position: 'absolute', fontSize: '16px' }}>
                                        {formatDollar(pastListing?.listingPrice)}
                                    </span>
                                </Timeline.Item>
                            );
                        }
                    })}
                </Timeline>
            </div>);
    };

    const getSmCard = () => {
        return (
            <Card style={{ width: '100%' }}>
                <div style={{ marginBottom: '8px', fontSize: '16px' }}>{listing.filterLabel}</div>
                {listing.photo && (
                    <img src={listing.photo} style={{ width: '100%', marginBottom: '8px' }} alt="property cover" />
                )}
                <div>
                    {listing?.url == null ?
                        <h3>{formattedAddress}</h3>
                        :
                        <a target="_blank" rel="noopener noreferrer" href={listing.url} onClick={() => setArchived(true)}>
                            <h3>{formattedAddress}</h3>
                        </a>
                    }
                </div>
                <p style={{ fontSize: '16px' }}>{formattedRooms}</p>

                <Row>
                    <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                        Price
                    </Col>
                    <Col span={12} style={{ fontSize: '20px' }}>
                        {'$ ' + addThousandSeparatorForNumber(round(listing.listingPrice))}
                    </Col>
                </Row>
                {listing.estimatedRent != null && (
                    <Row>
                        <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                            Estimated Rent
                        </Col>
                        <Col
                            span={12}
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            {'$ ' + addThousandSeparatorForNumber(round(listing.estimatedRent))}
                        </Col>
                    </Row>
                )}
                {listing.rentToPrice != null && (
                    <Row>
                        <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                            Rent-to-price
                        </Col>
                        <Col
                            span={12}
                            style={{
                                color: getColorForStatus(getStatusForRTP(listing.rentToPrice)),
                                fontSize: '20px',
                            }}
                        >
                            {listing.rentToPrice == null ? 0 : (listing.rentToPrice * 100).toFixed(2) + '%'}
                        </Col>
                    </Row>
                )}
                {getTimeline('SM')}
                <Row>
                    <Col span={16} style={{ fontSize: '12px', color: 'gray', paddingTop: '10px' }}>
                        {dateString}
                    </Col>
                    <Col offset={1} span={3}>
                        <Button danger onClick={onDelete}>
                            <DeleteOutlined />
                        </Button>
                    </Col>
                    <Col offset={1} span={3}>
                        <Button onClick={() => setArchived(true)}>
                            <ContainerOutlined />
                        </Button>
                    </Col>
                </Row>
            </Card>
        );
    };

    const MdCard = (
        <Card style={{ width: '100%' }} bodyStyle={{ padding: '6px 24px 12px 12px' }}>
            <Row style={{ marginBottom: '6px', fontSize: '16px', color: '#444' }}><p>{listing.filterLabel}</p></Row>
            <Row>
                <Col span={6}>
                    <img
                        src={listing.photo == null ? '/empty.jpg' : listing.photo}
                        style={{
                            marginTop: '-12px',
                            marginLeft: 0,
                            marginBottom: 0,
                            width: 'calc(100% + 12px)',
                        }}
                        alt="property cover"
                    />
                </Col>
                <Col span={13} offset={1}>
                    <Row>
                        {listing?.url == null ?
                            <h3 style={{ display: 'inline-block', marginBottom: '2px' }}>{formattedAddress}</h3>
                            :
                            <a target="_blank" rel="noopener noreferrer" href={listing.url} onClick={() => setArchived(true)}>
                                <h3 style={{ display: 'inline-block', marginBottom: '2px' }}>{formattedAddress}</h3>
                            </a>
                        }
                    </Row>

                    <Row style={{ marginTop: '4px' }}>
                        <p style={{ float: 'left', marginRight: '20px', fontSize: '16px', fontWeight: 500 }}>{formattedRooms}</p>

                        <p style={{ float: 'right', marginRight: '20px', fontSize: '16px' }}>{dateString}</p>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col span={9}>
                            <p
                                style={{
                                    fontSize: '22px',
                                    lineHeight: '28px',
                                    marginBottom: '4px',
                                }}
                            >
                                {'$ ' + addThousandSeparatorForNumber(round(listing.listingPrice))}
                            </p>

                            <p style={{ fontSize: '16px' }}>Price</p>
                        </Col>

                        <Col span={8}>
                            <p
                                style={{
                                    fontSize: '22px',
                                    lineHeight: '28px',
                                    marginBottom: '4px',
                                }}
                            >
                                {'$ ' + addThousandSeparatorForNumber(round(listing.estimatedRent))}
                            </p>

                            <p style={{ fontSize: '16px' }}>Estimated Rent</p>
                        </Col>
                        {listing.rentToPrice != null && (
                            <Col span={7}>
                                <p
                                    style={{
                                        color: getColorForStatus(getStatusForRTP(listing.rentToPrice)),
                                        fontSize: '22px',
                                        lineHeight: '28px',
                                        marginBottom: '4px',
                                    }}
                                >
                                    {listing.rentToPrice == null ? 0 : (listing.rentToPrice * 100).toFixed(2) + '%'}
                                </p>

                                <p style={{ fontSize: '16px' }}>Rent-to-price</p>
                            </Col>
                        )}
                    </Row>
                    {getTimeline('MD')}
                </Col>
                <Col span={4}>
                    <Button danger onClick={onDelete}>
                        <DeleteOutlined />
                    </Button>
                    <Button style={{ marginLeft: '4px' }} onClick={() => setArchived(true)}>
                        <ContainerOutlined />
                    </Button>
                </Col>
            </Row>
        </Card>
    );

    const getLgCard = () => {
        return (
            <Card style={{ width: '100%' }} bodyStyle={{ padding: '6px 24px 12px 12px' }}>
                <Row style={{ marginBottom: '6px', fontSize: '16px', color: '#444' }}>
                    <p>{listing.filterLabel}</p>
                </Row>
                <Row>
                    <Col span={6}>
                        <img
                            src={listing.photo == null ? '/empty.jpg' : listing.photo}
                            style={{
                                marginTop: '-12px',
                                marginLeft: 0,
                                marginBottom: 0,
                                width: 'calc(100% + 12px)',
                            }}
                            alt="property cover"
                        />
                    </Col>
                    <Col span={14} offset={1}>
                        <div>
                            <div>
                                {listing?.url == null ? (
                                    <h3 style={{ display: 'inline-block', marginBottom: '2px' }}>{formattedAddress}</h3>
                                ) : (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={listing.url}
                                        onClick={() => setArchived(true)}
                                    >
                                        <h3 style={{ display: 'inline-block', marginBottom: '2px' }}>
                                            {formattedAddress}
                                        </h3>
                                    </a>
                                )}
                                <p style={{ float: 'right', marginRight: '20px', fontSize: '16px' }}>{dateString}</p>
                            </div>

                            <p style={{ fontSize: '18px', marginTop: '4px' }}>{formattedRooms}</p>
                        </div>
                        <Row style={{ marginTop: '20px' }}>
                            <Col span={9}>
                                <p
                                    style={{
                                        fontSize: '24px',
                                        lineHeight: '30px',
                                        marginBottom: '6px',
                                    }}
                                >
                                    {'$ ' + addThousandSeparatorForNumber(round(listing.listingPrice))}
                                </p>

                                <p style={{ fontSize: '16px' }}>Price</p>
                            </Col>

                            <Col span={8}>
                                <p
                                    style={{
                                        fontSize: '24px',
                                        lineHeight: '30px',
                                        marginBottom: '6px',
                                    }}
                                >
                                    {'$ ' + addThousandSeparatorForNumber(round(listing.estimatedRent))}
                                </p>

                                <p style={{ fontSize: '16px' }}>Estimated Rent</p>
                            </Col>
                            {listing.rentToPrice != null && (
                                <Col span={7}>
                                    <p
                                        style={{
                                            color: getColorForStatus(getStatusForRTP(listing.rentToPrice)),
                                            fontSize: '24px',
                                            lineHeight: '30px',
                                            marginBottom: '6px',
                                        }}
                                    >
                                        {listing.rentToPrice == null ? 0 : (listing.rentToPrice * 100).toFixed(2) + '%'}
                                    </p>

                                    <p style={{ fontSize: '16px' }}>Rent-to-price</p>
                                </Col>
                            )}
                        </Row>
                        {getTimeline('LG')}
                    </Col>
                    <Col span={3}>
                        <Button danger onClick={onDelete}>
                            <DeleteOutlined />
                        </Button>
                        <Button style={{ marginLeft: '4px' }} onClick={() => setArchived(true)}>
                            <ContainerOutlined />
                        </Button>
                    </Col>
                </Row>
            </Card>
        );
    };

    if (windowWidth >= WindowWidth.LG) {
        return getLgCard();
    } else if (windowWidth >= WindowWidth.MD) {
        return MdCard;
    } else {
        return getSmCard();
    }
};

export default RedfinEmailListingItem;
