import React, { CSSProperties, useContext, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import { SignInModal } from 'components/SignInModal';
import { WindowWidthContext } from 'AppLayout';
import { WindowWidth } from 'helpers';

const SubMenu = Menu.SubMenu;
const { Header } = Layout;

interface MenuSubcomponent {
    title: string;
    exact?: boolean;
    path: string;
    component: any;
}

export interface MenuRoute {
    title: string;
    subcomponents?: MenuSubcomponent[];
    exact?: boolean;
    path?: string;
    component?: any;
}

interface HeaderMenuProps extends RouteComponentProps<any> {
    menuRoutes: MenuRoute[];
}

const HeaderMenu = (props: HeaderMenuProps) => {
    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const windowWidth = useContext(WindowWidthContext);

    const getSelectedKeys = () => {
        return [props.location.pathname];
    };

    const onClickUser = () => {
        setSignInModalVisible(!signInModalVisible);
    };

    const onCancelModal = () => {
        setSignInModalVisible(!signInModalVisible);
    };

    const handleClick = (e) => {
        switch (e.key) {
            case 'user':
                onClickUser();
                break;
            default:
                return;
        }
    };

    const menuRoutes = props.menuRoutes;

    const getMenuItems = (menuRoute: MenuRoute, index: number) => {
        if (menuRoute.subcomponents != null) {
            return (
                <SubMenu key={index} title={<span className="submenu-title-wrapper">{menuRoute.title}</span>} style={{ zIndex: 9999, float: 'left' }}>
                    {menuRoute.subcomponents.map((component) => (
                        <Menu.Item key={component.path}>
                            <Link to={component.path}>{component.title}</Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
            );
        } else {
            return (
                <Menu.Item key={menuRoute.path} style={{ float: 'left' }}>
                    <Link to={menuRoute.path == null ? '' : menuRoute.path}>{menuRoute.title}</Link>
                </Menu.Item>
            );
        }
    };
    const mobile = windowWidth === WindowWidth.XS;
    const headerStyle = mobile ? {
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px #f0f1f2',
        padding: '0 4px',
        position: 'fixed',
        zIndex: 1,
        width: '100%',
    } : {
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px #f0f1f2',
        marginBottom: '3px',
    };

    return (
        <Header style={headerStyle as CSSProperties}>
            <Menu
                mode="horizontal"
                theme="light"
                defaultSelectedKeys={['/']}
                selectedKeys={getSelectedKeys()}
                style={{ lineHeight: '64px', borderBottom: '0', display: 'table', width: '100%' }}
                onClick={handleClick}
            >
                <Menu.Item key="home" style={{ float: 'left' }}>
                    <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="blog" style={{ float: 'left' }}>
                    <a target="_blank" rel="noopener noreferrer" href="https://quanti-fi.com">Blog</a>
                </Menu.Item>
                {menuRoutes.map((menuRoute, index) => getMenuItems(menuRoute, index))}
                <Menu.Item key="user" style={{ float: 'right' }}>
                    <UserOutlined />
                </Menu.Item>
            </Menu>
            <SignInModal visible={signInModalVisible} onCancelModal={onCancelModal} />
        </Header>
    );
};

export default HeaderMenu;
