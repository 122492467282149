import React from 'react';
import { Layout } from 'antd';
import ReportButtons from '../Report/ReportButtons';
import { RentalCardUIProps } from './RentalCard';
import RefinanceInfoForm from 'rental/RefinanceInfoForm';

const { Content } = Layout;

const RentalMdCard = (props: RentalCardUIProps) => {
    const { loading, isSharedCard, currentReport, reportId, fetchWebsiteLoading, resetReport, saveReport, applyDefault, onValidationSuccess, onValidationFailed, shouldValidate, step, onSetStep, setValidated, getPhotos, getBreadcrumbs, getSkeleton, elements, isBRRRR } = props;

    const { PropertyInfoForm, PurchaseInfoForm, RentalInfoForm, RentalCardSider, RehabInfoForm, Report } = elements;

    const steps = isBRRRR ? [
        {
            title: 'Property',
            content: (
                <PropertyInfoForm
                    propertyInfo={currentReport?.propertyInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    fetchWebsiteLoading={fetchWebsiteLoading}
                    shouldValidate={shouldValidate && step === 0}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Purchase',
            content: (
                <PurchaseInfoForm
                    purchaseInfo={currentReport?.purchaseInfo}
                    closingCost={currentReport?.closingCostBreakdown}
                    repairCost={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 1}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    isBRRRR={isBRRRR}
                />
            ),
        },
        {
            title: 'Rehab',
            content: (
                <RehabInfoForm
                    rehabInfo={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 2}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Rental',
            content: (
                <RentalInfoForm
                    rentalInfo={currentReport?.rentalInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 3}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    isShortTermRental={currentReport?.isShortTermRental}
                />
            ),
        },{
            title: 'Refinance',
            content: (
                <RefinanceInfoForm
                    refinanceInfo={currentReport?.refinanceInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 4}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Report',
            content: <Report currentReport={currentReport} />,
        },
    ] : [
        {
            title: 'Property Info',
            content: (
                <PropertyInfoForm
                    propertyInfo={currentReport?.propertyInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    fetchWebsiteLoading={fetchWebsiteLoading}
                    shouldValidate={shouldValidate && step === 0}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Purchase Info',
            content: (
                <PurchaseInfoForm
                    purchaseInfo={currentReport?.purchaseInfo}
                    closingCost={currentReport?.closingCostBreakdown}
                    repairCost={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 1}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Rental Info',
            content: (
                <RentalInfoForm
                    rentalInfo={currentReport?.rentalInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 2}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    isShortTermRental={currentReport?.isShortTermRental}
                />
            ),
        },
        {
            title: 'Report',
            content: <Report currentReport={currentReport} />,
        },
    ];

    return (
        <Layout>
            <RentalCardSider
                mobile
                setStep={onSetStep}
                step={step}
                currentReport={currentReport}
                photos={getPhotos()}
                width={150}
                loading={loading}
                sharedCard={!!isSharedCard}
            />
            <Layout style={{ padding: '0 24px', backgroundColor: '#fbfbfb' }}>
                <div>
                    {getBreadcrumbs()}
                    {!isSharedCard && (
                        <ReportButtons
                            graphqlId={currentReport?.id}
                            mobile
                            reportId={reportId}
                            onSave={saveReport}
                            onReset={resetReport}
                            watchlistStatus={currentReport?.watchlistStatus ?? null}
                            applyDefault={applyDefault}
                            enableRentalTypeButton
                            rentalTypeLabel={
                                currentReport == null || !currentReport.isShortTermRental
                                    ? 'Switch to Short Term Rental'
                                    : 'Switch to Long Term Rental'
                            }
                            onChangeRentalType={props.onChangeRentalType}
                            enableBRRRRButton
                            brrrrLabel={
                                currentReport?.isBRRRR
                                    ? 'Switch to Rental'
                                    : 'Switch to BRRRR'
                            }
                            onChangeBRRRR={props.onChangeBRRRR}
                        />
                    )}
                </div>
                <Content>
                    <div className="split-card-ipad" style={{ paddingRight: '24px' }}>
                        <div>{loading ? getSkeleton() : steps[step].content}</div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default RentalMdCard;
