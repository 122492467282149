import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Form, Input, Select, Button, Col, Row, Card, Tooltip, notification } from 'antd';
import { WEBSITE_FETCH_REQUESTED, CLEAR_FETCH_ERROR } from 'redux/actions/rental';

import { PROPERTY_INFO_LABELS } from 'consts/rental';
import FormItem from 'components/RentalFormItem';
import OtherPropertyInfoForm from './OtherPropertyInfoForm';
import { mapPropsToFields, WindowWidth } from 'helpers';
import FormInputNumber from 'components/FormInputNumber';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { PropertyInfoForm$key } from './__generated__/PropertyInfoForm.graphql';
import RelayEnvironment from 'RelayEnvironment';
import { UserContext } from 'App';
import { WindowWidthContext } from 'AppLayout';

const { TextArea, Search } = Input;
const { Option, OptGroup } = Select;

export interface PropertyInfoFormProps extends Dispatch {
    propertyInfo?: PropertyInfoForm$key | null,
    propertyUrl?: string,
    onValidationSuccess: () => void;
    onValidationFailed: () => void;
    fetchWebsiteLoading?: boolean;
    fetchErrorMessage?: string;
    shouldValidate: boolean;
    setValidated: () => void;
    reportId?: string | null;
}

const PropertyInfoForm = (props: PropertyInfoFormProps) => {
    const [showOtherPropertyFeatures, setShowOtherPropertyFeatures] = useState(false);
    const user = useContext(UserContext);
    const windowWidth = useContext(WindowWidthContext);

    const propertyInfo = useFragment(
        graphql`
            fragment PropertyInfoForm on RentalReportPropertyInfo {        
                reportTitle
                address
                city
                state
                commnity
                county
                zipcode
                propertyType
                annualTax
                mlsNumber
                photos
                description
                bedrooms
                bathrooms
                totalSqft
                lotSize
                yearBuilt
                yearRenovated
                units
                stories
                countyAppraisedValue
                pool
                heating
                cooling
                fireplace
                garage
                construction
                roofing
                flooringTypes
                wiringCondition
                plumbingCondition
                sidingMaterial
                otherInformation
                location {
                    lat
                    lng
                }
            }
        `,
        props.propertyInfo ?? null);

    const url = props.propertyUrl;
    const { fetchErrorMessage, shouldValidate, setValidated } = props;

    const [form] = Form.useForm();

    useEffect(() => {
        if (shouldValidate) {
            setValidated();
            form.validateFields()
                .then(() => {
                    props.onValidationSuccess();
                })
                .catch((error) => {
                    props.onValidationFailed();
                });
        }
    }, [shouldValidate, setValidated, form, props]);

    useEffect(() => {
        if (fetchErrorMessage != null) {
            notification['error']({
                message: 'Import failed',
                description: fetchErrorMessage,
            });
        }
    }, [fetchErrorMessage]);

    useEffect(() => {
        if (fetchErrorMessage) {
            props.clearFetchError();
        }
    }, [props.clearFetchError, fetchErrorMessage, props]);

    // onUploadPhotos = (photo) => {
    //     console.log(photo);
    //     const url = window.URL.createObjectURL(photo);
    //     console.log(url);
    //     localStorage.setItem(url, photo);
    //     return true;
    // }

    // dummyRequest = ({ file, onSuccess }) => {
    //     setTimeout(() => {
    //         onSuccess('ok');
    //     }, 0);
    // };

    const {
        REPORT_TITLE,
        ADDRESS,
        ZIPCODE,
        CITY,
        STATE,
        PROPERTY_TYPE,
        ANNUAL_TAX,
        MLS_NUMBER,
        // PHOTOS,
        DESCRIPTION,
        BATHROOMS,
        BEDROOMS,
        TOTAL_SQFT,
        LOT_SIZE,
    } = PROPERTY_INFO_LABELS;

    const handleSubmit = (values) => {
        commitLocalUpdate(RelayEnvironment, store => {
            const id = props.reportId as string;
            if (id != null) {
                const record = store.get(id);
                if (record != null && record.getLinkedRecord('propertyInfo') != null) {
                    const propertyInfo = record.getLinkedRecord('propertyInfo');
                    Object.keys(values).forEach(key => {
                        propertyInfo?.setValue(values[key], key);
                    });
                }
            }
        });
    };

    const fetchWebsiteLoading = props.fetchWebsiteLoading;

    return (
        <Form
            layout="horizontal"
            className="stepForm"
            onFinish={handleSubmit}
            form={form}
            scrollToFirstError
            onValuesChange={handleSubmit}
            fields={mapPropsToFields(propertyInfo)}
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{ required: 'Please fill out ${name}.' }}
        >
            {user?.allowFetchWebsite &&
            <Form.Item label="Import data from website">
                <Tooltip placement="bottomRight" title="Currently supports redfin">
                    <Search onSearch={value => {props.requestFetchWebsite(value);}} enterButton loading={fetchWebsiteLoading} />
                </Tooltip>
            </Form.Item>}

            {windowWidth <= WindowWidth.SM && url != null && url.length > 0 && (
                <div style={{
                    marginBottom: '12px',
                    marginTop: '-12px',
                }}>
                    <a target="_blank" rel="noopener noreferrer" href={url}>{url.length > 45 ? url.substring(0, 45) + '...' : url}</a>
                </div>
            )}

            <h3 style={{ textAlign: 'left' }}>{REPORT_TITLE}</h3>
            <FormItem name="reportTitle">
                <Input disabled={fetchWebsiteLoading}/>
            </FormItem>

            <h3 style={{ textAlign: 'left' }}>Address</h3>
            <Card className='form-card'>
                <Row gutter={12}>
                    <Col lg={8} xs={24}>
                        <FormItem
                            label={STATE}
                            name="state"
                        >
                            <Input disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                    <Col lg={9} xs={24}>
                        <FormItem
                            label={CITY}
                            name="city"
                        >
                            <Input disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                    <Col lg={7} xs={24}>
                        <FormItem
                            label={ZIPCODE}
                            name="zipcode"
                        >
                            <Input disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-24px' }}>
                    <Col lg={24} xs={24}>
                        <FormItem
                            label={ADDRESS}
                            name="address"
                            rules={[{ required: true }]}
                        >
                            <Input disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                </Row>
            </Card>

            <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Property Details</h3>
            <Card className='form-card'>
                <Row gutter={12}>
                    <Col lg={12} xs={24}>
                        <FormItem label={PROPERTY_TYPE} name="propertyType" style={{ textAlign: 'start' }}>
                            <Select disabled={fetchWebsiteLoading}>
                                <Option value="Condo/Unit">Condo/Unit</Option>
                                <Option value="Single Family">Single Family</Option>
                                <OptGroup label="Small Multifamily">
                                    <Option value="Duplex">Duplex</Option>
                                    <Option value="Triplex">Triplex</Option>
                                    <Option value="Fourplex">Fourplex</Option>
                                </OptGroup>
                                <OptGroup label="Large Multifamily">
                                    <Option value="5-plex">5-plex</Option>
                                    <Option value="6-plex">6-plex</Option>
                                    <Option value="7-plex">7-plex</Option>
                                    <Option value="8-plex+">8-plex+</Option>
                                </OptGroup>
                                <Option value="Commercial">Commercial</Option>
                                <Option value="Industrial">Industrial</Option>
                                <Option value="Mobile Home">Mobile Home</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col lg={12} xs={24}>
                        <FormItem label={BEDROOMS} name="bedrooms">
                            <FormInputNumber zeroMarginTop disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                    <Col lg={12} xs={24} >
                        <FormItem label={BATHROOMS} name="bathrooms">
                            <FormInputNumber zeroMarginTop disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col lg={12} xs={24} >
                        <FormItem label={TOTAL_SQFT} name="totalSqft">
                            <FormInputNumber min={0} zeroMarginTop disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormItem label={LOT_SIZE} name="lotSize">
                            <FormInputNumber min={0} zeroMarginTop disabled={fetchWebsiteLoading} />
                        </FormItem>
                    </Col>
                </Row>

                <FormItem label={ANNUAL_TAX} name="annualTax">
                    <FormInputNumber dollar zeroMarginTop disabled={fetchWebsiteLoading} />
                </FormItem>

                <FormItem label={MLS_NUMBER} name="mlsNumber">
                    <Input disabled={fetchWebsiteLoading} />
                </FormItem>

                <FormItem label={DESCRIPTION} name="description">
                    <TextArea rows={4} disabled={fetchWebsiteLoading} />
                </FormItem>

                <Button
                    icon={showOtherPropertyFeatures ? <UpOutlined /> : <DownOutlined />}
                    onClick={() => {
                        setShowOtherPropertyFeatures(!showOtherPropertyFeatures);
                    }}
                    disabled={fetchWebsiteLoading}
                >
                    {showOtherPropertyFeatures ? 'Hide other property features' : 'Show other property features'}
                </Button>

                <OtherPropertyInfoForm {...props} showOtherPropertyFeatures={showOtherPropertyFeatures} disabled={fetchWebsiteLoading} />
            </Card>

            {/* <FormItem {...formItemLayout} label={PHOTOS} name="photos">
                <Upload listType="picture" className="upload-list-inline" accept="image/*" customRequest={dummyRequest}>
                    <Row>
                        <Button>
                            <UploadOutlined /> Upload
                        </Button>
                    </Row>
                </Upload>
                , , )}
            </FormItem> */}
        </Form>
    );
};

interface Dispatch {
    requestFetchWebsite?: any;
    clearFetchError?: any;
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestFetchWebsite: (url) => {
            dispatch({ type: WEBSITE_FETCH_REQUESTED, payload: { url } });
        },
        clearFetchError: () => {
            dispatch({ type: CLEAR_FETCH_ERROR });
        },
    };
};

export default connect(null, mapDispatchToProps)(PropertyInfoForm);
