import React, { useEffect } from 'react';
import { Form, Row, Col, Card } from 'antd';
import { RENTAL_LABELS } from 'consts/rental';
import { mapPropsToFields } from 'helpers';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import FormInputNumber from 'components/FormInputNumber';
import FormItem from 'components/RentalFormItem';
import { RentalInfoForm$key } from './__generated__/RentalInfoForm.graphql';
import RelayEnvironment from 'RelayEnvironment';

const {
    MONTHLY_RENT,
    DAILY_RATE,
    OCCUPANCY_RATE,
    OTHER_MONTHLY_INCOME,
    ELECTRICITY,
    WATER_AND_SEWER,
    PMI,
    GARBAGE,
    INTERNET,
    CLEANING,
    SUPPLIES,
    HOA,
    MONTHLY_INSURANCE,
    OTHER_MONTHLY_EXPENSES,
    VACANCY,
    MAINTENANCE,
    CAPEX,
    MANAGEMENT_FEES,
    ANNUAL_INCOME_GROWTH,
    ANNUAL_PV_GROWTH,
    ANNUAL_EXPENSES_GROWTH,
    SALES_EXPENSES,
} = RENTAL_LABELS;

export interface RentalInfoFormProps {
    rentalInfo?: RentalInfoForm$key | null,
    onValidationSuccess: () => void;
    onValidationFailed: () => void;
    shouldValidate: boolean;
    setValidated: () => void;
    reportId?: string | null;
    isShortTermRental?: boolean;
}

const RentalInfoForm = (props: RentalInfoFormProps) => {
    const rentalInfo = useFragment(
        graphql`
            fragment RentalInfoForm on RentalReportRentalInfo {
                monthlyRent
                dailyRate
                occupancyRate
                otherMonthlyIncome
                electricity
                waterAndSewer
                pmi
                garbage
                internet
                cleaning
                supplies
                hoa
                monthlyInsurance
                otherMonthlyExpenses
                vacancy
                maintenance
                capex
                managementFees
                annualIncomeGrowth
                annualPropertyValueGrowth
                annualExpensesGrowth
                salesExpenses
            }
        `,
        props.rentalInfo ?? null);
    const [form] = Form.useForm();

    const { shouldValidate, setValidated } = props;

    useEffect(() => {
        if (shouldValidate) {
            setValidated();
            form.validateFields()
                .then(() => {
                    props.onValidationSuccess();
                })
                .catch((error) => {
                    props.onValidationFailed();
                });
        }
    }, [form, props, shouldValidate, setValidated]);

    const handleSubmit = (values) => {
        commitLocalUpdate(RelayEnvironment, store => {
            const id = props.reportId as string;
            if (id != null) {
                const record = store.get(id);
                if (record != null && record.getLinkedRecord('rentalInfo') != null) {
                    const rentalInfo = record.getLinkedRecord('rentalInfo');
                    if (rentalInfo == null) {
                        return;
                    }
                    if (isShortTermRental) {
                        if ('dailyRate' in values || 'occupancyRate' in values) {
                            const dailyRate = 'dailyRate' in values ? values['dailyRate'] : rentalInfo.getValue('dailyRate');
                            const occupancyRate =
                                'occupancyRate' in values ? values['occupancyRate'] : rentalInfo.getValue('occupancyRate');
                            rentalInfo.setValue(Math.round(dailyRate * occupancyRate / 100 * 30), 'monthlyRent');
                        }
                    }
                    Object.keys(values).forEach(key => {
                        rentalInfo.setValue(values[key], key);
                    });
                }
            }
        });
    };

    const isShortTermRental = props.isShortTermRental;

    // const applyDefault = () => {
    //     const { setFieldsValue } = form;

    //     setFieldsValue({
    //         [WATER_AND_SEWER]: 75,
    //         [GARBAGE]: 20,
    //         [MONTHLY_INSURANCE]: 75,
    //         [VACANCY]: 5,
    //         [MAINTENANCE]: 5,
    //         [CAPEX]: 5,
    //         [MANAGEMENT_FEES]: 5,
    //         [ANNUAL_INCOME_GROWTH]: 2,
    //         [ANNUAL_PV_GROWTH]: 1,
    //         [ANNUAL_EXPENSES_GROWTH]: 1,
    //         [SALES_EXPENSES]: 8,
    //     });
    // };

    // const reset = () => {
    //     form.resetFields();
    // };

    return (
        <Form
            layout="horizontal"
            className="stepForm"
            onFinish={handleSubmit}
            form={form}
            fields={mapPropsToFields(rentalInfo)}
            onValuesChange={handleSubmit}
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{ required: 'Please fill out ${name}.' }}
        >
            {/* <Row justify='start' style={{ margin: '12px 0 12px 0' }}>
                    <Col lg={12} xs={24}>
                        <Button
                            type='primary'
                            style={{ float: 'left' }}
                            onClick={applyDefault}
                        >
                            Apply Default
                        </Button>
                        <Button
                            style={{ float: 'left', marginLeft: '24px' }}
                            onClick={reset}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col lg={12} xs={24}>
                    </Col>
                </Row> */}

            <h3 style={{ textAlign: 'left' }}>Income</h3>
            <Card className="form-card">
                {isShortTermRental && (
                    <Row>
                        <Col lg={12} xs={24}>
                            <FormItem label={DAILY_RATE} name="dailyRate">
                                <FormInputNumber dollar zeroMarginTop />
                            </FormItem>
                        </Col>

                        <Col lg={12} xs={24}>
                            <FormItem label={OCCUPANCY_RATE} name="occupancyRate">
                                <FormInputNumber percentage zeroMarginTop />
                            </FormItem>
                        </Col>
                    </Row>
                )}

                <FormItem label={MONTHLY_RENT} name="monthlyRent">
                    <FormInputNumber dollar zeroMarginTop />
                </FormItem>

                <FormItem label={OTHER_MONTHLY_INCOME} name="otherMonthlyIncome" style={{ marginBottom: '0' }}>
                    <FormInputNumber dollar zeroMarginTop />
                </FormItem>
            </Card>

            <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Fixed Landlord-Paid Expenses</h3>
            <Card className="form-card">
                <Row gutter={6}>
                    <Col lg={12} xs={24}>
                        <FormItem label={ELECTRICITY} name="electricity">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormItem label={WATER_AND_SEWER} name="waterAndSewer">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormItem label={GARBAGE} name="garbage">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>

                    {isShortTermRental && (
                        <>
                            <Col lg={12} xs={24}>
                                <FormItem label={CLEANING} name="cleaning">
                                    <FormInputNumber dollar zeroMarginTop />
                                </FormItem>
                            </Col>
                            <Col lg={12} xs={24}>
                                <FormItem label={SUPPLIES} name="supplies">
                                    <FormInputNumber dollar zeroMarginTop />
                                </FormItem>
                            </Col>
                            <Col lg={12} xs={24}>
                                <FormItem label={INTERNET} name="internet">
                                    <FormInputNumber dollar zeroMarginTop />
                                </FormItem>
                            </Col>
                        </>
                    )}

                    <Col lg={12} xs={24}>
                        <FormItem label={HOA} name="hoa">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormItem label={PMI} name="pmi">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>
                    <Col lg={12} xs={24}>
                        <FormItem label={MONTHLY_INSURANCE} name="monthlyInsurance">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>
                </Row>
                <FormItem label={OTHER_MONTHLY_EXPENSES} name="otherMonthlyExpenses" style={{ marginBottom: 0 }}>
                    <FormInputNumber dollar zeroMarginTop />
                </FormItem>
            </Card>

            <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Variable Landlord-Paid Expenses</h3>
            <Card className="form-card">
                <Row gutter={6}>
                    {!isShortTermRental && (
                        <Col lg={12} xs={24}>
                            <FormItem label={VACANCY} name="vacancy">
                                <FormInputNumber percentage zeroMarginTop style={{ width: '120px' }} />
                            </FormItem>
                        </Col>
                    )}
                    <Col lg={12} xs={24}>
                        <FormItem label={MAINTENANCE} name="maintenance">
                            <FormInputNumber percentage zeroMarginTop style={{ width: '120px' }} />
                        </FormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormItem label={CAPEX} name="capex" style={{ marginBottom: 0 }}>
                            <FormInputNumber percentage zeroMarginTop style={{ width: '120px' }} />
                        </FormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormItem label={MANAGEMENT_FEES} name="managementFees" style={{ marginBottom: 0 }}>
                            <FormInputNumber percentage zeroMarginTop style={{ width: '120px' }} />
                        </FormItem>
                    </Col>
                </Row>
            </Card>

            <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Future Assumptions</h3>
            <Card className="form-card">
                <Row gutter={6}>
                    <Col lg={24} xs={24}>
                        <FormItem label={ANNUAL_PV_GROWTH} name="annualPropertyValueGrowth">
                            <FormInputNumber percentage zeroMarginTop style={{ width: '100px' }} />
                        </FormItem>
                    </Col>

                    <Col lg={24} xs={24}>
                        <FormItem label={ANNUAL_INCOME_GROWTH} name="annualIncomeGrowth">
                            <FormInputNumber percentage zeroMarginTop style={{ width: '100px' }} />
                        </FormItem>
                    </Col>

                    <Col lg={24} xs={24}>
                        <FormItem label={ANNUAL_EXPENSES_GROWTH} name="annualExpensesGrowth">
                            <FormInputNumber percentage zeroMarginTop style={{ width: '100px' }} />
                        </FormItem>
                    </Col>

                    <Col lg={24} xs={24}>
                        <FormItem label={SALES_EXPENSES} name="salesExpenses" style={{ marginBottom: 0 }}>
                            <FormInputNumber percentage zeroMarginTop style={{ width: '100px' }} />
                        </FormItem>
                    </Col>
                </Row>
            </Card>
        </Form>
    );
};

export default RentalInfoForm;
