import React from 'react';
import { InputNumber } from 'antd';

interface FormInputNumberProps {
    min?: number;
    max?: number;
    dollar?: boolean;
    percentage?: boolean;
    width?: string;
    onChange?: any;
    value?: any;
    disabled?: boolean;
    style?: any;
    zeroMarginTop?: boolean;
    returnString?: boolean;
}

const getStyle = (props: FormInputNumberProps) => {
    const style: any = {
        float: 'left',
        height: '32px',
    };
    if (!props.zeroMarginTop) {
        style.marginTop = '4px';
    }
    if (props.dollar) {
        style.width = '138px';
    } else {
        style.width = '162px';
    }
    return style;
};

const FormInputNumber = (props: FormInputNumberProps) => {
    const min = props.min ? props.min : 0;
    let inputStyle: any = getStyle(props);
    if (props.width) {
        inputStyle.width = props.width;
    }
    inputStyle = props.style ? { ...inputStyle, ...props.style } : inputStyle;
    if (props.dollar) {
        const marginStyle = props.zeroMarginTop ? '6px 4px 0px 0px' :'8px 2px 0px 0px';
        return (
            <div style={{ display: 'block' }}>
                <p style={{ float: 'left', margin: marginStyle }}>$</p>
                <InputNumber
                    min={min}
                    max={props.max}
                    style={inputStyle}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value == null ? '' : value.replace(/\$\s?|(,*)/g, ''))}
                    onChange={props.onChange}
                    value={props.value}
                    disabled={props.disabled}
                />
            </div>
        );
    } else if (props.percentage) {
        return (
            <InputNumber
                style={inputStyle}
                min={min}
                max={props.max}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value.replace('%', '')}
                onChange={props.onChange}
                value={props.value}
                disabled={props.disabled}
            />
        );
    } else {
        return (
            <InputNumber
                min={min}
                max={props.max}
                style={inputStyle}
                onChange={(value) => {
                    if (props.returnString) {
                        props.onChange(value.toString());
                    } else {
                        props.onChange(value);
                    }
                }}
                value={props.value}
                disabled={props.disabled}
            />
        );
    }
};

export default FormInputNumber;
