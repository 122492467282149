import React from 'react';
import { connect } from 'react-redux';
import { InputNumber } from 'antd';
import { MortgageInterface } from 'mortgage/types';
import { updateDiscountRate } from 'redux/actions/mortgage';
import { AppState } from 'redux/store';
import { checkNumber } from 'redux/selectors';
import { Axis, Chart, LineAdvance, Tooltip } from 'bizcharts';

interface Props extends StoreState, Dispatch {
}

interface NPVData {
    year: number;
    npv: number;
    mortgage: string;
}

const getData = (mortgages: MortgageInterface[], discountRate: number, maxYears: number) => {
    let data: NPVData[] = [];
    mortgages.forEach((mortgage, index) => {
        if ((mortgage.purchasePrice != null) && (mortgage.isCashPurchase || (mortgage.amortizedYears != null && mortgage.downPayment != null && mortgage.loanInterestRate != null))) {
            const npvAray = getNPV(mortgage, discountRate, maxYears, mortgage.title == null ? '' : mortgage.title);
            data = [...data, ...npvAray];
        }
    });
    return data;
};

const getNPV = (mortgage: MortgageInterface, discountRate: number, maxYears: number, mortgageTitle: string): NPVData[] => {
    let cost = checkNumber(mortgage.totalCash);
    if (!mortgage.isCashPurchase) {
        cost += checkNumber(mortgage.monthlyPayments) * 12;
    }
    let npvArray: NPVData[] = [{
        year: 1,
        npv: cost * Math.pow(1 + discountRate, maxYears - 1),
        mortgage: mortgageTitle,
    }];

    for (let year = 2; year < maxYears; year++) {
        const prevNPV = npvArray[npvArray.length - 1];
        if (year <= checkNumber(mortgage.amortizedYears)) {
            const newNPV = prevNPV.npv + checkNumber(mortgage.monthlyPayments) * 12 * Math.pow(1 + discountRate, (maxYears - year));
            const newEle = { year, npv: newNPV, mortgage: mortgageTitle };
            npvArray = [...npvArray, newEle];
        } else {
            npvArray = [...npvArray, { ...prevNPV, year }];
        }
    }
    return npvArray;
};

const MortgageNPVComparisonCard = (props: Props) => {
    const { mortgages, discountRate } = props;

    if (mortgages == null || mortgages.length === 0) return null;
    const maxYears = mortgages.reduce((max, mortgage) => {
        return Math.max(max, checkNumber(mortgage.amortizedYears));
    }, 0);
    const data = getData(mortgages, discountRate, maxYears);
    if (data == null || data.length === 0) {
        return null;
    }

    return (
        <div>
            <div style={{ display: 'block' }}>
                <div>
                    Discount Rate:
                    <InputNumber
                        style={{ marginLeft: '4px' }}
                        min={0}
                        max={1}
                        onChange={(value) => {
                            if (typeof value === 'number') {
                                props.updateDiscountRate(value);
                            }
                        }}
                        value={props.discountRate}
                    />
                </div>
            </div>
            <h3 style={{ textAlign: 'center', marginTop: '16px' }}>Net Present Value</h3>
            <p>Note: lower is better. This is to estimate the opportunity cost at the end of {maxYears} years of different mortgage structures at different stage.</p>
            <Chart padding={[10, 20, 50, 80]} autoFit height={400} data={data} >
                <LineAdvance
                    shape="smooth"
                    point
                    area
                    position="year*npv"
                    color="mortgage"
                />
                <Axis name="npv" label={{ formatter: (text, item, index) => { return `$${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } }}/>
                <Axis name="year" label={{ formatter: (text, item, index) => { return `Year ${text}`; } }}/>
                <Tooltip>
                    {(title,items) => {
                        return (
                            <>
                                <div style={{ marginBottom: '12px', marginTop: '12px' }}>
                                    {'Year ' + title}
                                </div>
                                <ul style={{ margin: '0', listStyleType: 'none', padding: '0' }}>
                                    {items?.map((item, index) => {
                                        return (
                                            <li key={index} style={{ listStyleType: 'none', padding: '0', margin: '12px 0px' }}>
                                                <span style={{ background: item.color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px' }}></span>
                                                <span>{item.name}</span>:
                                                <span style={{ display: 'inline-block', float: 'right', marginLeft: '30px' }}>{`$${Math.round(item.value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        );
                    }}
                </Tooltip>
            </Chart>
        </div>
    );
};

interface StoreState {
    mortgages?: MortgageInterface[],
    discountRate: number,
}

const mapStateToProps = (state: AppState): StoreState => ({
    mortgages: state.mortgage.currentReport.mortgages,
    discountRate: state.mortgage.currentReport.discountRate,
});

interface Dispatch {
    updateDiscountRate: (discountRate: number) => void;
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateDiscountRate: (discountRate: number) => {
            dispatch(updateDiscountRate(discountRate));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MortgageNPVComparisonCard);
