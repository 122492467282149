/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RentalInfoForm = {
    readonly monthlyRent: number | null;
    readonly dailyRate: number | null;
    readonly occupancyRate: number | null;
    readonly otherMonthlyIncome: number | null;
    readonly electricity: number | null;
    readonly waterAndSewer: number | null;
    readonly pmi: number | null;
    readonly garbage: number | null;
    readonly internet: number | null;
    readonly cleaning: number | null;
    readonly supplies: number | null;
    readonly hoa: number | null;
    readonly monthlyInsurance: number | null;
    readonly otherMonthlyExpenses: number | null;
    readonly vacancy: number | null;
    readonly maintenance: number | null;
    readonly capex: number | null;
    readonly managementFees: number | null;
    readonly annualIncomeGrowth: number | null;
    readonly annualPropertyValueGrowth: number | null;
    readonly annualExpensesGrowth: number | null;
    readonly salesExpenses: number | null;
    readonly " $refType": "RentalInfoForm";
};
export type RentalInfoForm$data = RentalInfoForm;
export type RentalInfoForm$key = {
    readonly " $data"?: RentalInfoForm$data;
    readonly " $fragmentRefs": FragmentRefs<"RentalInfoForm">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RentalInfoForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyRent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dailyRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "occupancyRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherMonthlyIncome",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "electricity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waterAndSewer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pmi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "garbage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cleaning",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplies",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyInsurance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherMonthlyExpenses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vacancy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maintenance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualIncomeGrowth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualPropertyValueGrowth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualExpensesGrowth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesExpenses",
      "storageKey": null
    }
  ],
  "type": "RentalReportRentalInfo",
  "abstractKey": null
};
(node as any).hash = '263eefd37634af7e3ff8029ea1b70ce5';
export default node;
