/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SortConnectionRedfinEmailListingEnum = "ESTIMATED_RENT_RTP_DATE_DESC" | "RENT_TO_PRICE_DATE_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type RedfinEmailListingsListQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    days?: number | null;
    orderBy?: SortConnectionRedfinEmailListingEnum | null;
    showArchived?: boolean | null;
    status?: Array<string> | null;
    search?: string | null;
};
export type RedfinEmailListingsListQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"RedfinEmailListingsListFragment">;
};
export type RedfinEmailListingsListQuery = {
    readonly response: RedfinEmailListingsListQueryResponse;
    readonly variables: RedfinEmailListingsListQueryVariables;
};



/*
query RedfinEmailListingsListQuery(
  $count: Int = 15
  $cursor: String
  $days: Int
  $orderBy: SortConnectionRedfinEmailListingEnum = _ID_DESC
  $showArchived: Boolean
  $status: [String!]
  $search: String
) {
  ...RedfinEmailListingsListFragment
}

fragment RedfinEmailListingItemFragment on RedfinEmailListing {
  _id
  id
  address
  city
  state
  zipcode
  listingPrice
  status
  url
  date
  totalSqft
  bedrooms
  bathrooms
  estimatedRent
  rentToPrice
  photo
  filterLabel
  archived
  listingHistory {
    status
    listingPrice
    date
  }
}

fragment RedfinEmailListingsListFragment on Query {
  RedfinEmailListingConnection(first: $count, after: $cursor, sort: $orderBy, filter: {days: $days, showArchived: $showArchived, statusArr: $status, search: $search}) {
    edges {
      node {
        _id
        rentToPrice
        ...RedfinEmailListingItemFragment
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 15,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "days"
},
v3 = {
  "defaultValue": "_ID_DESC",
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showArchived"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "days",
        "variableName": "days"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "showArchived",
        "variableName": "showArchived"
      },
      {
        "kind": "Variable",
        "name": "statusArr",
        "variableName": "status"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "orderBy"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "listingPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RedfinEmailListingsListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RedfinEmailListingsListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "RedfinEmailListingsListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "RedfinEmailListingConnection",
        "kind": "LinkedField",
        "name": "RedfinEmailListingConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RedfinEmailListingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RedfinEmailListing",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rentToPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipcode",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalSqft",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bedrooms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bathrooms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "estimatedRent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RedfinEmailListingListingHistory",
                    "kind": "LinkedField",
                    "name": "listingHistory",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "filters": [
          "sort",
          "filter"
        ],
        "handle": "connection",
        "key": "Query_RedfinEmailListingConnection",
        "kind": "LinkedHandle",
        "name": "RedfinEmailListingConnection"
      }
    ]
  },
  "params": {
    "cacheID": "96b212b0ae30966b7c2349fc7ee11aca",
    "id": null,
    "metadata": {},
    "name": "RedfinEmailListingsListQuery",
    "operationKind": "query",
    "text": "query RedfinEmailListingsListQuery(\n  $count: Int = 15\n  $cursor: String\n  $days: Int\n  $orderBy: SortConnectionRedfinEmailListingEnum = _ID_DESC\n  $showArchived: Boolean\n  $status: [String!]\n  $search: String\n) {\n  ...RedfinEmailListingsListFragment\n}\n\nfragment RedfinEmailListingItemFragment on RedfinEmailListing {\n  _id\n  id\n  address\n  city\n  state\n  zipcode\n  listingPrice\n  status\n  url\n  date\n  totalSqft\n  bedrooms\n  bathrooms\n  estimatedRent\n  rentToPrice\n  photo\n  filterLabel\n  archived\n  listingHistory {\n    status\n    listingPrice\n    date\n  }\n}\n\nfragment RedfinEmailListingsListFragment on Query {\n  RedfinEmailListingConnection(first: $count, after: $cursor, sort: $orderBy, filter: {days: $days, showArchived: $showArchived, statusArr: $status, search: $search}) {\n    edges {\n      node {\n        _id\n        rentToPrice\n        ...RedfinEmailListingItemFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3d14e725c71d4608b1cdace798b6ad41';
export default node;
