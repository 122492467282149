/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateByIdRentalReportInput = {
    createTime?: number | null;
    createdByUid?: string | null;
    propertyInfo?: UpdateByIdRentalReportPropertyInfoInput | null;
    purchaseInfo?: UpdateByIdRentalReportPurchaseInfoInput | null;
    rentalInfo?: UpdateByIdRentalReportRentalInfoInput | null;
    refinanceInfo?: UpdateByIdRentalReportRefinanceInfoInput | null;
    closingCostBreakdown?: UpdateByIdRentalReportClosingCostBreakdownInput | null;
    repairCostBreakdown?: UpdateByIdRentalReportRepairCostBreakdownInput | null;
    isShortTermRental?: boolean | null;
    isBRRRR?: boolean | null;
    propertyUrl?: string | null;
    watchlistStatus?: string | null;
    propertyInfo_fuzzy?: unknown | null;
};
export type UpdateByIdRentalReportPropertyInfoInput = {
    reportTitle?: string | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    commnity?: string | null;
    county?: string | null;
    zipcode?: string | null;
    apn?: string | null;
    propertyType?: string | null;
    annualTax?: number | null;
    mlsNumber?: string | null;
    photos?: unknown | null;
    description?: string | null;
    bedrooms?: number | null;
    bathrooms?: number | null;
    totalSqft?: number | null;
    lotSize?: number | null;
    yearBuilt?: number | null;
    yearRenovated?: number | null;
    units?: number | null;
    stories?: number | null;
    countyAppraisedValue?: number | null;
    pool?: boolean | null;
    heating?: boolean | null;
    cooling?: boolean | null;
    fireplace?: boolean | null;
    garage?: boolean | null;
    construction?: string | null;
    roofing?: string | null;
    flooringTypes?: string | null;
    wiringCondition?: string | null;
    plumbingCondition?: string | null;
    sidingMaterial?: string | null;
    otherInformation?: string | null;
    location?: UpdateByIdRentalReportPropertyInfoLocationInput | null;
    _id?: unknown | null;
};
export type UpdateByIdRentalReportPropertyInfoLocationInput = {
    lat?: number | null;
    lng?: number | null;
    _id?: unknown | null;
};
export type UpdateByIdRentalReportPurchaseInfoInput = {
    purchasePrice?: number | null;
    afterRepairValue?: number | null;
    closingCost?: number | null;
    repairCost?: number | null;
    isCashPurchase?: boolean | null;
    downPayment?: number | null;
    loanInterestRate?: number | null;
    pointsChargedByLender?: number | null;
    otherChargesFromLender?: number | null;
    wrapLoanFeesIntoLoan?: boolean | null;
    isInterestOnly?: boolean | null;
    amortizedYears?: number | null;
    typicalCapRate?: number | null;
    listingPrice?: number | null;
    _id?: unknown | null;
};
export type UpdateByIdRentalReportRentalInfoInput = {
    monthlyRent?: number | null;
    otherMonthlyIncome?: number | null;
    electricity?: number | null;
    waterAndSewer?: number | null;
    pmi?: number | null;
    garbage?: number | null;
    hoa?: number | null;
    monthlyInsurance?: number | null;
    otherMonthlyExpenses?: number | null;
    vacancy?: number | null;
    maintenance?: number | null;
    capex?: number | null;
    managementFees?: number | null;
    dailyRate?: number | null;
    occupancyRate?: number | null;
    cleaning?: number | null;
    supplies?: number | null;
    internet?: number | null;
    annualIncomeGrowth?: number | null;
    annualPropertyValueGrowth?: number | null;
    annualExpensesGrowth?: number | null;
    salesExpenses?: number | null;
    _id?: unknown | null;
};
export type UpdateByIdRentalReportRefinanceInfoInput = {
    afterRepairValue?: number | null;
    closingCost?: number | null;
    isCashPurchase?: boolean | null;
    downPayment?: number | null;
    loanInterestRate?: number | null;
    pointsChargedByLender?: number | null;
    otherChargesFromLender?: number | null;
    wrapLoanFeesIntoLoan?: boolean | null;
    isInterestOnly?: boolean | null;
    amortizedYears?: number | null;
    _id?: unknown | null;
};
export type UpdateByIdRentalReportClosingCostBreakdownInput = {
    points?: number | null;
    prepaidHazardInsurance?: number | null;
    prepaidFloodInsurance?: number | null;
    prepaidPropertyTaxes?: number | null;
    annualAssesements?: number | null;
    titleAndEscrowFees?: number | null;
    attorneyCharges?: number | null;
    inspectionCosts?: number | null;
    recordingFees?: number | null;
    appraisalFees?: number | null;
    otherFees?: number | null;
    totalClosingCost?: number | null;
    _id?: unknown | null;
};
export type UpdateByIdRentalReportRepairCostBreakdownInput = {
    roof?: number | null;
    concrete?: number | null;
    guttersSoffitFascia?: number | null;
    garage?: number | null;
    siding?: number | null;
    landscaping?: number | null;
    exteriorPainting?: number | null;
    septic?: number | null;
    decks?: number | null;
    foundation?: number | null;
    demo?: number | null;
    sheerstock?: number | null;
    plumbing?: number | null;
    carpentryWindowsDoors?: number | null;
    electrical?: number | null;
    interiorPainting?: number | null;
    hvac?: number | null;
    cabinetsCounterTops?: number | null;
    framing?: number | null;
    flooring?: number | null;
    permits?: number | null;
    termites?: number | null;
    mold?: number | null;
    miscellaneous?: number | null;
    totalRepairCost?: number | null;
    _id?: unknown | null;
};
export type WatchlistButtonMutationVariables = {
    id: unknown;
    record: UpdateByIdRentalReportInput;
};
export type WatchlistButtonMutationResponse = {
    readonly RentalReportUpdateById: {
        readonly record: {
            readonly id: string;
            readonly watchlistStatus: string | null;
        } | null;
    } | null;
};
export type WatchlistButtonMutation = {
    readonly response: WatchlistButtonMutationResponse;
    readonly variables: WatchlistButtonMutationVariables;
};



/*
mutation WatchlistButtonMutation(
  $id: MongoID!
  $record: UpdateByIdRentalReportInput!
) {
  RentalReportUpdateById(_id: $id, record: $record) {
    record {
      id
      watchlistStatus
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdRentalReportPayload",
    "kind": "LinkedField",
    "name": "RentalReportUpdateById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RentalReport",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "watchlistStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchlistButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchlistButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "952bdacd91317817405b30fcabd3749f",
    "id": null,
    "metadata": {},
    "name": "WatchlistButtonMutation",
    "operationKind": "mutation",
    "text": "mutation WatchlistButtonMutation(\n  $id: MongoID!\n  $record: UpdateByIdRentalReportInput!\n) {\n  RentalReportUpdateById(_id: $id, record: $record) {\n    record {\n      id\n      watchlistStatus\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1b5c79397993c5933dcfc18f07ab21ab';
export default node;
