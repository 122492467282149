import React from 'react';
import { getReport } from 'redux/selectors';
import { Row, Col, Statistic, Card, Tooltip } from 'antd';
import { getColorForStatus, getStatusForCashflow, getStatusForCOC } from 'helpers';
import { round } from 'helpers';
import AnalysisOverTime from './AnalysisOverTime';
import ExpenseReport from './ExpenseReport';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Report$key } from './__generated__/Report.graphql';
import { ReportDataInterface } from 'rental/types';

interface Props {
    currentReport: Report$key | null;
}

const Report = (props: Props) => {
    const reportFragmentData = useFragment(
        graphql`
            fragment Report on RentalReport {
                propertyUrl
                isShortTermRental
                isBRRRR
                propertyInfo {
                    reportTitle
                    address
                    city
                    state
                    zipcode
                    bedrooms
                    bathrooms
                    totalSqft
                    annualTax
                }
                purchaseInfo {
                    purchasePrice
                    listingPrice
                    closingCost
                    repairCost
                    isCashPurchase
                    pointsChargedByLender
                    wrapLoanFeesIntoLoan
                    otherChargesFromLender
                    loanInterestRate
                    downPayment
                    amortizedYears
                    afterRepairValue
                }
                rentalInfo {
                    monthlyRent
                    dailyRate
                    occupancyRate
                    otherMonthlyIncome
                    vacancy
                    capex
                    maintenance
                    managementFees
                    monthlyInsurance
                    pmi
                    hoa
                    electricity
                    waterAndSewer
                    garbage
                    internet
                    cleaning
                    supplies
                    otherMonthlyExpenses
                    annualExpensesGrowth
                    annualIncomeGrowth
                    annualPropertyValueGrowth
                    salesExpenses
                }
            }
        `,
        props.currentReport,
    );

    const reportData = reportFragmentData != null ? getReport(reportFragmentData) : ({} as ReportDataInterface);
    const { loan } = reportData;
    const monthlyExpenses = reportData.monthlyOperatingExpenses;
    if (loan != null && loan.monthlyMortgage != null && loan.monthlyMortgage > 0) {
        monthlyExpenses.mortgage = +loan.monthlyMortgage.toFixed(1);
    }

    return (
        <div className="report">
            <Row style={{ marginTop: '16px' }} gutter={12}>
                <Col lg={12} sm={24}>
                    <Card className="form-card">
                        <Tooltip title="income - mortgage - all expenses, divided by total cash">
                            <p>Cashflow</p>
                            <p
                                style={{
                                    color: getColorForStatus(getStatusForCashflow(reportData.cashflow)),
                                    fontSize: '30px',
                                    lineHeight: '38px',
                                    marginBottom: '12px',
                                }}
                            >
                                {'$ ' + round(reportData.cashflow)}
                            </p>
                        </Tooltip>

                        <Tooltip title="income - mortgage - all expenses, divided by total cash">
                            <p style={{ lineHeight: '22px' }}>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.65)',
                                        marginRight: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Monthly Income
                                </span>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.85)',
                                        fontSize: '20px',
                                    }}
                                >
                                    {'$' + reportData.monthlyIncome}
                                </span>
                            </p>
                        </Tooltip>

                        <Tooltip title="All expenses including mortgage.">
                            <p style={{ lineHeight: '22px' }}>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.65)',
                                        marginRight: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Monthly Expense
                                </span>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.85)',
                                        fontSize: '20px',
                                    }}
                                >
                                    {'$' + round(reportData.monthlyExpense)}
                                </span>
                            </p>
                        </Tooltip>
                    </Card>
                </Col>

                <Col lg={12} sm={24}>
                    <Card className="form-card">
                        <Tooltip title="cashflow * 12 / total cash">
                            <p>Cash on Cash ROI</p>
                            <p
                                style={{
                                    color: getColorForStatus(getStatusForCOC(reportData.coc)),
                                    fontSize: '30px',
                                    lineHeight: '38px',
                                    marginBottom: '12px',
                                }}
                            >
                                {round(reportData.coc) + '%'}
                            </p>
                        </Tooltip>


                        <Tooltip title="down payment + closing costs + repair costs">
                            <p style={{ lineHeight: '22px' }}>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.65)',
                                        marginRight: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Total Cash Needed
                                </span>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.85)',
                                        fontSize: '20px',
                                    }}
                                >
                                    {'$' + round(reportData.totalCash)}
                                </span>
                            </p>
                        </Tooltip>

                        <Tooltip title="NOI (income - operating expenses) / market value">
                            <p style={{ lineHeight: '22px' }}>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.65)',
                                        marginRight: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Cap Rate
                                </span>
                                <span
                                    style={{
                                        color: 'rgba(0,0,0,.85)',
                                        fontSize: '20px',
                                    }}
                                >
                                    {round(reportData.capRate) + '%'}
                                </span>
                            </p>
                        </Tooltip>
                    </Card>
                </Col>
            </Row>

            <Card className="form-card" style={{ marginTop: '16px' }}>
                <Row>
                    <Col lg={8} sm={24}>
                        <Statistic title="Down Payment" value={round(loan?.downPayment)} prefix="$" />
                    </Col>
                    <Col lg={8} sm={24}>
                        <Statistic title="Monthly Mortgage" value={round(loan?.monthlyMortgage)} prefix="$" />
                    </Col>
                    <Col lg={8} sm={24}>
                        <Statistic title="Loan Amount" value={round(loan?.loanAmount)} prefix="$" />
                    </Col>
                </Row>
                <Row style={{ marginTop: '16px' }}>
                    <Col lg={8} sm={24}>
                        <Statistic title="Interest Rate" value={round(loan?.interestRate)} suffix="%" />
                    </Col>
                    <Col lg={8} sm={24}>
                        <Statistic title="Amortized Over" value={loan?.amortizedYears} suffix="years" />
                    </Col>
                    <Col lg={8} sm={24}>
                        <Statistic title="Loan Points" value={round(loan?.points)} suffix="%" />
                    </Col>
                </Row>
            </Card>

            <Card className="form-card" style={{ marginTop: '16px' }}>
                <Row>
                    <Col lg={8} sm={24}>
                        <Statistic title="Monthly Cashflow" value={round(reportData?.cashflow)} prefix="$" />
                    </Col>
                    <Col lg={8} sm={24}>
                        <Tooltip title="Cashflow + first month mortgage principal." placement="top">
                            <Statistic title="Monthly Return" value={round(reportData?.monthlyReturn)} prefix="$" />
                        </Tooltip>
                    </Col>
                    <Col lg={8} sm={24}>
                        <Tooltip title="First year cashflow + mortgage principal divided by total cash" placement="top">
                            <Statistic title="ROI" value={round(reportData?.roi * 100)} suffix="%" />
                        </Tooltip>
                    </Col>
                </Row>
                <Row style={{ marginTop: '16px' }}>
                    <Col lg={8} sm={24}>
                        <Tooltip title="Cashflow supposing vacancy, maintenance and CapEx all 0." placement="top">
                            <Statistic
                                title="Perfect Cashflow"
                                value={round(reportData?.perfectMonthlyCashflow)}
                                prefix="$"
                            />
                        </Tooltip>
                    </Col>
                    <Col lg={8} sm={24}>
                        {' '}
                        <Tooltip title="Cashflow + mortgage principal supposing vacancy, maintenance and CapEx all 0." placement="top">
                            <Statistic
                                title="Perfect Monthly Return"
                                value={round(reportData?.perfectMonthlyReturn)}
                                prefix="$"
                            />
                        </Tooltip>
                    </Col>
                    <Col lg={8} sm={24}>
                        <Tooltip title="First year perfect cashflow + mortgage principal divided by total cash" placement="top">
                            <Statistic title="Perfect ROI" value={round(reportData?.perfectROI * 100)} suffix="%" />
                        </Tooltip>
                    </Col>
                </Row>
            </Card>

            {reportData.monthlyOperatingExpenses != null && (
                <ExpenseReport expenses={reportData.monthlyOperatingExpenses} />
            )}

            <div className="form-card" style={{ marginTop: '16px' }}>
                <AnalysisOverTime dataSource={reportData.analysisOverTime} />
            </div>
        </div>
    );
};

export default Report;
