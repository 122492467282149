const setNetWorthTrackerNamespace = (actionType: string) => 'net-worth-tracker/' + actionType;

// actionTypes
export const SET_DATE = setNetWorthTrackerNamespace('SET_DATE');
export const SET_LIABILITIES_INFO = setNetWorthTrackerNamespace('SET_LIABILITIES_INFO');
export const SET_ASSETS_INFO = setNetWorthTrackerNamespace('SET_ASSETS_INFO');
export const SET_COMMENTS = setNetWorthTrackerNamespace('SET_COMMENTS');
export const RESET_REPORT = setNetWorthTrackerNamespace('RESET_REPORT');

export const SAVE_REPORT = setNetWorthTrackerNamespace('SAVE_REPORT');
export const SAVE_REPORT_SUCCEEDED = setNetWorthTrackerNamespace('SAVE_REPORT_SUCCEEDED');
export const SAVE_REPORT_FAILED = setNetWorthTrackerNamespace('SAVE_REPORT_FAILED');
export const FETCH_NET_WORTH = setNetWorthTrackerNamespace('FETCH_NET_WORTH');
export const FETCH_NET_WORTH_SUCCEEDED = setNetWorthTrackerNamespace('FETCH_NET_WORTH_SUCCEEDED');
export const FETCH_NET_WORTH_FAILED = setNetWorthTrackerNamespace('FETCH_NET_WORTH_REPORTS_FAILED');
export const DELETE_REPORT = setNetWorthTrackerNamespace('DELETE_REPORT');

export const POPULATE_NET_WORTH_CARD = setNetWorthTrackerNamespace('POPULATE_NET_WORTH_CARD');
export const POPULATE_NET_WORTH_CARD_ASYNC = setNetWorthTrackerNamespace('POPULATE_NET_WORTH_CARD_ASYNC');

export const POPULATE_NET_WORTH_DASHBOARD = setNetWorthTrackerNamespace('POPULATE_NET_WORTH_DASHBOARD');

// actions
export const setDate = (date) => ({
    type: SET_DATE,
    payload: { date },
});

export const setAssetsInfo = (assetsInfo) => ({
    type: SET_ASSETS_INFO,
    payload: { assetsInfo },
});

export const setLiabilitiesInfo = (liabilitiesInfo) => ({
    type: SET_LIABILITIES_INFO,
    payload: { liabilitiesInfo },
});

export const setComments = (comments) => ({
    type: SET_COMMENTS,
    payload: { comments },
});

export const addNewNetWorth = (onSuccess, onFailure, reportInfo) => {
    return { type: SAVE_REPORT, payload: { onSuccess, onFailure, reportInfo } };
};

export const deleteNetWorth = (onSuccess, onFailure, id) => {
    return { type: DELETE_REPORT, payload: { onSuccess, onFailure, id } };
};

export const fetchNetWorth = () => ({
    type: FETCH_NET_WORTH,

});

export const populateNetWorthCard = () => ({
    type: POPULATE_NET_WORTH_CARD_ASYNC,
});

export const populateNetWorthDashboard = () => ({
    type: POPULATE_NET_WORTH_DASHBOARD,
});
