/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SaveDefaultStrategyInput = {
    createdByUid?: string | null;
    closingCost?: number | null;
    repairCost?: number | null;
    isCashPurchase?: boolean | null;
    downPayment?: number | null;
    loanInterestRate?: number | null;
    wrapLoanFeesIntoLoan?: boolean | null;
    pointsChargedByLender?: number | null;
    amortizedYears?: number | null;
    electricity?: number | null;
    waterAndSewer?: number | null;
    pmi?: number | null;
    garbage?: number | null;
    hoa?: number | null;
    monthlyInsurance?: number | null;
    otherMonthlyExpenses?: number | null;
    vacancy?: number | null;
    maintenance?: number | null;
    capex?: number | null;
    managementFees?: number | null;
    annualIncomeGrowth?: number | null;
    annualPropertyValueGrowth?: number | null;
    annualExpensesGrowth?: number | null;
    salesExpenses?: number | null;
};
export type DefaultStrategyFormMutationVariables = {
    id?: unknown | null;
    record: SaveDefaultStrategyInput;
};
export type DefaultStrategyFormMutationResponse = {
    readonly DefaultStrategySave: {
        readonly recordId: unknown | null;
        readonly record: {
            readonly id: string;
            readonly _id: unknown;
            readonly closingCost: number | null;
            readonly repairCost: number | null;
            readonly downPayment: number | null;
            readonly loanInterestRate: number | null;
            readonly wrapLoanFeesIntoLoan: boolean | null;
            readonly pointsChargedByLender: number | null;
            readonly amortizedYears: number | null;
            readonly electricity: number | null;
            readonly waterAndSewer: number | null;
            readonly pmi: number | null;
            readonly garbage: number | null;
            readonly hoa: number | null;
            readonly monthlyInsurance: number | null;
            readonly otherMonthlyExpenses: number | null;
            readonly vacancy: number | null;
            readonly maintenance: number | null;
            readonly capex: number | null;
            readonly managementFees: number | null;
            readonly annualIncomeGrowth: number | null;
            readonly annualPropertyValueGrowth: number | null;
            readonly annualExpensesGrowth: number | null;
            readonly salesExpenses: number | null;
        } | null;
    } | null;
};
export type DefaultStrategyFormMutation = {
    readonly response: DefaultStrategyFormMutationResponse;
    readonly variables: DefaultStrategyFormMutationVariables;
};



/*
mutation DefaultStrategyFormMutation(
  $id: MongoID
  $record: SaveDefaultStrategyInput!
) {
  DefaultStrategySave(_id: $id, record: $record) {
    recordId
    record {
      id
      _id
      closingCost
      repairCost
      downPayment
      loanInterestRate
      wrapLoanFeesIntoLoan
      pointsChargedByLender
      amortizedYears
      electricity
      waterAndSewer
      pmi
      garbage
      hoa
      monthlyInsurance
      otherMonthlyExpenses
      vacancy
      maintenance
      capex
      managementFees
      annualIncomeGrowth
      annualPropertyValueGrowth
      annualExpensesGrowth
      salesExpenses
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "SaveDefaultStrategyPayload",
    "kind": "LinkedField",
    "name": "DefaultStrategySave",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DefaultStrategy",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closingCost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "repairCost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downPayment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "loanInterestRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "wrapLoanFeesIntoLoan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointsChargedByLender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amortizedYears",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "electricity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "waterAndSewer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pmi",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "garbage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hoa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthlyInsurance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "otherMonthlyExpenses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vacancy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maintenance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "capex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managementFees",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annualIncomeGrowth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annualPropertyValueGrowth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annualExpensesGrowth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salesExpenses",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DefaultStrategyFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DefaultStrategyFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03ae4375b0e8048e55d8e4786ccd244c",
    "id": null,
    "metadata": {},
    "name": "DefaultStrategyFormMutation",
    "operationKind": "mutation",
    "text": "mutation DefaultStrategyFormMutation(\n  $id: MongoID\n  $record: SaveDefaultStrategyInput!\n) {\n  DefaultStrategySave(_id: $id, record: $record) {\n    recordId\n    record {\n      id\n      _id\n      closingCost\n      repairCost\n      downPayment\n      loanInterestRate\n      wrapLoanFeesIntoLoan\n      pointsChargedByLender\n      amortizedYears\n      electricity\n      waterAndSewer\n      pmi\n      garbage\n      hoa\n      monthlyInsurance\n      otherMonthlyExpenses\n      vacancy\n      maintenance\n      capex\n      managementFees\n      annualIncomeGrowth\n      annualPropertyValueGrowth\n      annualExpensesGrowth\n      salesExpenses\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0d5fe063f4d4c9e2d5f249bcabbce307';
export default node;
