import React from 'react';
import Date from './Date';
import Buttons from './Buttons';
import Assets from './Assets';
import Liabilities from './Liabilities';
import Comments from './Comments';

const CurrentNetWorthForm = () => {
    return (
        <div className="stepForm">
            <Date />

            <Assets />

            <Liabilities />

            <Comments />

            <Buttons />
        </div>
    );
};

export default CurrentNetWorthForm;
