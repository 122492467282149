import * as actionTypes from '../actions/rental';
import { RentalState, CurrentRentalReportInterface } from 'rental/types';
import { checkNamespace } from 'helpers';

export const initialCurrentRentalState: CurrentRentalReportInterface = {
    reportId: '',
    propertyInfo: {},
    purchaseInfo: {},
    rentalInfo: {},
    closingCostBreakdown: {},
    repairCostBreakdown: {},
    fetchWebsiteLoading: false,
};

export const initialRentalState: RentalState = {
    currentReport: initialCurrentRentalState,
};

function currentRentalReportReducer(rentalState = initialRentalState, action): CurrentRentalReportInterface {
    const state = rentalState.currentReport;
    if (!checkNamespace(action, 'current_rental')) return state;
    switch (action.type) {
        case actionTypes.RENTAL_SET_URL: {
            return {
                ...state,
                propertyUrl: action.payload.propertyUrl,
            };
        }
        case actionTypes.WEBSITE_FETCH_REQUESTED: {
            return {
                ...state,
                fetchWebsiteLoading: true
            };
        }
        case actionTypes.WEBSITE_FETCH_SUCCEEDED: {
            const { data } = action.payload;
            return {
                ...state,
                propertyInfo: data,
                purchaseInfo: { listingPrice: data.listingPrice },
                rentalInfo: { hoa: data.hoa },
                fetchWebsiteLoading: false,
            };
        }
        case actionTypes.WEBSITE_FETCH_FAILED: {
            // console.log(action.message);
            return {
                ...state,
                fetchErrorMessage: action.message,
                fetchWebsiteLoading: false,
            };
        }
        case actionTypes.CLEAR_FETCH_ERROR: {
            return {
                ...state,
                fetchErrorMessage: undefined,
            };
        }
        default:
            return state;
    }
}

export default function (state = initialRentalState, action): RentalState {
    if (checkNamespace(action, 'current_rental')) {
        const newCurrentReport: CurrentRentalReportInterface = currentRentalReportReducer(state, action);
        return {
            ...state,
            currentReport: newCurrentReport,
        };
    }

    return state;
}
