import React, { useRef } from 'react';
import { Form, Row, Col, InputNumber, Button } from 'antd';
import FormInputNumber from 'components/FormInputNumber';
import { RetirementAccountsInfoInterface } from '../../types';
import { getRetirementAccountsInfo, getCompensation, getTotalRetirementAccountsSavings } from 'redux/selectors';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/budget';
import { mapPropsToFields, round } from 'helpers';
import { connect } from 'react-redux';
import { max401K, budgetDisabledStyle } from 'consts/budget';
import { FormInstance } from 'antd/lib/form';

interface Props extends StoreState, Dispatch {}

const RetirementAccounts = (props: Props) => {
    const formRef = useRef<FormInstance | null>(null);

    const onClickMaxOut = () => {
        props.setRetirementAccountsInfo({ employee401K: max401K });
    };

    const onClickCompanyMatch = () => {
        const companyMatchOfCompensation = round(formRef.current?.getFieldValue('companyMatchOfCompensation'));
        const compensation = props.compensation;
        const employee401K = Math.min(round((compensation * companyMatchOfCompensation) / 100), max401K);
        const values = { employee401K };
        props.setRetirementAccountsInfo(values);
    };

    return (
        <Form
            layout="vertical"
            onValuesChange={(values) => {
                props.setRetirementAccountsInfo(values);
            }}
            fields={mapPropsToFields(props.retirementAccountsInfo)}
            ref={formRef}
        >
            <Row>
                <h2 style={{ float: 'left' }}>Retirement Accounts</h2>
            </Row>

            <div
                style={{
                    textAlign: 'left',
                    marginBottom: '12px',
                    fontSize: '14px',
                    fontVariant: 'tabular-nums',
                    lineHeight: 1.5715,
                    listStyle: 'none',
                    color: 'rgba(0, 0, 0, 0.85)',
                }}
            >
                <>{'My company will match '}</>
                <Form.Item noStyle name="companyMatchPercentage">
                    <InputNumber formatter={(value) => `${value}%`} parser={(value: any) => value.replace('%', '')} />
                </Form.Item>
                <>{' on the first '}</>
                <Form.Item noStyle name="companyMatchOfCompensation">
                    <InputNumber formatter={(value) => `${value}%`} parser={(value: any) => value.replace('%', '')} />
                </Form.Item>
                <>{' of my eligibile compensation.'}</>
            </div>

            <div className="ant-row ant-form-item">
                <div className="ant-col ant-form-item-label">
                    <label>401k</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                            <div style={{ float: 'left', marginTop: '4px', marginRight: '6px' }}>
                                <Button onClick={onClickMaxOut}>Max out</Button> or{' '}
                                <Button onClick={onClickCompanyMatch}>Up to company match</Button> or
                            </div>
                            <Form.Item name="employee401K" noStyle>
                                <FormInputNumber max={max401K} dollar />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>

            <Row>
                <Col lg={8} xs={24}>
                    <Form.Item label="Roth IRA" name="roth">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                    <Form.Item label="Other After-Tax IRAs" name="otherIRA">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={12} sm={24}>
                    <Form.Item label="Total Retirement Accounts Savings" name="totalRetirementAccountsSavings">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
interface StoreState {
    retirementAccountsInfo: RetirementAccountsInfoInterface & { totalRetirementAccountsSavings: number };
    compensation: number;
}

const mapStateToProps = (state: AppState): StoreState => ({
    retirementAccountsInfo: {
        ...getRetirementAccountsInfo(state),
        totalRetirementAccountsSavings: getTotalRetirementAccountsSavings(state),
    },
    compensation: getCompensation(state),
});

interface Dispatch {
    setRetirementAccountsInfo: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        setRetirementAccountsInfo: (retirementAccountsInfo: RetirementAccountsInfoInterface) => {
            dispatch(actions.setRetirementAccountsInfo(retirementAccountsInfo));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetirementAccounts);
