import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { MortgageInterface } from 'mortgage/types';
import { AppState } from 'redux/store';
import MortgageNPVComparisonCard from './MortgageNPVComparisonCard';
import MortgageEquityComparisonCard from './MortgageEquityComparisonCard';

const MortgageComparisonCard = (props: StoreState) => {
    const mortgages = props.mortgages;
    if (mortgages == null || mortgages.length === 0) {
        return null;
    }

    let comparableMortgageNumber = 0;
    mortgages.forEach((mortgage, index) => {
        if ((mortgage.purchasePrice != null) && (mortgage.isCashPurchase || (mortgage.amortizedYears != null && mortgage.downPayment != null && mortgage.loanInterestRate != null))) {
            comparableMortgageNumber += 1;
        }
    });

    if (comparableMortgageNumber === 0) {
        return null;
    }

    return (
        <Card>
            <h2 style={{ textAlign: 'center' }}>Comparison</h2>
            <MortgageNPVComparisonCard />
            <MortgageEquityComparisonCard />
        </Card>
    );
};

interface StoreState {
    mortgages?: MortgageInterface[],
}

const mapStateToProps = (state: AppState): StoreState => ({
    mortgages: state.mortgage.currentReport.mortgages,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MortgageComparisonCard);
