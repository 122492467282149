/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PreviousRentalReportCardMutationVariables = {
    id: unknown;
    connections: Array<string>;
};
export type PreviousRentalReportCardMutationResponse = {
    readonly RentalReportRemoveById: {
        readonly recordId: unknown | null;
        readonly record: {
            readonly id: string;
        } | null;
    } | null;
};
export type PreviousRentalReportCardMutation = {
    readonly response: PreviousRentalReportCardMutationResponse;
    readonly variables: PreviousRentalReportCardMutationVariables;
};



/*
mutation PreviousRentalReportCardMutation(
  $id: MongoID!
) {
  RentalReportRemoveById(input: {_id: $id}) {
    recordId
    record {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recordId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviousRentalReportCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RemoveByIdRentalReportPayload",
        "kind": "LinkedField",
        "name": "RentalReportRemoveById",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RentalReport",
            "kind": "LinkedField",
            "name": "record",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PreviousRentalReportCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RemoveByIdRentalReportPayload",
        "kind": "LinkedField",
        "name": "RentalReportRemoveById",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RentalReport",
            "kind": "LinkedField",
            "name": "record",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "465234128309d7ba511cf0868ca5a8ef",
    "id": null,
    "metadata": {},
    "name": "PreviousRentalReportCardMutation",
    "operationKind": "mutation",
    "text": "mutation PreviousRentalReportCardMutation(\n  $id: MongoID!\n) {\n  RentalReportRemoveById(input: {_id: $id}) {\n    recordId\n    record {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '26b910067cd964d10044049220b283eb';
export default node;
