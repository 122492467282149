import React from 'react';
import { FormItemProps } from 'antd/lib/form';
import { Form } from 'antd';

const RentalFormItem = (props: FormItemProps) => {
    if (props.label && (typeof props.label === 'string')) {
        const newProps: FormItemProps = {
            ...props,
            label: <span style={{ fontWeight: 500 }}>{props.label}</span>,
        };
        return <Form.Item {...newProps} />;
    } else {
        return <Form.Item {...props} />;
    }
};

export default RentalFormItem;
