import React from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Row, Col, Popconfirm, Button, message } from 'antd';
import { BudgetReportInterface } from '../types';
import { addThousandSeparatorForNumber, round } from 'helpers';
import { connect } from 'react-redux';
import { deleteReport } from 'redux/actions/budget';

interface Props extends Dispatch {
    reportInfo: BudgetReportInterface;
}

const PreviousBudgetReportCard = (props: Props) => {
    const onDeleteReportSuccess = () => {
        const msg = 'Delete report of ' + props.reportInfo.reportTitle + ' succeeded!';
        message.success(msg);
    };

    const onDeleteReportFailure = (e) => {
        const msg = 'Delete report of ' + props.reportInfo.reportTitle + ' failed: ' + e.message;
        message.error(msg);
    };

    const onConfirmDeleteReport = () => {
        props.deleteBudgetReport(onDeleteReportSuccess, onDeleteReportFailure, props.reportInfo._id);
    };

    return (
        <Card style={{ width: '100%' }}>
            <Row>
                <Col span={22}>
                    <Link to={'/budget/' + props.reportInfo._id}>
                        <h3 style={{ lineHeight: '30px' }}>{props.reportInfo.reportTitle || 'Budget'}</h3>
                    </Link>

                    <p>
                        {'Annual Total Savings: $ ' +
                            addThousandSeparatorForNumber(round(props.reportInfo.results.totalSavings))}
                    </p>
                </Col>
                <Col span={2}>
                    <Popconfirm
                        title="Are you sure delete this report?"
                        onConfirm={onConfirmDeleteReport}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </Card>
    );
};

interface Dispatch {
    deleteBudgetReport: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        deleteBudgetReport: (onSuccess, onFailure, reportId) => {
            dispatch(deleteReport(onSuccess, onFailure, reportId));
        },
    };
};

export default connect(null, mapDispatchToProps)(PreviousBudgetReportCard);
