import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Empty, Spin, Radio, Select, Checkbox, InputNumber, Layout, Breadcrumb, Row, Col, Input, Switch } from 'antd';
import { states, WindowWidth } from 'helpers';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { WindowWidthContext } from 'AppLayout';
import RentalReportList from './RentalReportList';

const Option = Select.Option;
const Content = Layout.Content;
const Search = Input.Search;

type Props = StoreState;

const RentalReportPage = (props: Props) => {
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [searchString, setSearchString] = useState<string | undefined>(undefined);
    const [sortCriteria, setSortCriteria] = useState('default');
    const [stateFilter, setStateFilter] = useState<string[]>([]);
    const [cocFilter, setCocFilter] = useState<number | null>(null);
    const [cashflowFilter, setCashflowFilter] = useState<number | null>(null);
    const [shortTermRentalOnly, setShortTermRentalOnly] = useState(false);
    const windowWidth = useContext(WindowWidthContext);

    const getList = () => {
        if (!props.firebaseAuthLoaded) {
            return <Spin size="large" style={{ margin: '0 auto', display: 'block' }} />;
        } else if (props.firebaseAuthEmpty) {
            return <Empty description="You need to log in first" />;
        } else {
            return (
                <RentalReportList
                    searchString={searchString}
                    sortCriteria={sortCriteria}
                    stateFilter={stateFilter}
                    cocFilter={cocFilter}
                    cashflowFilter={cashflowFilter}
                />
            );
        }
    };

    const getFontSize = () => windowWidth <= WindowWidth.SM ? '14px' : '16px';
    return (
        <Layout style={{ background: '#fbfbfb', padding: '0 16px' }}>
            <div className="previous-reports">
                <div>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Previous Rental Reports</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Content>
                    <Row style={{ marginBottom: '8px', marginLeft: '4px' }}>
                        <Col lg={12} md={24} style={{ textAlign: 'left' }}>
                            <Search
                                placeholder="Search report title, address, city, or zipcode."
                                allowClear
                                style={{ float: 'left', width: '100%' }}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                onSearch={(value) => {
                                    setSearchString(value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '4px', marginLeft: '4px' }}>
                        <Col md={12} sm={24} style={{ marginBottom: '8px' }}>
                            <span style={{ fontSize: getFontSize(), marginRight: '8px' }}>Sort by:</span>
                            <Radio.Group
                                value={sortCriteria}
                                onChange={(e) => {
                                    setSortCriteria(e.target.value);
                                }}
                            >
                                <Radio.Button value="default">Default</Radio.Button>
                                <Radio.Button value="coc">Cash on Cash</Radio.Button>
                                <Radio.Button value="cashflow">Cashflow</Radio.Button>
                            </Radio.Group>
                        </Col>
                        <Col lg={0} md={2} sm={0} />
                        <Col md={10} sm={24} style={{ marginBottom: '8px' }}>
                            <span style={{ fontSize: getFontSize(), marginRight: '8px' }}>State:</span>
                            <Select
                                style={{ width: 160 }}
                                onChange={(value) => {
                                    setStateFilter(value);
                                }}
                                value={stateFilter}
                                mode="multiple"
                            >
                                {Object.keys(states).map((stateAbbv) => (
                                    <Option value={stateAbbv} key={stateAbbv}>
                                        {stateAbbv}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col lg={12} md={24} style={{ marginBottom: '8px' }}>
                            <span style={{ fontSize: getFontSize(), marginRight: windowWidth <= WindowWidth.SM ? '2px' : '8px' }}>Cash on Cash:</span>
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCocFilter(0);
                                    } else {
                                        setCocFilter(null);
                                    }
                                }}
                                checked={cocFilter === 0}
                                style={{ padding: windowWidth <= WindowWidth.SM ? '0' : undefined }}
                            >
                                <span style={{ fontSize: getFontSize() }}>Non-negative</span>
                            </Checkbox>
                            <span style={{ fontSize: getFontSize(), marginLeft: windowWidth <= WindowWidth.SM ? '0' : '12px', marginRight: windowWidth <= WindowWidth.SM ? '2px' : '8px' }}>
                                {'or >='}
                            </span>
                            <InputNumber
                                onChange={(value) => {
                                    if (value != null) {
                                        if (typeof value === 'string' && !isNaN(parseFloat(value))) {
                                            setCocFilter(parseFloat(value));
                                        } else if (typeof value === 'number') {
                                            setCocFilter(value);
                                        }
                                    }
                                }}
                                value={cocFilter === 0 || cocFilter == null ? undefined : cocFilter}
                                style={{ marginRight: '4px', width: '70px' }}
                            />
                            {'%'}
                        </Col>
                        <Col lg={12} md={24} style={{ marginBottom: '8px' }}>
                            <span style={{ fontSize: getFontSize(), marginRight: '8px' }}>Cashflow:</span>
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCashflowFilter(0);
                                    } else {
                                        setCashflowFilter(null);
                                    }
                                }}
                                checked={cashflowFilter === 0}
                            >
                                <span style={{ fontSize: getFontSize() }}>Non-negative</span>
                            </Checkbox>
                            <span style={{ fontSize: getFontSize(), marginLeft: windowWidth <= WindowWidth.SM ? '0' : '12px', marginRight: '4px' }}>
                                {'or >= $'}
                            </span>
                            <InputNumber
                                onChange={(value) => {
                                    if (value != null) {
                                        if (typeof value === 'string' && !isNaN(parseFloat(value))) {
                                            setCashflowFilter(parseFloat(value));
                                        } else if (typeof value === 'number') {
                                            setCashflowFilter(value);
                                        }
                                    }
                                }}
                                value={cashflowFilter === 0 || cashflowFilter == null ? undefined : cashflowFilter}
                            />
                        </Col>
                        <Col lg={12} md={24} style={{ marginBottom: '8px' }}>
                            <span style={{ fontSize: getFontSize(), marginRight: '10px' }}>Short-term Rental Only</span>
                            <Switch
                                onChange={(checked) => {
                                    setShortTermRentalOnly(checked);
                                }}
                                checked={shortTermRentalOnly}
                            />
                        </Col>
                    </Row>
                    {getList()}
                </Content>
            </div>
        </Layout>
    );
};

interface StoreState {
    firebaseAuthLoaded: boolean;
    firebaseAuthEmpty: boolean;
}

const mapStateToProps = (state: AppState): StoreState => ({
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
    firebaseAuthEmpty: state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(RentalReportPage);
