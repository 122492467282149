/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RentalReportPaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    search?: string | null;
    shortTermRentalOnly?: boolean | null;
    states?: Array<string> | null;
    uid?: string | null;
};
export type RentalReportPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"RentalReportListFragment">;
};
export type RentalReportPaginationQuery = {
    readonly response: RentalReportPaginationQueryResponse;
    readonly variables: RentalReportPaginationQueryVariables;
};



/*
query RentalReportPaginationQuery(
  $count: Int
  $cursor: String
  $search: String
  $shortTermRentalOnly: Boolean
  $states: [String!]
  $uid: String
) {
  ...RentalReportListFragment
}

fragment RentalReportListFragment on Query {
  RentalReportConnection(first: $count, after: $cursor, filter: {createdByUid: $uid, states: $states, search: $search, shortTermRentalOnly: $shortTermRentalOnly}) {
    edges {
      node {
        _id
        createTime
        watchlistStatus
        isShortTermRental
        isBRRRR
        propertyUrl
        propertyInfo {
          reportTitle
          address
          city
          state
          zipcode
          bedrooms
          bathrooms
          totalSqft
          annualTax
          photos
        }
        purchaseInfo {
          purchasePrice
          listingPrice
          closingCost
          repairCost
          isCashPurchase
          pointsChargedByLender
          wrapLoanFeesIntoLoan
          otherChargesFromLender
          loanInterestRate
          downPayment
          amortizedYears
          afterRepairValue
        }
        rentalInfo {
          monthlyRent
          dailyRate
          occupancyRate
          otherMonthlyIncome
          vacancy
          capex
          maintenance
          managementFees
          monthlyInsurance
          pmi
          hoa
          electricity
          waterAndSewer
          garbage
          internet
          cleaning
          supplies
          otherMonthlyExpenses
          annualExpensesGrowth
          annualIncomeGrowth
          annualPropertyValueGrowth
          salesExpenses
        }
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shortTermRentalOnly"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "states"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "createdByUid",
        "variableName": "uid"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "shortTermRentalOnly",
        "variableName": "shortTermRentalOnly"
      },
      {
        "kind": "Variable",
        "name": "states",
        "variableName": "states"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RentalReportPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RentalReportListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RentalReportPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RentalReportConnection",
        "kind": "LinkedField",
        "name": "RentalReportConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RentalReportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RentalReport",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "watchlistStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isShortTermRental",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBRRRR",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "propertyUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RentalReportPropertyInfo",
                    "kind": "LinkedField",
                    "name": "propertyInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reportTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "zipcode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bedrooms",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bathrooms",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSqft",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annualTax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photos",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RentalReportPurchaseInfo",
                    "kind": "LinkedField",
                    "name": "purchaseInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "purchasePrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "listingPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closingCost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "repairCost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCashPurchase",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pointsChargedByLender",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "wrapLoanFeesIntoLoan",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "otherChargesFromLender",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "loanInterestRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downPayment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amortizedYears",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "afterRepairValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RentalReportRentalInfo",
                    "kind": "LinkedField",
                    "name": "rentalInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "monthlyRent",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dailyRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "occupancyRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "otherMonthlyIncome",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vacancy",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "capex",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maintenance",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "managementFees",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "monthlyInsurance",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pmi",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hoa",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "electricity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "waterAndSewer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "garbage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "internet",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cleaning",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "supplies",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "otherMonthlyExpenses",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annualExpensesGrowth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annualIncomeGrowth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annualPropertyValueGrowth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "salesExpenses",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "Query_RentalReportConnection",
        "kind": "LinkedHandle",
        "name": "RentalReportConnection"
      }
    ]
  },
  "params": {
    "cacheID": "ca5c0e5ca0a2bd166871c93084d97635",
    "id": null,
    "metadata": {},
    "name": "RentalReportPaginationQuery",
    "operationKind": "query",
    "text": "query RentalReportPaginationQuery(\n  $count: Int\n  $cursor: String\n  $search: String\n  $shortTermRentalOnly: Boolean\n  $states: [String!]\n  $uid: String\n) {\n  ...RentalReportListFragment\n}\n\nfragment RentalReportListFragment on Query {\n  RentalReportConnection(first: $count, after: $cursor, filter: {createdByUid: $uid, states: $states, search: $search, shortTermRentalOnly: $shortTermRentalOnly}) {\n    edges {\n      node {\n        _id\n        createTime\n        watchlistStatus\n        isShortTermRental\n        isBRRRR\n        propertyUrl\n        propertyInfo {\n          reportTitle\n          address\n          city\n          state\n          zipcode\n          bedrooms\n          bathrooms\n          totalSqft\n          annualTax\n          photos\n        }\n        purchaseInfo {\n          purchasePrice\n          listingPrice\n          closingCost\n          repairCost\n          isCashPurchase\n          pointsChargedByLender\n          wrapLoanFeesIntoLoan\n          otherChargesFromLender\n          loanInterestRate\n          downPayment\n          amortizedYears\n          afterRepairValue\n        }\n        rentalInfo {\n          monthlyRent\n          dailyRate\n          occupancyRate\n          otherMonthlyIncome\n          vacancy\n          capex\n          maintenance\n          managementFees\n          monthlyInsurance\n          pmi\n          hoa\n          electricity\n          waterAndSewer\n          garbage\n          internet\n          cleaning\n          supplies\n          otherMonthlyExpenses\n          annualExpensesGrowth\n          annualIncomeGrowth\n          annualPropertyValueGrowth\n          salesExpenses\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8f922209b3efa56df56d29191c319947';
export default node;
