/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Checkbox, Row, Col, Card, Input, Popconfirm, Button } from 'antd';
import { mapPropsToFields } from 'helpers';
import FormInputNumber from 'components/FormInputNumber';
import BasicRow from 'components/BasicRow';
import { FormInstance } from 'antd/lib/form';
import { MortgageInterface } from 'mortgage/types';
import { removeMortgage, toggleMortgageExpanded, updateMortgage } from 'redux/actions/mortgage';
import { budgetDisabledStyle } from 'consts/budget';
import { DeleteOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';

interface Props extends Dispatch {
    mortgage: MortgageInterface;
    index: number;
    style: React.CSSProperties;
}

const MortgageForm = (props: Props) => {
    const formRef = useRef<FormInstance | null>(null);

    const { mortgage, style, index } = props;

    const getHeader = () => (
        <Row style={{ textAlign: 'left', cursor: 'pointer' }}>
            <Col span={20} onClick={() => props.toggleMortgageExpanded(index, !mortgage.expanded )}>
                {mortgage.expanded ? <DownOutlined /> : <RightOutlined />}

                <h3 style={{ display: 'inline-block', marginBottom: '0', marginLeft: '12px' }}>{mortgage.title}</h3>
            </Col>

            <Col span={2} offset={2}>
                <Popconfirm
                    title="Do you want to delete this mortgage?"
                    onConfirm={
                        () => {
                            props.removeMortgage(index);
                        }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
            </Col>
        </Row>
    );

    if (!mortgage.expanded) {
        return <Card style={style}>{getHeader()}</Card>;
    }

    const handleSubmit = (values) => {
        props.updateMortgage(index, values);
    };


    const getLoanDetailsComponent = () => {
        const isCashPurchase = props.mortgage.isCashPurchase;
        if (!isCashPurchase) {
            return (
                <>
                    <br />
                    <BasicRow>
                        <Col lg={8} xs={24}>
                            <Form.Item label="Down Payment" name="downPayment" rules={[{ required: true }]}>
                                <FormInputNumber percentage />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xs={24}>
                            <Form.Item label="Interest Rate" name="loanInterestRate" rules={[{ required: true }]}>
                                <FormInputNumber percentage />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xs={24}>
                            <Form.Item label="Amortized Years" name="amortizedYears" rules={[{ required: true }]}>
                                <FormInputNumber />
                            </Form.Item>
                        </Col>
                    </BasicRow>


                    <Row>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Points" name="pointsChargedByLender">
                                <FormInputNumber percentage />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label="Fees" name="fees">
                                <FormInputNumber dollar />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '12px' }}>
                        <h4 style={{ float: 'left' }}>Wrap fees into the loan?</h4>
                        <Form.Item name="wrapLoanFeesIntoLoan" valuePropName="checked" noStyle>
                            <Checkbox style={{ float: 'left', marginLeft: '10px' }} />
                        </Form.Item>
                    </Row>

                    <Row>
                        <h4 style={{ float: 'left' }}>Interest Only?</h4>
                        <Form.Item name="isInterestOnly" valuePropName="checked" noStyle>
                            <Checkbox disabled style={{ float: 'left', marginLeft: '10px' }} />
                        </Form.Item>
                    </Row>
                </>
            );
        } else {
            return null;
        }
    };

    const getSummary = () => {
        return (
            <>
                <br />
                <h2 style={{ textAlign: 'left' }}>Summary</h2>
                <br />
                <Row>
                    <Col lg={8} sm={24}>
                        <Form.Item label="Interest Rate" name="loanInterestRate">
                            <FormInputNumber percentage disabled style={{ ...budgetDisabledStyle, left: '-12px' }} />
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <Form.Item label="Monthly Payments" name="monthlyPayments">
                            <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <Form.Item label="APR" name="apr">
                            <FormInputNumber percentage disabled style={{ ...budgetDisabledStyle, left: '-12px' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} sm={24}>
                        <Form.Item label="Down Payment" name="downPaymentAmount">
                            <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <Form.Item label="Closing Costs" name="closingCosts">
                            <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <Form.Item label="Total Cash Upfront" name="totalCash">
                            <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    };


    return (
        <Card style={style}>
            {getHeader()}
            <br />
            <Form
                layout="vertical"
                onFinish={handleSubmit}
                ref={formRef}
                fields={mapPropsToFields(mortgage)}
                onValuesChange={(values) => props.updateMortgage(props.index, values)}
            >
                <Row>
                    <Col lg={20} xs={24}>
                        <Form.Item label="Mortgage Name" name="mortgageName">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} xs={24}>
                        <Form.Item label="Purcahse Price" name="purchasePrice" rules={[{ required: true }]}>
                            <FormInputNumber dollar />
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <div className="ant-row ant-form-item" style={{ marginBottom: '12px', marginTop: '16px' }}>
                            <div className="ant-col ant-form-item-label">
                                <label style={{ float: 'left' }}>All Cash?</label>
                                <Form.Item name="isCashPurchase" noStyle valuePropName="checked">
                                    <Checkbox style={{ float: 'left', marginLeft: '8px', paddingTop: '4.8px' }} />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>

                {getLoanDetailsComponent()}

                {getSummary()}
            </Form>
        </Card>
    );
};

const mapStateToProps = null;

interface Dispatch {
    updateMortgage: (index: number, mortgage: MortgageInterface) => void;
    toggleMortgageExpanded: (index: number, expanded: boolean) => void;
    removeMortgage: (index: number) => void;
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMortgage: (index: number, mortgage: MortgageInterface) => {
            dispatch(updateMortgage(index, mortgage));
        },
        toggleMortgageExpanded: (index: number, expanded: boolean) => {
            dispatch(toggleMortgageExpanded(index, expanded));
        },
        removeMortgage: (index: number) => {
            dispatch(removeMortgage(index));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MortgageForm);
