import React from 'react';
import { Steps, Card } from 'antd';
import ReportButtons from '../Report/ReportButtons';
import { RentalCardUIProps } from './RentalCard';
import NavigationButtons from 'rental/NavigationButtons';
import RefinanceInfoForm from 'rental/RefinanceInfoForm';

const Step = Steps.Step;

const RentalSmCard = (props: RentalCardUIProps) => {
    const { loading, isSharedCard, currentReport, reportId, fetchWebsiteLoading, resetReport, saveReport, applyDefault, onValidationSuccess, onValidationFailed, shouldValidate, step, setStep, setValidated, elements, propertyUrl, isBRRRR } = props;

    const { PropertyInfoForm, PurchaseInfoForm, RentalInfoForm, RehabInfoForm, Report } = elements;

    const steps = isBRRRR ? [
        {
            title: 'Property',
            content: (
                <PropertyInfoForm
                    propertyInfo={currentReport?.propertyInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    fetchWebsiteLoading={fetchWebsiteLoading}
                    shouldValidate={shouldValidate && step === 0}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Purchase',
            content: (
                <PurchaseInfoForm
                    purchaseInfo={currentReport?.purchaseInfo}
                    closingCost={currentReport?.closingCostBreakdown}
                    repairCost={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 1}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    isBRRRR={isBRRRR}
                />
            ),
        },
        {
            title: 'Rehab',
            content: (
                <RehabInfoForm
                    rehabInfo={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 2}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Rental',
            content: (
                <RentalInfoForm
                    rentalInfo={currentReport?.rentalInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 3}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    isShortTermRental={currentReport?.isShortTermRental}
                />
            ),
        },{
            title: 'Refinance',
            content: (
                <RefinanceInfoForm
                    refinanceInfo={currentReport?.refinanceInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 4}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Report',
            content: <Report currentReport={currentReport} />,
        },
    ] : [
        {
            title: 'Property',
            content: (
                <PropertyInfoForm
                    propertyInfo={currentReport?.propertyInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    fetchWebsiteLoading={fetchWebsiteLoading}
                    shouldValidate={shouldValidate && step === 0}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    propertyUrl={propertyUrl}
                />
            ),
        },
        {
            title: 'Purchase',
            content: (
                <PurchaseInfoForm
                    purchaseInfo={currentReport?.purchaseInfo}
                    closingCost={currentReport?.closingCostBreakdown}
                    repairCost={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 1}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        {
            title: 'Rental',
            content: (
                <RentalInfoForm
                    rentalInfo={currentReport?.rentalInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 2}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                    isShortTermRental={currentReport?.isShortTermRental}
                />
            ),
        },
        {
            title: 'Report',
            content: <Report currentReport={currentReport} />,
        },
    ];
    return (
        <div>
            <header style={{ textAlign: 'center' }}>
                <h1 style={{ paddingTop: '4vh' }}> Rental Property Analysis </h1>
            </header>
            <Steps current={step} size="small" direction="horizontal" style={{ padding: '2px 10px 0 10px' }}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <Card bordered={false} className="split-card-mobile" loading={loading}>
                <div className="steps-content-mobile">{steps[step].content}</div>
                <NavigationButtons
                    isBRRRR={isBRRRR}
                    step={step}
                    previousStep={() => {
                        setStep(Math.max(0, step - 1));
                    }}
                    nextStep={() => {
                        setStep(Math.min(isBRRRR ? 5 : 3, step + 1));
                    }}
                    disabled={fetchWebsiteLoading}
                />
                {!isSharedCard && (
                    <ReportButtons
                        graphqlId={currentReport?.id}
                        reportId={reportId}
                        onSave={saveReport}
                        onReset={resetReport}
                        applyDefault={applyDefault}
                        watchlistStatus={currentReport?.watchlistStatus ?? null}
                        enableRentalTypeButton
                        rentalTypeLabel={
                            currentReport == null || !currentReport.isShortTermRental
                                ? 'Switch to Short Term Rental'
                                : 'Switch to Long Term Rental'
                        }
                        onChangeRentalType={props.onChangeRentalType}
                        enableBRRRRButton
                        brrrrLabel={
                            currentReport?.isBRRRR
                                ? 'Switch to Rental'
                                : 'Switch to BRRRR'
                        }
                        onChangeBRRRR={props.onChangeBRRRR}
                    />
                )}
            </Card>
        </div>
    );
};

export default RentalSmCard;
