/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AppQueryVariables = {
    uid: string;
    userNotLoggedIn: boolean;
};
export type AppQueryResponse = {
    readonly UserByUid?: {
        readonly uid: string | null;
        readonly allowRedfinEmail: boolean | null;
        readonly allowFetchWebsite: boolean | null;
    } | null;
    readonly DefaultStrategy?: ReadonlyArray<{
        readonly id: string;
        readonly _id: unknown;
        readonly closingCost: number | null;
        readonly repairCost: number | null;
        readonly downPayment: number | null;
        readonly loanInterestRate: number | null;
        readonly wrapLoanFeesIntoLoan: boolean | null;
        readonly pointsChargedByLender: number | null;
        readonly amortizedYears: number | null;
        readonly electricity: number | null;
        readonly waterAndSewer: number | null;
        readonly pmi: number | null;
        readonly garbage: number | null;
        readonly hoa: number | null;
        readonly monthlyInsurance: number | null;
        readonly otherMonthlyExpenses: number | null;
        readonly vacancy: number | null;
        readonly maintenance: number | null;
        readonly capex: number | null;
        readonly managementFees: number | null;
        readonly annualIncomeGrowth: number | null;
        readonly annualPropertyValueGrowth: number | null;
        readonly annualExpensesGrowth: number | null;
        readonly salesExpenses: number | null;
    }>;
};
export type AppQuery = {
    readonly response: AppQueryResponse;
    readonly variables: AppQueryVariables;
};



/*
query AppQuery(
  $uid: String!
  $userNotLoggedIn: Boolean!
) {
  UserByUid(filter: {uid: $uid}) @skip(if: $userNotLoggedIn) {
    uid
    allowRedfinEmail
    allowFetchWebsite
    id
  }
  DefaultStrategy @skip(if: $userNotLoggedIn) {
    id
    _id
    closingCost
    repairCost
    downPayment
    loanInterestRate
    wrapLoanFeesIntoLoan
    pointsChargedByLender
    amortizedYears
    electricity
    waterAndSewer
    pmi
    garbage
    hoa
    monthlyInsurance
    otherMonthlyExpenses
    vacancy
    maintenance
    capex
    managementFees
    annualIncomeGrowth
    annualPropertyValueGrowth
    annualExpensesGrowth
    salesExpenses
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userNotLoggedIn"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "uid"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowRedfinEmail",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowFetchWebsite",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DefaultStrategy",
  "kind": "LinkedField",
  "name": "DefaultStrategy",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closingCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repairCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loanInterestRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wrapLoanFeesIntoLoan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsChargedByLender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amortizedYears",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "electricity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waterAndSewer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pmi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "garbage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyInsurance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherMonthlyExpenses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vacancy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maintenance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualIncomeGrowth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualPropertyValueGrowth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualExpensesGrowth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesExpenses",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": [
      {
        "condition": "userNotLoggedIn",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "UserByUid",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppQuery",
    "selections": [
      {
        "condition": "userNotLoggedIn",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "UserByUid",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "708f8bd9a35d5e55571a6edcacae73a2",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery(\n  $uid: String!\n  $userNotLoggedIn: Boolean!\n) {\n  UserByUid(filter: {uid: $uid}) @skip(if: $userNotLoggedIn) {\n    uid\n    allowRedfinEmail\n    allowFetchWebsite\n    id\n  }\n  DefaultStrategy @skip(if: $userNotLoggedIn) {\n    id\n    _id\n    closingCost\n    repairCost\n    downPayment\n    loanInterestRate\n    wrapLoanFeesIntoLoan\n    pointsChargedByLender\n    amortizedYears\n    electricity\n    waterAndSewer\n    pmi\n    garbage\n    hoa\n    monthlyInsurance\n    otherMonthlyExpenses\n    vacancy\n    maintenance\n    capex\n    managementFees\n    annualIncomeGrowth\n    annualPropertyValueGrowth\n    annualExpensesGrowth\n    salesExpenses\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c9fcd73022be56b2ad11f1131a33c5e9';
export default node;
