import React from 'react';
import { Modal, Form } from 'antd';
import { PURCHASE_CLOSING_COST_BREAKDOWN_LABELS } from 'consts/rental';
import { mapPropsToFields, createFormFromArray } from 'helpers';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ClosingCostModal$key } from './__generated__/ClosingCostModal.graphql';
import RelayEnvironment from 'RelayEnvironment';

const {
    POINTS_FEE,
    PREPAID_HAZARD_INSURANCE,
    PREPAID_FLOOD_INSURANCE,
    PREPAID_PROPERTY_TAXES,
    ANNUAL_ASSESSMENTS,
    TITLE_AND_ESCROW_FEES,
    ATTORNEY_CHARGES,
    INSPECTION_COSTS,
    RECORDING_FEES,
    APPRAISAL_FEES,
    OTHER_FEES,
} = PURCHASE_CLOSING_COST_BREAKDOWN_LABELS;

interface Props {
    toggleModal: any;
    visible: boolean;
    closingCost?: ClosingCostModal$key | null;
    reportId?: string | null;
}

const ClosingCostModal = (props: Props) => {
    const closingCostBreakdown = useFragment(
        graphql`
            fragment ClosingCostModal on RentalReportClosingCostBreakdown {
                points
                prepaidHazardInsurance
                prepaidFloodInsurance
                prepaidPropertyTaxes
                annualAssesements
                titleAndEscrowFees
                attorneyCharges
                inspectionCosts
                recordingFees
                appraisalFees
                otherFees
                totalClosingCost
            }
        `,
        props.closingCost ?? null);
    const [form] = Form.useForm();
    const handleClosingCostModalOk = () => {
        form.validateFields().then((values) => {
            props.toggleModal(false);
            commitLocalUpdate(RelayEnvironment, store => {
                const id = props.reportId as string;
                if (id != null) {
                    const record = store.get(id);
                    if (record != null) {
                        const totalClosingCost = Object.keys(values).reduce((sum, key) => {
                            return sum + (values[key] ?? 0);
                        }, 0);
                        values.totalClosingCost = totalClosingCost;
                        const purchaseInfoRecord = record.getLinkedRecord('purchaseInfo');
                        if (purchaseInfoRecord != null) {
                            purchaseInfoRecord.setValue(totalClosingCost, 'closingCost');
                        }

                        const closingCostBreakdownRecord = record.getLinkedRecord('closingCostBreakdown');
                        if (closingCostBreakdownRecord != null) {
                            Object.keys(values).forEach(key => {
                                closingCostBreakdownRecord?.setValue(values[key], key);
                            });
                        }
                    }
                }
            });
        });
    };

    const handleClosingCostModalCancel = () => {
        props.toggleModal(false);
    };

    const closingSubcostArray = [
        POINTS_FEE,
        PREPAID_HAZARD_INSURANCE,
        PREPAID_FLOOD_INSURANCE,
        PREPAID_PROPERTY_TAXES,
        ANNUAL_ASSESSMENTS,
        TITLE_AND_ESCROW_FEES,
        ATTORNEY_CHARGES,
        INSPECTION_COSTS,
        RECORDING_FEES,
        APPRAISAL_FEES,
        OTHER_FEES,
    ];
    const formHTML = createFormFromArray(closingSubcostArray, 3);

    return (
        <Modal
            title="Purchase Closing Cost Breakdown"
            visible={props.visible}
            onOk={handleClosingCostModalOk}
            onCancel={handleClosingCostModalCancel}
            width={700}
        >
            <Form
                fields={mapPropsToFields(closingCostBreakdown)}
                form={form}
                scrollToFirstError
            >
                {formHTML}
            </Form>
        </Modal>
    );
};

export default ClosingCostModal;
