import React, { useEffect } from 'react';
import { Card, Spin } from 'antd';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { withRouter, RouteComponentProps } from 'react-router';
import GoogleMap from './GoogleMap';
import * as actions from 'redux/actions/map';
import { MapInterface, NeighborhoodStatsInterface } from '../types';

interface RouteMatchParams {
    id?: string;
}
interface Props extends RouteComponentProps<RouteMatchParams>, StoreState {
    populateMapCard: any;
    firebaseAuthLoaded: boolean;
}

const MapCard = (props: Props) => {
    const { currentMap, firebaseAuthLoaded, match, populateMapCard } = props;
    useEffect(() => {
        const urlID = match.params.id;
        if (currentMap._id !== urlID && firebaseAuthLoaded) {
            populateMapCard(urlID);
        }
    }, [currentMap._id, firebaseAuthLoaded, match.params.id, populateMapCard]);

    const getGoogleMap = () => {
        if (
            props.currentMap == null ||
            props.currentMap.listings.length === 0 ||
            props.censusData == null ||
            props.censusData.length === 0
        ) {
            return <Spin style={{ marginTop: '200px' }} size="large" tip="Loading..." />;
        }
        return <GoogleMap currentMapData={props.currentMap} censusData={props.censusData} />;
    };

    return (
        <div>
            <header style={{ height: '10vh', textAlign: 'center' }}>
                <h1 style={{ paddingTop: '4vh' }}> Real Estate Map </h1>
            </header>
            <Card bordered={false} className="split-card">
                <div className="budget-content">{getGoogleMap()}</div>
            </Card>
        </div>
    );
};

interface StoreState {
    currentMap: MapInterface;
    censusData: NeighborhoodStatsInterface[];
    firebaseAuthLoaded: boolean;
}

const mapStateToProps = (state: AppState): StoreState => ({
    currentMap: state.map.currentMap,
    censusData: state.map.censusData,
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
});

interface Dispatch {
    populateMapCard: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        populateMapCard: (reportId: string, censusData: NeighborhoodStatsInterface[]) => {
            dispatch(actions.populateMapCard(reportId));
            if (censusData == null || censusData.length === 0) {
                dispatch(actions.fetchCensusData());
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MapCard));
