import { getDerivedNetWorthInfo } from './../selectors/netWorthTracker';
import { NetWorthFormItemInterface } from './../../netWorthTracker/types';
import { checkNamespace } from 'helpers';
import { NetWorthTrackerState, MonthlyNetWorthInterface } from 'netWorthTracker/types';
import * as actionTypes from '../actions/netWorthTracker';
import moment from 'moment';

const date = moment().format('YYYY/MM');
const initialMonthlyNetWorthState: MonthlyNetWorthInterface = {
    date: date,
    assetsInfo: {
        assets: [
            {
                key: 'Stocks',
                value: 0,
            },
            {
                key: 'Bonds',
                value: 0,
            },
            {
                key: 'Real Estate',
                value: 0,
            }
        ],
        retirementAccounts: [
            {
                key: '401k',
                value: 0,
            }
        ],
        totalAssets: 0,
        totalAssetsWithoutRetirementAccounts: 0,
        totalRetirementAccounts: 0,
    },
    liabilitiesInfo: {
        liabilities: [{
            key: 'Mortgage',
            value: 0,
        }],
        totalLiabilities: 0,
    },
    summary: {
        totalAssets: 0,
        totalAssetsWithoutRetirementAccounts: 0,
        totalRetirementAccounts: 0,
        totalLiabilities: 0,
        totalNetWorth: 0,
    },
};

export const initialNetWorthTrackerState: NetWorthTrackerState = {
    currentNetWorthInfo: JSON.parse(JSON.stringify(initialMonthlyNetWorthState)),
    netWorthRecords: [],
    loading: false,
};

export default function (state = initialNetWorthTrackerState, action): NetWorthTrackerState {
    if (!checkNamespace(action, 'net-worth-tracker')) return state;

    const currentNetWorthInfo = state.currentNetWorthInfo;
    const deriveNetWorthResults = (currentNetWorthInfo: MonthlyNetWorthInterface): NetWorthTrackerState => ({
        ...state,
        currentNetWorthInfo: getDerivedNetWorthInfo(currentNetWorthInfo),
    });

    switch (action.type) {
        case actionTypes.SET_DATE: {
            const { date } = action.payload;
            return deriveNetWorthResults({
                ...currentNetWorthInfo,
                date,
            });
        }
        case actionTypes.SET_ASSETS_INFO: {
            const { assetsInfo } = action.payload;
            return deriveNetWorthResults({
                ...currentNetWorthInfo,
                assetsInfo: { ...currentNetWorthInfo?.assetsInfo, ...assetsInfo }
            });
        }
        case actionTypes.SET_LIABILITIES_INFO: {
            const { liabilitiesInfo } = action.payload;
            return deriveNetWorthResults({
                ...currentNetWorthInfo,
                liabilitiesInfo: { ...currentNetWorthInfo?.liabilitiesInfo, ...liabilitiesInfo },
            });
        }
        case actionTypes.SET_COMMENTS: {
            const { comments } = action.payload;
            return deriveNetWorthResults({
                ...currentNetWorthInfo,
                comments: comments,
            });
        }
        case actionTypes.SAVE_REPORT: {
            const { reportInfo } = action.payload;
            return {
                ...state,
                currentNetWorthInfo: JSON.parse(JSON.stringify(initialMonthlyNetWorthState)),
                netWorthRecords: [...state.netWorthRecords, reportInfo],
            };
        }
        case actionTypes.RESET_REPORT: {
            return deriveNetWorthResults({
                date: moment().format('YYYY/mm'),
                assetsInfo: {
                    assets: [] as NetWorthFormItemInterface[],
                    retirementAccounts: [] as NetWorthFormItemInterface[],
                    totalAssets: 0,
                    totalAssetsWithoutRetirementAccounts: 0,
                    totalRetirementAccounts: 0,
                },
                liabilitiesInfo: {
                    liabilities: [] as NetWorthFormItemInterface[],
                    totalLiabilities: 0,
                },
                summary: {
                    totalAssets: 0,
                    totalAssetsWithoutRetirementAccounts: 0,
                    totalLiabilities: 0,
                    totalNetWorth: 0,
                    totalRetirementAccounts: 0,
                },
            });
        }
        case actionTypes.FETCH_NET_WORTH: {
            return {
                ...state,
                loading: true,
            };
        }
        case actionTypes.FETCH_NET_WORTH_SUCCEEDED: {
            const { data } = action.payload;
            return {
                ...state,
                netWorthRecords: [...data],
                loading: false,
            };
        }
        case actionTypes.FETCH_NET_WORTH_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }
        case actionTypes.POPULATE_NET_WORTH_CARD: {
            return {
                ...state,
                currentNetWorthInfo: JSON.parse(JSON.stringify(initialMonthlyNetWorthState)),
            };
        }
        case actionTypes.POPULATE_NET_WORTH_DASHBOARD: {
            return state;
        }
        default:
            return state;
    }
}
