import React from 'react';
import { Input, Checkbox, Row, Col } from 'antd';
import { PROPERTY_INFO_LABELS } from 'consts/rental';
import FormItem from 'components/RentalFormItem';
import FormInputNumber from 'components/FormInputNumber';

const textAreaLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const { TextArea } = Input;

interface Props {
    showOtherPropertyFeatures?: boolean;
    disabled?: boolean;
}

const OtherPropertyInfoForm = (props: Props) => {
    const { showOtherPropertyFeatures } = props;
    const {
        YEAR_BUILT,
        YEAR_RENOVATED,
        UNITS,
        STORIES,
        COUNTY_APPRAISED_VALUE,
        POOL,
        HEATING,
        COOLING,
        FIREPLACE,
        GARAGE,
        CONSTRUCTION,
        ROOFING,
        FLOORING_TYPES,
        WIRING_CONDITION,
        PLUMBING_CONDITION,
        SIDING_MATERIAL,
        OTHER_INFORMATION,
    } = PROPERTY_INFO_LABELS;

    return (
        <div
            style={{
                marginTop: '24px',
                display: showOtherPropertyFeatures ? 'block' : 'none',
            }}
        >
            <Row gutter={12}>
                <Col lg={12} xs={24}>
                    <FormItem label={YEAR_BUILT} name="yearBuilt">
                        <FormInputNumber min={0} zeroMarginTop disabled={props.disabled} />
                    </FormItem>
                </Col>
                <Col lg={12} xs={24}>
                    <FormItem label={YEAR_RENOVATED} name="yearRenovated">
                        <FormInputNumber min={0} zeroMarginTop disabled={props.disabled} />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col lg={12} xs={24}>
                    <FormItem label={UNITS} name="units">
                        <FormInputNumber min={0} zeroMarginTop disabled={props.disabled} />
                    </FormItem>
                </Col>

                <Col lg={12} xs={24}>
                    <FormItem label={STORIES} name="stories">
                        <FormInputNumber min={0} zeroMarginTop disabled={props.disabled} />
                    </FormItem>
                </Col>
            </Row>

            <FormItem label={COUNTY_APPRAISED_VALUE} name="countyAppraisedValue">
                <FormInputNumber dollar zeroMarginTop disabled={props.disabled} />
            </FormItem>

            <Row gutter={8}>
                <Col lg={4} xs={8}>
                    <FormItem name="pool" valuePropName="checked">
                        <Checkbox disabled={props.disabled}>
                            <span className="checkbox-label">{POOL}</span>
                        </Checkbox>
                    </FormItem>
                </Col>

                <Col lg={5} xs={8}>
                    <FormItem name="heating" valuePropName="checked">
                        <Checkbox disabled={props.disabled}>
                            <span className="checkbox-label">{HEATING}</span>
                        </Checkbox>
                    </FormItem>
                </Col>

                <Col lg={5} xs={8}>
                    <FormItem name="cooling" valuePropName="checked">
                        <Checkbox disabled={props.disabled}>
                            <span className="checkbox-label">{COOLING}</span>
                        </Checkbox>
                    </FormItem>
                </Col>

                <Col lg={5} xs={8}>
                    <FormItem name="fireplace" valuePropName="checked">
                        <Checkbox disabled={props.disabled}>
                            <span className="checkbox-label">{FIREPLACE}</span>
                        </Checkbox>
                    </FormItem>
                </Col>

                <Col lg={5} xs={8}>
                    <FormItem name="garage" valuePropName="checked">
                        <Checkbox disabled={props.disabled}>
                            <span className="checkbox-label">{GARAGE}</span>
                        </Checkbox>
                    </FormItem>
                </Col>
            </Row>

            <FormItem label={CONSTRUCTION} name="construction">
                <Input disabled={props.disabled} />
            </FormItem>

            <FormItem label={ROOFING} name="roofing">
                <Input disabled={props.disabled} />
            </FormItem>

            <FormItem label={FLOORING_TYPES} name="flooringTypes">
                <Input disabled={props.disabled} />
            </FormItem>

            <FormItem label={WIRING_CONDITION} name="wiringCondition">
                <Input disabled={props.disabled} />
            </FormItem>

            <FormItem label={PLUMBING_CONDITION} name="plumbingCondition">
                <Input disabled={props.disabled} />
            </FormItem>

            <FormItem label={SIDING_MATERIAL} name="sidingMaterial">
                <Input disabled={props.disabled} />
            </FormItem>

            <FormItem {...textAreaLayout} label={OTHER_INFORMATION} name="otherInformation">
                <TextArea rows={4} disabled={props.disabled} />
            </FormItem>
        </div>
    );
};

export default OtherPropertyInfoForm;
