import { MortgageInterface } from './../../mortgage/types';
const setMortgageNamespace = (actionType: string) => 'mortgage/' + actionType;

// actionTypes
export const SET_MORTGAGE_TITLE = setMortgageNamespace('SET_MORTGAGE_TITLE');
export const RESET_REPORT = setMortgageNamespace('RESET_REPORT');

export const SAVE_REPORT = setMortgageNamespace('SAVE_REPORT');
export const SAVE_REPORT_SUCCEEDED = setMortgageNamespace('SAVE_REPORT_SUCCEEDED');
export const SAVE_REPORT_FAILED = setMortgageNamespace('SAVE_REPORT_FAILED');
export const FETCH_MORTGAGE_REPORTS = setMortgageNamespace('FETCH_MORTGAGE_REPORTS');
export const FETCH_MORTGAGE_REPORTS_SUCCEEDED = setMortgageNamespace('FETCH_MORTGAGE_REPORTS_SUCCEEDED');
export const FETCH_MORTGAGE_REPORTS_FAILED = setMortgageNamespace('FETCH_MORTGAGE_REPORTS_FAILED');
export const DELETE_REPORT = setMortgageNamespace('DELETE_REPORT');

export const POPULATE_MORTGAGE_CARD = setMortgageNamespace('POPULATE_MORTGAGE_CARD');
export const POPULATE_MORTGAGE_CARD_ASYNC = setMortgageNamespace('POPULATE_MORTGAGE_CARD_ASYNC');

export const ADD_NEW_MORTGAGE = setMortgageNamespace('ADD_NEW_MORTGAGE');
export const REMOVE_MORTGAGE = setMortgageNamespace('REMOVE_MORTGAGE');
export const UPDATE_MORTGAGE = setMortgageNamespace('UPDATE_MORTGAGE');
export const TOGGLE_MORTGAGE_EXPANDED = setMortgageNamespace('TOGGLE_MORTGAGE_EXPANDED');
export const UPDATE_DISCOUNT_RATE = setMortgageNamespace('UPDATE_DISCOUNT_RATE');

// actions
export const setMortgageTitle = (reportTitle) => ({ type: SET_MORTGAGE_TITLE, payload: { reportTitle } });

export const saveNewReport = (onSuccess, onFailure, reportInfo) => {
    return { type: SAVE_REPORT, payload: { onSuccess, onFailure, reportInfo } };
};

export const deleteReport = (onSuccess, onFailure, reportId) => {
    return { type: DELETE_REPORT, payload: { onSuccess, onFailure, reportId } };
};

export const fetchMortgageReports = (ids?: string[]) => ({
    type: FETCH_MORTGAGE_REPORTS,
    payload: { ids },
});

export const populateMortgageCard = (reportId: string) => ({
    type: POPULATE_MORTGAGE_CARD_ASYNC,
    payload: {
        reportId,
    },
});

export const addNewMortgage = () => ({
    type: ADD_NEW_MORTGAGE,
    payload: {},
});

export const removeMortgage = (index: number) => ({
    type: REMOVE_MORTGAGE,
    payload: { index },
});

export const updateMortgage = (index: number, mortgage: MortgageInterface) => ({
    type: UPDATE_MORTGAGE,
    payload: { index, mortgage },
});

export const toggleMortgageExpanded = (index: number, expanded: boolean) => ({
    type: TOGGLE_MORTGAGE_EXPANDED,
    payload: { index, expanded },
});

export const updateDiscountRate = (discountRate: number) => ({
    type: UPDATE_DISCOUNT_RATE,
    payload: { discountRate },
});
