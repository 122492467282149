/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Checkbox, Row, Col, Card } from 'antd';
import { PROPERTY_INFO_LABELS, PURCHASE_LABELS } from 'consts/rental';
import ClosingCostModal from './ClosingCostModal';
import RepairCostModal from './RepairCostModal';
import { mapPropsToFields } from 'helpers';
import FormInputNumber from 'components/FormInputNumber';
import FormItem from 'components/RentalFormItem';
import { FormInstance } from 'antd/lib/form';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { PurchaseInfoForm$key } from './__generated__/PurchaseInfoForm.graphql';
import { ClosingCostModal$key } from './__generated__/ClosingCostModal.graphql';
import { RepairCostModal$key } from './__generated__/RepairCostModal.graphql';
import RelayEnvironment from 'RelayEnvironment';

const { LISTING_PRICE } = PROPERTY_INFO_LABELS;

const {
    PURCHASE_PRICE,
    AFTER_REPAIR_VALUE,
    CLOSING_COST,
    REPAIR_COST,
    DOWN_PAYMENT,
    LOAN_INTEREST_RATE,
    POINTS_CHARGED_BY_LENDER,
    OTHER_CHARGES_FROM_THE_LENDER,
    AMORTIZED_YEARS,
    TYPICAL_CAP_RATE,
} = PURCHASE_LABELS;

export interface PurchaseInfoFormProps {
    purchaseInfo?: PurchaseInfoForm$key | null,
    closingCost?: ClosingCostModal$key | null,
    repairCost?: RepairCostModal$key | null,
    onValidationSuccess: () => void;
    onValidationFailed: () => void;
    shouldValidate: boolean;
    setValidated: () => void;
    reportId?: string | null;
    isBRRRR?: boolean;
}

const PurchaseInfoForm = (props: PurchaseInfoFormProps) => {
    const [closingCostModalVisible, setClosingCostModalVisible] = useState(false);
    const [repairCostModalVisible, setRepairCostModalVisible] = useState(false);
    const formRef = useRef<FormInstance | null>(null);
    const purchaseInfo = useFragment(
        graphql`
            fragment PurchaseInfoForm on RentalReportPurchaseInfo {
                purchasePrice
                afterRepairValue
                closingCost
                repairCost
                isCashPurchase
                downPayment
                loanInterestRate
                pointsChargedByLender
                otherChargesFromLender
                wrapLoanFeesIntoLoan
                isInterestOnly
                amortizedYears
                typicalCapRate
                listingPrice
            }
        `,
        props.purchaseInfo ?? null);

    useEffect(() => {
        if (props.shouldValidate) {
            props.setValidated();
            formRef.current
                ?.validateFields()
                .then(() => {
                    props.onValidationSuccess();
                })
                .catch((error) => {
                    props.onValidationFailed();
                });
        }
    }, [props, formRef]);

    const showClosingCostModal = (e) => {
        e.preventDefault();
        setClosingCostModalVisible(true);
    };

    const toggleClosingCostModal = (visible: boolean) => {
        setClosingCostModalVisible(visible);
    };

    const showRepairCostModal = (e) => {
        e.preventDefault();
        setRepairCostModalVisible(true);
    };

    const toggleRepairCostModal = (visible: boolean) => {
        setRepairCostModalVisible(visible);
    };

    const handleSubmit = (values) => {
        commitLocalUpdate(RelayEnvironment, store => {
            const id = props.reportId as string;
            if (id != null) {
                const record = store.get(id);
                if (record != null && record.getLinkedRecord('purchaseInfo') != null) {
                    const purchaseInfo = record.getLinkedRecord('purchaseInfo');
                    Object.keys(values).forEach(key => {
                        purchaseInfo?.setValue(values[key], key);
                    });
                }
            }
        });
    };

    const getLoanDetailsComponent = () => {
        const isCashPurchase = purchaseInfo?.isCashPurchase;
        if (!isCashPurchase) {
            return (
                <Card className='form-card'>
                    <Row gutter={12}>
                        <Col lg={12} xs={24}>
                            <FormItem label={DOWN_PAYMENT} name="downPayment" rules={[{ required: true }]}>
                                <FormInputNumber percentage zeroMarginTop />
                            </FormItem>
                        </Col>
                        <Col lg={12} xs={24}>
                            <FormItem label={LOAN_INTEREST_RATE} name="loanInterestRate" rules={[{ required: true }]}>
                                <FormInputNumber percentage zeroMarginTop />
                            </FormItem>
                        </Col>
                    </Row>

                    <FormItem label={AMORTIZED_YEARS} name="amortizedYears" rules={[{ required: true }]}>
                        <FormInputNumber zeroMarginTop />
                    </FormItem>

                    <FormItem label={POINTS_CHARGED_BY_LENDER} name="pointsChargedByLender">
                        <FormInputNumber percentage zeroMarginTop />
                    </FormItem>

                    <FormItem label={OTHER_CHARGES_FROM_THE_LENDER} name="otherChargesFromLender">
                        <FormInputNumber dollar zeroMarginTop />
                    </FormItem>

                    <Row style={{ marginTop: '12px' }}>
                        <h4 style={{ float: 'left' }}>Are loan fees & points wrapped into the loan?</h4>
                        <FormItem name="wrapLoanFeesIntoLoan" valuePropName="checked" noStyle>
                            <Checkbox style={{ float: 'left', marginLeft: '10px' }} />
                        </FormItem>
                    </Row>

                    <Row>
                        <h4 style={{ float: 'left' }}>Interest Only?</h4>
                        <FormItem name="isInterestOnly" valuePropName="checked" noStyle>
                            <Checkbox disabled style={{ float: 'left', marginLeft: '10px' }} />
                        </FormItem>
                    </Row>

                    <FormItem label={TYPICAL_CAP_RATE} name="typicalCapRate">
                        <FormInputNumber percentage zeroMarginTop />
                    </FormItem>
                </Card>
            );
        } else {
            return null;
        }
    };

    return (
        <Form
            layout="horizontal"
            className="stepForm"
            onFinish={handleSubmit}
            ref={formRef}
            fields={mapPropsToFields(purchaseInfo)}
            onValuesChange={handleSubmit}
            validateMessages={{ required: 'Please fill out ${name}.' }}
        >
            <Card className='form-card'>
                <Row gutter={12}>
                    <Col lg={12} xs={24}>
                        <FormItem label={LISTING_PRICE} name="listingPrice">
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>
                    <Col lg={12} xs={24}>
                        <FormItem label={PURCHASE_PRICE} name="purchasePrice" rules={[{ required: true }]}>
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>
                </Row>

                <FormItem
                    label={CLOSING_COST}
                    name="closingCost"
                    extra={
                        <p className="closing-cost-extra">
                            Enter the total value or provide a <a onClick={showClosingCostModal}>cost breakdown</a>.
                        </p>
                    }
                    style={{ marginBottom: 0 }}
                >
                    <FormInputNumber dollar zeroMarginTop />
                </FormItem>
            </Card>

            <ClosingCostModal
                visible={closingCostModalVisible}
                toggleModal={toggleClosingCostModal}
                closingCost={props.closingCost}
                reportId={props.reportId}
            />

            {
                !props.isBRRRR &&
                <>
                    <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Repair</h3>
                    <Card className='form-card'>
                        <FormItem
                            label={REPAIR_COST}
                            name="repairCost"
                            extra={
                                <p className="closing-cost-extra">
                                    Enter the total value or provide a <a onClick={showRepairCostModal}>cost breakdown</a>.
                                </p>
                            }
                            style={{ marginBottom: 0 }}
                        >
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>

                        <FormItem
                            label={AFTER_REPAIR_VALUE}
                            name="afterRepairValue"
                            style={{ marginBottom: 0 }}
                        >
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Card>

                    <RepairCostModal
                        visible={repairCostModalVisible}
                        toggleModal={toggleRepairCostModal}
                        repairCost={props.repairCost}
                        reportId={props.reportId}
                    />
                </>
            }

            <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Financing</h3>

            <div className="ant-row ant-form-item" style={{ marginBottom: '12px' }}>
                <div className="ant-col">
                    <label style={{ float: 'left' }}>Cash Purchase?</label>
                    <FormItem name="isCashPurchase" noStyle valuePropName="checked" colon={false}>
                        <Checkbox style={{ float: 'left', marginLeft: '8px', paddingTop: '2px' }} />
                    </FormItem>
                </div>
            </div>
            {getLoanDetailsComponent()}
        </Form>
    );
};

export default PurchaseInfoForm;
