import { checkNamespace } from 'helpers';
import * as actionTypes from '../actions/map';
import _ from 'lodash';
import { MapState, MapInterface } from '../../map/types';

export const initialMapState: MapState = {
    currentMap: {
        title: '',
        listings: [],
        _id: '',
    },
    previousMaps: [],
    censusData: [],
};

export default function (state = initialMapState, action): MapState {
    if (!checkNamespace(action, 'map')) return state;

    switch (action.type) {
        case actionTypes.FETCH_CENSUS_DATA_FAILED: {
            return state;
        }
        case actionTypes.FETCH_CENSUS_DATA_SUCCEEDED: {
            const { censusData } = action.payload;
            return {
                ...state,
                censusData,
            };
        }
        case actionTypes.SET_MAP_TITLE: {
            const { title } = action.payload;
            return { ...state, currentMap: { ...state.currentMap, title } };
        }
        case actionTypes.FETCH_MAPS_SUCCEEDED: {
            const { data } = action.payload;
            return {
                ...state,
                previousMaps: [...data],
                // previousMapsLoading: false,
            };
        }
        case actionTypes.FETCH_MAPS_FAILED: {
            return {
                ...state,
                // previousMapsLoading: false,
            };
        }
        case actionTypes.POPULATE_MAP_CARD: {
            const { mapId } = action.payload;
            if (!mapId || mapId.length === 0) {
                return initialMapState;
            } else if (mapId === state.currentMap._id) {
                return state;
            } else {
                const previousLst = state.previousMaps;
                const mapInfo: MapInterface | undefined = _.find(previousLst, { _id: mapId });
                if (!mapInfo) {
                    return initialMapState;
                }
                return {
                    ...state,
                    currentMap: {
                        listings: mapInfo.listings,
                        title: mapInfo.title,
                        _id: mapId,
                    },
                };
            }
        }
        default:
            return state;
    }
}
