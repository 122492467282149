import React from 'react';
import { Layout, Menu, Skeleton } from 'antd';
import ImageGallery from 'components/ImageGallery';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { RentalSider$key } from './__generated__/RentalSider.graphql';
import { getReportSubtitles } from 'redux/selectors';
import { SubtitlesInterface } from 'rental/types';
import PropertyTags from 'components/PropertyTags';

const { Sider } = Layout;

export interface RentalSiderProps {
    step: number;
    setStep: any;
    sharedCard: boolean;
    photos: any;
    mobile?: boolean;
    width: number;
    loading?: boolean;
    currentReport: RentalSider$key | null;
}

const RentalSider = (props: RentalSiderProps) => {
    const rentalSiderData = useFragment(
        graphql`
            fragment RentalSider on RentalReport {
                propertyUrl
                watchlistStatus
                isShortTermRental
                isBRRRR
                propertyInfo {
                    reportTitle
                    address
                    city
                    state
                    zipcode
                    bedrooms
                    bathrooms
                    totalSqft
                    annualTax
                }
            }
        `,
        props.currentReport);

    const subtitles = rentalSiderData == null ? {} as SubtitlesInterface: getReportSubtitles(rentalSiderData);
    const title = rentalSiderData?.propertyInfo?.reportTitle;
    const propertyUrl = rentalSiderData?.propertyUrl;
    const watchlistStatus = rentalSiderData?.watchlistStatus;
    const isShortTermRental = rentalSiderData?.isShortTermRental;

    const getMainInformation = () => {
        if (props.loading) {
            return <Skeleton active avatar paragraph={{ rows: 4 }} />;
        }
        if (
            title == null
            && props.photos == null
            && propertyUrl == null
            && subtitles?.firstAddressSubtitle == null
            && subtitles?.secondAddressSubtitle == null
            && subtitles?.bbSubtitle == null
            && subtitles?.sqftSubtitle == null
        ) {
            return null;
        }
        return (
            <>
                <div style={{ paddingBottom: '24px' }}>
                    {title && <h3 style={{ marginLeft: '24px', marginRight: '24px' }}>{title}</h3>}

                    {!props.loading && props.photos != null && (
                        <ImageGallery
                            items={props.photos}
                            style={{
                                margin: '0 -24px 4px -24px',
                                width: 'calc(100% + 48px)',
                            }}
                            showFullscreenButton
                            showThumbnails={false}
                        />
                    )}
                    <div
                        style={{
                            marginLeft: '24px',
                            marginRight: '24px',
                            marginTop: props.photos != null ? '16px' : 0,
                        }}
                    >
                        {!props.sharedCard && (
                            <PropertyTags
                                style={{ marginBottom: '12px' }}
                                watchlistStatus={watchlistStatus}
                                isShortTermRental={isShortTermRental}
                            />
                        )}
                        <div style={{ fontWeight: 500 }}>{subtitles?.firstAddressSubtitle}</div>
                        <div style={{ fontWeight: 500 }}>{subtitles?.secondAddressSubtitle}</div>
                        <div style={{ fontWeight: 500 }}>
                            {subtitles?.bbSubtitle} {subtitles?.sqftSubtitle}
                        </div>
                        {propertyUrl && (
                            <div
                                style={{
                                    marginTop: '4px',
                                    wordBreak: 'break-all',
                                }}
                            >
                                <a target="_blank" rel="noopener noreferrer" href={propertyUrl}>
                                    {propertyUrl.length > 99 ? propertyUrl.substring(0, 99) + '...' : propertyUrl}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
            </>
        );
    };

    const menuItems = rentalSiderData?.isBRRRR ? [
        'Property',
        'Purchase',
        'Rehab',
        'Rental',
        'Refinance',
    ] : [
        'Property',
        'Purchase',
        'Rental',
    ];
    if (props.mobile) {
        menuItems.push('Report');
    }

    return (
        <Sider width={300} style={{ backgroundColor: 'white' }}>
            <div style={{ marginTop: '24px' }}>
                {getMainInformation()}
                <Menu
                    mode="inline"
                    selectedKeys={[`${props.step}`]}
                    style={{ borderRight: 0, marginTop: '12px' }}
                    onClick={
                        ({ key }) => {
                            const stringKey = key as string;
                            props.setStep(parseInt(stringKey));
                        }
                    }
                >
                    {menuItems.map((name, index) => (
                        <Menu.Item key={index}>
                            {name}
                        </Menu.Item>
                    ))}
                </Menu>
            </div>
        </Sider>

    );
};

export default RentalSider;
