import React from 'react';
import WatchlistStatusTag from './WatchlistStatusTag';
import ShortTermRentalTag from './ShortTermRentalTag';

interface Props {
    watchlistStatus?: string | null;
    isShortTermRental?: boolean | null;
    style?: any;
}

const PropertyTags = (props: Props) => {
    const { watchlistStatus, isShortTermRental, style } = props;

    return (
        <>
            <WatchlistStatusTag watchlistStatus={watchlistStatus} style={style} />
            <ShortTermRentalTag isShortTermRental={isShortTermRental} style={style} />
        </>
    );
};

export default PropertyTags;
