import React from 'react';
import { ExpensesInfoInterface } from '../../types';

export interface ExpensesStoreState {
    expensesInfo: ExpensesInfoInterface;
}

export interface ExpensesDispatch {
    setExpenses: any;
}

interface Props extends ExpensesStoreState, ExpensesDispatch {}

export function expensesFormWrapper(ChildForm) {
    return (props: Props) => {
        const add = () => {
            const expenses = props.expensesInfo.expenses;
            const newExpenses = [...expenses, {}];
            props.setExpenses(newExpenses);
        };

        const remove = (key) => {
            const expenses = props.expensesInfo.expenses;
            const newExpenses = [...expenses.slice(0, key), ...expenses.slice(key + 1)];
            props.setExpenses(newExpenses);
        };

        return <ChildForm {...props} add={add} remove={remove} expensesLength={props.expensesInfo.expenses.length} />;
    };
}
