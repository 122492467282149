import React, { useContext, Suspense } from 'react';
import PreviousRentalReportCard from './PreviousRentalReportCard';
import { List, Spin } from 'antd';
import { getCashflow, getCashOnCash } from 'redux/selectors';
import { initialCurrentRentalState } from 'redux/reducers/rental';
import InfiniteScroll from 'react-infinite-scroller';
import graphql from 'babel-plugin-relay/macro';
import { usePaginationFragment, useLazyLoadQuery } from 'react-relay';
import { RentalReportListQuery } from './__generated__/RentalReportListQuery.graphql';
import { RentalReportListFragment, RentalReportListFragment$key } from './__generated__/RentalReportListFragment.graphql';
import { RentalReportPaginationQuery } from './__generated__/RentalReportPaginationQuery.graphql';
import { UserContext } from 'App';
interface Props {
    searchString: string | undefined;
    sortCriteria: string | undefined;
    stateFilter: string[] | undefined;
    cocFilter: number | null;
    cashflowFilter: number | null;
    shortTermRentalOnly: boolean;
}

type RentalReportEdges = NonNullable<RentalReportListFragment['RentalReportConnection']>['edges'];
type RentalReportNode = NonNullable<RentalReportEdges>[number]['node'];
export interface EnhancedRentalReportNode extends NonNullable<RentalReportNode> {
    cashflow?: number;
    cashOnCash?: number;
}

const RentalReportList = (props: Props) => {
    const { searchString, sortCriteria, stateFilter, cocFilter, cashflowFilter, shortTermRentalOnly } = props;
    const user = useContext(UserContext);

    const queryData = useLazyLoadQuery<RentalReportListQuery>(
        graphql`
          query RentalReportListQuery($count: Int = 15, $cursor: String, $uid: String!, $states: [String!], $search: String, $shortTermRentalOnly: Boolean) {
            ...RentalReportListFragment
          }
        `,
        {
            count: 15,
            cursor: '',
            uid: user?.uid ?? '',
            states: stateFilter,
            search: searchString,
            shortTermRentalOnly,
        },
    );
    const { data, loadNext, hasNext } = usePaginationFragment<
    RentalReportPaginationQuery,
    RentalReportListFragment$key
    >(
        graphql`
            fragment RentalReportListFragment on Query @refetchable(queryName: "RentalReportPaginationQuery") {
                RentalReportConnection(
                    first: $count
                    after: $cursor
                    filter: {
                        createdByUid: $uid
                        states: $states
                        search: $search
                        shortTermRentalOnly: $shortTermRentalOnly
                    }
                ) @connection(key: "Query_RentalReportConnection") {
                    __id
                    edges {
                        node {
                            _id
                            createTime
                            watchlistStatus
                            isShortTermRental
                            isBRRRR
                            propertyUrl
                            propertyInfo {
                                reportTitle
                                address
                                city
                                state
                                zipcode
                                bedrooms
                                bathrooms
                                totalSqft
                                annualTax
                                photos
                            }
                            purchaseInfo {
                                purchasePrice
                                listingPrice
                                closingCost
                                repairCost
                                isCashPurchase
                                pointsChargedByLender
                                wrapLoanFeesIntoLoan
                                otherChargesFromLender
                                loanInterestRate
                                downPayment
                                amortizedYears
                                afterRepairValue
                            }
                            rentalInfo {
                                monthlyRent
                                dailyRate
                                occupancyRate
                                otherMonthlyIncome
                                vacancy
                                capex
                                maintenance
                                managementFees
                                monthlyInsurance
                                pmi
                                hoa
                                electricity
                                waterAndSewer
                                garbage
                                internet
                                cleaning
                                supplies
                                otherMonthlyExpenses
                                annualExpensesGrowth
                                annualIncomeGrowth
                                annualPropertyValueGrowth
                                salesExpenses
                            }
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        queryData,
    );
    const edges = data.RentalReportConnection?.edges.map(edge => edge.node) ?? [];
    const connectionID = data.RentalReportConnection?.__id;

    const cashflowInfoMapper = (list: RentalReportNode[]): EnhancedRentalReportNode[] => {
        return list.map((property) => ({
            ...property,
            cashflow: getCashflow({
                ...initialCurrentRentalState,
                ...property,
            }),
            cashOnCash: getCashOnCash({
                ...initialCurrentRentalState,
                ...property,
            }),
        }));
    };

    const getRentalReportList = () => {
        let list = cashflowInfoMapper(edges);
        if (cocFilter != null) {
            list = list.filter((property) => {
                const coc = property.cashOnCash != null ? property.cashOnCash : 0;
                return +coc.toFixed(2) >= cocFilter;
            });
        }
        if (cashflowFilter != null) {
            list = list.filter((property) => {
                const cashflow = property.cashflow != null ? property.cashflow : 0;
                return +cashflow.toFixed(2) >= cashflowFilter;
            });
        }
        switch (sortCriteria) {
            case 'coc':
                list.sort((a, b) => {
                    const aval = a.cashOnCash != null ? a.cashOnCash : 0;
                    const bval = b.cashOnCash != null ? b.cashOnCash : 0;
                    return bval - aval;
                });
                break;
            case 'cashflow':
                list.sort((a, b) => {
                    const aval = a.cashflow != null ? a.cashflow : 0;
                    const bval = b.cashflow != null ? b.cashflow : 0;
                    return bval - aval;
                });
                break;
            case 'default':
            default:
                break;
        }
        return list;
    };

    return (
        <InfiniteScroll initialLoad={false} pageStart={0} loadMore={() => loadNext(15)} hasMore={hasNext}>
            <List
                itemLayout="vertical"
                split={false}
                grid={{ gutter: 16, column: 1 }}
                dataSource={getRentalReportList()}
                renderItem={(item) => (
                    <List.Item>
                        <PreviousRentalReportCard
                            from="/rental-reports"
                            reportInfo={item}
                            connectionID={connectionID}
                        />
                    </List.Item>
                )}
                loading={getRentalReportList() == null}
            />
        </InfiniteScroll>
    );
};

const withSuspense = (props) => (
    <Suspense fallback={<Spin size="large" style={{ margin: '48px auto 0', display: 'block' }} />}>
        <RentalReportList {...props} />
    </Suspense>
);

export default withSuspense;
