import React, { useEffect } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/netWorthTracker';
import CurrentNetWorthForm from './currentNetWorth/CurrentNetWorthForm';

interface Props {
    populateNetWorthTracker: any;
    firebaseAuthLoaded: boolean;
}

const NetWorthTracker = (props: Props) => {
    const { firebaseAuthLoaded, populateNetWorthTracker } = props;

    useEffect(() => {
        if (firebaseAuthLoaded) {
            populateNetWorthTracker();
        }
    }, [firebaseAuthLoaded, populateNetWorthTracker]);

    return (
        <div>
            <header style={{ height: '10vh', textAlign: 'center' }}>
                <h1 style={{ paddingTop: '4vh' }}> Monthly Net Worth Tracker </h1>
            </header>
            <Card bordered={false} className="split-card">
                <div className="budget-content">
                    <CurrentNetWorthForm />
                </div>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    currentReportId: state.budget.currentReportId,
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
});

interface Dispatch {
    populateNetWorthTracker: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        populateNetWorthTracker: () => {
            dispatch(actions.populateNetWorthCard());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetWorthTracker);
