import axios from 'axios';
import { serverURL } from 'consts/serverURL';

export async function fetchMaps(idToken: string, ids?: string[]) {
    const fetchMapsUrl = serverURL + '/fetch-real-esate-maps';
    return axios(fetchMapsUrl, {
        method: 'post',
        data: { ids, idToken },
    });
}

export async function fetchCensusData(idToken: string) {
    const fetchCensusDataUrl = serverURL + '/fetch-census-data';
    return axios(fetchCensusDataUrl, {
        method: 'post',
        data: { idToken },
    });
}
