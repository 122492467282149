import React from 'react';
import ReactImageGallery from 'react-image-gallery';

const ImageGallery = (props) => {
    return (
        <ReactImageGallery
            showPlayButton={false}
            renderLeftNav={(onClick, disabled) => {
                return (
                    <button
                        type="button"
                        className="image-gallery-icon image-gallery-left-nav" aria-label="Previous Slide"
                        onClick={onClick}
                        disabled={disabled}
                        style={{ paddingLeft: '0' }}
                    >
                        <svg
                            className="image-gallery-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="6 0 12 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ height: '48px', width: '24px' }}
                        >
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                    </button>
                );
            }}
            renderRightNav={(onClick, disabled) => {
                return (
                    <button
                        type="button"
                        className="image-gallery-icon image-gallery-right-nav" aria-label="Next Slide"
                        onClick={onClick}
                        disabled={disabled}
                        style={{ paddingRight: '0', marginRight: '-6px' }}
                    >
                        <svg
                            className="image-gallery-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="6 0 12 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ height: '48px' }}
                        >
                            <polyline points="9 18 15 12 9 6" />
                        </svg>
                    </button>
                );
            }}
            renderFullscreenButton={(onClick, isFullscreen) => {
                return (
                    <button
                        type="button"
                        className={`image-gallery-icon image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`}
                        onClick={onClick}
                        aria-label="Open Fullscreen"
                        style={{ paddingRight: '12px' }}
                    >
                        <svg
                            className="image-gallery-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ height: '24px', width: '24px' }}
                        >
                            <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                        </svg>
                    </button>
                );
            }}
            {...props}
        />
    );
};

export default ImageGallery;