import React, { useState } from 'react';
import { Button, Popover, Radio, message } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import { commitLocalUpdate, commitMutation } from 'react-relay';
import RelayEnvironment from '../RelayEnvironment';
import { WatchlistButtonMutation } from './__generated__/WatchlistButtonMutation.graphql';
import environment from '../RelayEnvironment';

interface Props extends RouteComponentProps {
    watchlistStatus?: string | null;
    reportId?: string;
    graphqlId?: string;
}

const watchlistMutation = graphql`
    mutation WatchlistButtonMutation($id: MongoID!, $record: UpdateByIdRentalReportInput!) {
        RentalReportUpdateById(_id: $id, record: $record) {
            record {
                id
                watchlistStatus
            }
        }
    }
`;

const WatchlistButton = (props: Props) => {
    const [watchlistPopoverVisible, setWatchlistPopoverVisible]= useState(false);

    const updateCurrentWatchlistStatus = (reportId, value) => {
        if (reportId != null && value != null && props.graphqlId != null) {
            commitMutation<WatchlistButtonMutation>(RelayEnvironment, {
                mutation: watchlistMutation,
                variables: {
                    id: reportId,
                    record: { watchlistStatus: value },
                },
                optimisticResponse: {
                    RentalReportUpdateById: {
                        record: {
                            id: props.graphqlId,
                            watchlistStatus: value,
                        },
                    }
                },
                onCompleted: () => {
                    setWatchlistPopoverVisible(false);
                    commitLocalUpdate(environment, store => {
                        const watchlistRecord = store.get('client:root:RentalWatchlist');
                        if (watchlistRecord != null) {
                            watchlistRecord.invalidateRecord();
                        }
                    });
                },
                onError: e => { message.error('Save default strategy failed' + e.message); }
            });
        }
    };

    return (
        <Popover
            content={
                <div>
                    <Radio.Group
                        options={[
                            { label: 'Never mind', value: 'unset' },
                            { label: 'Watchlist', value: 'watchlist' },
                            { label: 'Backup', value: 'backup' },
                            { label: 'Made offer', value: 'madeOffer' },
                            { label: 'Portfolio', value: 'portfolio' },
                            { label: 'Made offer but failed', value: 'madeOfferButFailed' },
                            { label: 'Pending', value: 'pending' },
                            { label: 'Off market', value: 'offMarket' },
                        ]}
                        onChange={e => updateCurrentWatchlistStatus(props.reportId, e.target.value)}
                        value={props.watchlistStatus == null ? 'unset' : props.watchlistStatus}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>
            }
            trigger="click"
            visible={watchlistPopoverVisible}
            onVisibleChange={(visible) => setWatchlistPopoverVisible(visible)}
            placement="bottomLeft"
        >
            <Button className="report-button" disabled={props.reportId == null}>
                Add to watchlist
            </Button>
        </Popover>
    );
};

export default withRouter(WatchlistButton);
