import React from 'react';
import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { ButtonType } from 'antd/lib/button';

interface Props extends StoreState {
    link?: string;
    style?: any;
    children?: any;
    onClick?: any;
    type?: ButtonType;
    className?: string;
    danger?: boolean;
    ghost?: boolean;
    block?: boolean;
    size?: 'small' | 'large';
}

const SignInOnlyButton = (props: Props) => {
    const loggedIn = props.firebaseAuth.isLoaded && !props.firebaseAuth.isEmpty;
    const buttonType = props.type || 'default';

    if (props.link) {
        if (loggedIn) {
            return (
                <Link to={props.link} style={props.style}>
                    <Button
                        type={buttonType}
                        danger={props.danger}
                        ghost={props.ghost}
                        block={props.block}
                        size={props.size}
                        className={props.className}
                    >
                        {props.children}
                    </Button>
                </Link>
            );
        } else {
            return (
                <Tooltip title="Please sign in first">
                    <Button
                        type={buttonType}
                        danger={props.danger}
                        ghost={props.ghost}
                        block={props.block}
                        className={props.className}
                        size={props.size}
                        style={props.style}
                        disabled
                    >
                        {props.children}
                    </Button>
                </Tooltip>
            );
        }
    } else {
        if (loggedIn) {
            return (
                <Button
                    type={buttonType}
                    danger={props.danger}
                    ghost={props.ghost}
                    block={props.block}
                    className={props.className}
                    size={props.size}
                    onClick={props.onClick}
                    style={props.style}
                >
                    {props.children}
                </Button>
            );
        } else {
            return (
                <Tooltip title="Please sign in first">
                    <Button
                        type={buttonType}
                        className={props.className}
                        danger={props.danger}
                        ghost={props.ghost}
                        block={props.block}
                        size={props.size}
                        disabled
                        style={props.style}
                    >
                        {props.children}
                    </Button>
                </Tooltip>
            );
        }
    }
};

interface StoreState {
    firebaseAuth: any;
}

const mapStateToProps = (state: AppState): StoreState => ({
    firebaseAuth: state.firebase.auth,
});

export default connect(mapStateToProps)(SignInOnlyButton);
