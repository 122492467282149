import React from 'react';
import { LiabilitiesInfoInterface } from '../types';
import _ from 'lodash';

export interface LiabilitiesStoreState {
    liabilitiesInfo: LiabilitiesInfoInterface;
    totalNetWorth?: number;
}

export interface LiabilitiesDispatch {
    setLiabilitiesInfo: (liabilitiesInfo: LiabilitiesInfoInterface) => void;
}

interface Props extends LiabilitiesStoreState, LiabilitiesDispatch {}

export function liabilitiesFormWrapper(ChildForm) {
    return (props: Props) => {
        const { liabilitiesInfo, setLiabilitiesInfo } = props;
        const liabilities = liabilitiesInfo.liabilities;

        const addLiability = () => {
            const newLiabilities = [...liabilities, {}];
            setLiabilitiesInfo({ ...liabilitiesInfo, liabilities: newLiabilities });
        };

        const removeLiability = (key) => {
            const newLiabilities = [...liabilities.slice(0, key), ...liabilities.slice(key + 1)];
            setLiabilitiesInfo({ ...liabilitiesInfo, liabilities: newLiabilities });
        };

        return <ChildForm {...props} addLiability={addLiability} removeLiability={removeLiability} liabilitiesLength={liabilities.length} />;
    };
}

export const transformLiabilitiesToFormFields = (liabilitiesInfo?: LiabilitiesInfoInterface) => {
    if (!liabilitiesInfo) return {};
    const transformed = {};
    const liabilities = liabilitiesInfo.liabilities;
    for (let i = 0; i < liabilities.length; i++) {
        transformed[`liability_keys_${i}`] = liabilities[i].key;
        transformed[`liability_values_${i}`] = liabilities[i].value;
    }
    return transformed;
};

export const transformFormFieldsToLiabilitiesInfo = (liabilitiesInfo: LiabilitiesInfoInterface, changedValues): LiabilitiesInfoInterface => {
    const { liabilities } = liabilitiesInfo;
    const transformedLiabilities =[...liabilities];

    _.forEach(changedValues, (fieldValue, fieldKey) => {
        if (fieldKey.startsWith('liability_keys_')) {
            const i = parseInt(fieldKey.split('liability_keys_')[1]);
            transformedLiabilities[i].key = fieldValue;
        } else if (fieldKey.startsWith('liability_values_')) {
            const i = parseInt(fieldKey.split('liability_values_')[1]);
            transformedLiabilities[i].value = Number(fieldValue);
        }
    });

    return { ...liabilitiesInfo, liabilities: transformedLiabilities };
};
