import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { BudgetReportInterface } from '../types';
import PreviousBudgetReportCard from './PreviousBudgetReportCard';
import { List, Empty, Spin } from 'antd';
import { fetchBudgetReports } from 'redux/actions/budget';

interface Props extends StoreState, Dispatch {}

const BudgetPreviousReports = (props: Props) => {
    const { firebaseAuthEmpty, firebaseAuthLoaded, fetchBudgetReports } = props;

    useEffect(() => {
        if (firebaseAuthLoaded && !firebaseAuthEmpty) {
            fetchBudgetReports();
        }
    }, [fetchBudgetReports, firebaseAuthEmpty, firebaseAuthLoaded]);

    const getList = () => {
        if (!props.firebaseAuthLoaded) {
            return <Spin size="large" style={{ margin: '0 auto', display: 'block' }} />;
        } else if (props.firebaseAuthEmpty) {
            return <Empty description="You need to log in first" />;
        } else {
            return (
                <List
                    itemLayout="vertical"
                    split={false}
                    grid={{ gutter: 16, column: 1 }}
                    dataSource={props.previousReports}
                    renderItem={(item: BudgetReportInterface) => (
                        <List.Item>
                            <PreviousBudgetReportCard reportInfo={item} />
                        </List.Item>
                    )}
                    loading={props.loading}
                />
            );
        }
    };

    return (
        <div className="previous-reports">
            <header style={{ textAlign: 'center', marginBottom: '40px' }}>
                <h1> Previous Budget Reports </h1>
            </header>
            {getList()}
        </div>
    );
};

interface Dispatch {
    fetchBudgetReports: any;
}

interface StoreState {
    firebaseAuthLoaded: boolean;
    firebaseAuthEmpty: boolean;
    previousReports: BudgetReportInterface[];
    loading: boolean;
}

const mapStateToProps = (state: AppState): StoreState => ({
    previousReports: state.budget.previousReports,
    loading: state.budget.previousReportsLoading,
    firebaseAuthEmpty: state.firebase.auth.isEmpty,
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
});

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        fetchBudgetReports: () => {
            dispatch(fetchBudgetReports());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetPreviousReports);
