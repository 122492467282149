import React, { useContext, useState } from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { withRouter, RouteComponentProps } from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, commitMutation, commitLocalUpdate } from 'react-relay';
import {
    RentalCardControllerQuery,
    RentalCardControllerQueryResponse,
} from './__generated__/RentalCardControllerQuery.graphql';
import {
    RentalCardControllerSaveMutation,
    SaveRentalReportInput,
} from './__generated__/RentalCardControllerSaveMutation.graphql';
import RelayEnvironment, { initializeRentalReport } from '../../RelayEnvironment';
import { DefaultStrategyContext, DefaultStrategyContextType } from 'App';
import { checkNumber } from 'redux/selectors';
import { Suspense } from 'react';
import {
    unwrapClosingCostBreakdown,
    unwrapPropertyInfo,
    unwrapPurchaseInfo,
    unwrapRentalInfo,
    unwrapRepairCostBreakdown,
} from 'helpers';
import { RentalCard } from './RentalCard';

const RENTAL_REPORT_ID = 'client:RentalReport';

interface RouteMatchParams {
    id?: string;
}

interface RouteLocationState {
    from?: string;
}

interface Props extends RouteComponentProps<RouteMatchParams, any, RouteLocationState>, StoreState {
    sharedCard?: boolean;
}

const rentalReportMutation = graphql`
    mutation RentalCardControllerSaveMutation($id: MongoID, $record: SaveRentalReportInput!) {
        RentalReportSave(_id: $id, record: $record) {
            recordId
            record {
                id
                propertyUrl
                isShortTermRental
                isBRRRR
                watchlistStatus
                fetchWebsiteLoading
                propertyInfo {
                    reportTitle
                    address
                    city
                    state
                    commnity
                    county
                    zipcode
                    propertyType
                    annualTax
                    mlsNumber
                    photos
                    description
                    bedrooms
                    bathrooms
                    totalSqft
                    lotSize
                    yearBuilt
                    yearRenovated
                    units
                    stories
                    countyAppraisedValue
                    pool
                    heating
                    cooling
                    fireplace
                    garage
                    construction
                    roofing
                    flooringTypes
                    wiringCondition
                    plumbingCondition
                    sidingMaterial
                    otherInformation
                    location {
                        lat
                        lng
                    }
                    ...PropertyInfoForm
                }
                purchaseInfo {
                    purchasePrice
                    afterRepairValue
                    closingCost
                    repairCost
                    isCashPurchase
                    downPayment
                    loanInterestRate
                    pointsChargedByLender
                    otherChargesFromLender
                    wrapLoanFeesIntoLoan
                    isInterestOnly
                    amortizedYears
                    typicalCapRate
                    listingPrice
                    ...PurchaseInfoForm
                }
                rentalInfo {
                    monthlyRent
                    dailyRate
                    occupancyRate
                    otherMonthlyIncome
                    electricity
                    waterAndSewer
                    pmi
                    garbage
                    hoa
                    cleaning
                    supplies
                    internet
                    monthlyInsurance
                    otherMonthlyExpenses
                    vacancy
                    maintenance
                    capex
                    managementFees
                    annualIncomeGrowth
                    annualPropertyValueGrowth
                    annualExpensesGrowth
                    salesExpenses
                    ...RentalInfoForm
                }
                closingCostBreakdown {
                    points
                    prepaidHazardInsurance
                    prepaidFloodInsurance
                    prepaidPropertyTaxes
                    annualAssesements
                    titleAndEscrowFees
                    attorneyCharges
                    inspectionCosts
                    recordingFees
                    appraisalFees
                    otherFees
                    totalClosingCost
                    ...ClosingCostModal
                }
                repairCostBreakdown {
                    roof
                    concrete
                    guttersSoffitFascia
                    garage
                    siding
                    landscaping
                    exteriorPainting
                    septic
                    decks
                    foundation
                    demo
                    sheerstock
                    plumbing
                    carpentryWindowsDoors
                    electrical
                    interiorPainting
                    hvac
                    cabinetsCounterTops
                    framing
                    flooring
                    permits
                    termites
                    mold
                    miscellaneous
                    totalRepairCost
                    ...RepairCostModal
                    ...RehabInfoForm
                }
                refinanceInfo {
                    afterRepairValue
                    closingCost
                    isCashPurchase
                    downPayment
                    loanInterestRate
                    pointsChargedByLender
                    otherChargesFromLender
                    wrapLoanFeesIntoLoan
                    isInterestOnly
                    amortizedYears
                    ...RefinanceInfoForm
                }
            }
        }
    }
`;

const RentalCardController = (props: Props) => {
    const [reportId, setReportId] = useState(
        props.match.params.id == null || props.match.params.id.length === 0
            ? RENTAL_REPORT_ID
            : props.match.params.id,
    );
    const defaultStrategies = useContext(DefaultStrategyContext);
    const getDefaultStrategy = () => {
        if (defaultStrategies != null && defaultStrategies.length > 0) {
            return defaultStrategies[0];
        } else {
            return {
                closingCost: 3000,
                repairCost: 1000,
                downPayment: 25,
                loanInterestRate: 3.5,
                wrapLoanFeesIntoLoan: false,
                amortizedYears: 30,
                monthlyInsurance: 100,
                vacancy: 6,
                maintenance: 8,
                capex: 8,
                managementFees: 10,
                annualIncomeGrowth: 2,
                annualPropertyValueGrowth: 2,
                annualExpensesGrowth: 2,
                salesExpenses: 9,
            } as DefaultStrategyContextType;
        }
    };

    const currentReportQuery = useLazyLoadQuery<RentalCardControllerQuery>(
        graphql`
            query RentalCardControllerQuery($id: MongoID!) {
                RentalReport(_id: $id) {
                    id
                    propertyUrl
                    isShortTermRental
                    isBRRRR
                    watchlistStatus
                    fetchWebsiteLoading
                    propertyInfo {
                        reportTitle
                        address
                        city
                        state
                        commnity
                        county
                        zipcode
                        propertyType
                        annualTax
                        mlsNumber
                        photos
                        description
                        bedrooms
                        bathrooms
                        totalSqft
                        lotSize
                        yearBuilt
                        yearRenovated
                        units
                        stories
                        countyAppraisedValue
                        pool
                        heating
                        cooling
                        fireplace
                        garage
                        construction
                        roofing
                        flooringTypes
                        wiringCondition
                        plumbingCondition
                        sidingMaterial
                        otherInformation
                        location {
                            lat
                            lng
                        }
                        ...PropertyInfoForm
                    }
                    purchaseInfo {
                        purchasePrice
                        afterRepairValue
                        closingCost
                        repairCost
                        isCashPurchase
                        downPayment
                        loanInterestRate
                        pointsChargedByLender
                        otherChargesFromLender
                        wrapLoanFeesIntoLoan
                        isInterestOnly
                        amortizedYears
                        typicalCapRate
                        listingPrice
                        ...PurchaseInfoForm
                    }
                    rentalInfo {
                        monthlyRent
                        dailyRate
                        occupancyRate
                        otherMonthlyIncome
                        electricity
                        waterAndSewer
                        pmi
                        garbage
                        hoa
                        cleaning
                        supplies
                        internet
                        monthlyInsurance
                        otherMonthlyExpenses
                        vacancy
                        maintenance
                        capex
                        managementFees
                        annualIncomeGrowth
                        annualPropertyValueGrowth
                        annualExpensesGrowth
                        salesExpenses
                        ...RentalInfoForm
                    }
                    closingCostBreakdown {
                        points
                        prepaidHazardInsurance
                        prepaidFloodInsurance
                        prepaidPropertyTaxes
                        annualAssesements
                        titleAndEscrowFees
                        attorneyCharges
                        inspectionCosts
                        recordingFees
                        appraisalFees
                        otherFees
                        totalClosingCost
                        ...ClosingCostModal
                    }
                    repairCostBreakdown {
                        roof
                        concrete
                        guttersSoffitFascia
                        garage
                        siding
                        landscaping
                        exteriorPainting
                        septic
                        decks
                        foundation
                        demo
                        sheerstock
                        plumbing
                        carpentryWindowsDoors
                        electrical
                        interiorPainting
                        hvac
                        cabinetsCounterTops
                        framing
                        flooring
                        permits
                        termites
                        mold
                        miscellaneous
                        totalRepairCost
                        ...RepairCostModal
                        ...RehabInfoForm
                    }
                    refinanceInfo {
                        afterRepairValue
                        closingCost
                        isCashPurchase
                        downPayment
                        loanInterestRate
                        pointsChargedByLender
                        otherChargesFromLender
                        wrapLoanFeesIntoLoan
                        isInterestOnly
                        amortizedYears
                        ...RefinanceInfoForm
                    }
                    ...Report
                    ...RentalSider
                }
            }
        `,
        { id: reportId },
        { fetchPolicy: reportId === RENTAL_REPORT_ID ? 'store-only' : 'store-or-network' },
    );

    const currentReport = currentReportQuery.RentalReport;

    const loading = !props.firebaseAuthLoaded || currentReport == null;
    const fetchWebsiteLoading = !!currentReport?.fetchWebsiteLoading;

    const convertCurrentReportToMutationInput = (
        currentReport: NonNullable<RentalCardControllerQueryResponse['RentalReport']>,
    ): SaveRentalReportInput => {
        const propertyInfo = currentReport.propertyInfo == null ? {} : unwrapPropertyInfo(currentReport.propertyInfo);
        const purchaseInfo = currentReport.purchaseInfo == null ? {} : unwrapPurchaseInfo(currentReport.purchaseInfo);
        const rentalInfo = currentReport.rentalInfo == null ? {} : unwrapRentalInfo(currentReport.rentalInfo);
        const closingCostBreakdown =
            currentReport.closingCostBreakdown == null
                ? {}
                : unwrapClosingCostBreakdown(currentReport.closingCostBreakdown);
        const repairCostBreakdown =
            currentReport.repairCostBreakdown == null
                ? {}
                : unwrapRepairCostBreakdown(currentReport.repairCostBreakdown);

        return {
            createTime: Math.round(new Date().getTime() / 1000),
            propertyInfo,
            purchaseInfo,
            rentalInfo,
            closingCostBreakdown,
            repairCostBreakdown,
            propertyUrl: currentReport.propertyUrl,
            isShortTermRental: currentReport.isShortTermRental,
            isBRRRR: currentReport.isBRRRR,
            watchlistStatus: currentReport.watchlistStatus,
        };
    };

    const saveReport = () => {
        if (currentReport != null) {
            return commitMutation<RentalCardControllerSaveMutation>(RelayEnvironment, {
                mutation: rentalReportMutation,
                variables: {
                    record: convertCurrentReportToMutationInput(currentReport),
                    id: reportId === RENTAL_REPORT_ID ? undefined : reportId,
                },
                onCompleted: (response) => {
                    message.success('Save report succeeded');
                    if (response.RentalReportSave != null) {
                        if (reportId === RENTAL_REPORT_ID) {
                            commitLocalUpdate(RelayEnvironment, (store) => {
                                store.delete(reportId + ':propertyInfo');
                                store.delete(reportId + ':purchaseInfo');
                                store.delete(reportId + ':rentalInfo');
                                store.delete(reportId + ':closingCostBreakdown');
                                store.delete(reportId + ':repairCostBreakdown');
                                store.delete(reportId);
                            });
                            commitLocalUpdate(RelayEnvironment, (store) => {
                                initializeRentalReport(store, reportId);
                            });
                        }
                    }
                    const newID = response.RentalReportSave?.recordId as string | null;
                    if (newID != null && newID.length > 0 && reportId !== newID) {
                        props.history.replace({
                            pathname: '/rental/' + newID,
                        });
                        setReportId(newID as string);
                    }
                },
                onError: (e) => {
                    message.error('Save report failed' + e.message);
                },
            });
        }
    };

    const resetReport = () => {
        if (currentReport != null) {
            commitLocalUpdate(RelayEnvironment, (store) => {
                store.delete(reportId + ':propertyInfo');
                store.delete(reportId + ':purchaseInfo');
                store.delete(reportId + ':rentalInfo');
                store.delete(reportId + ':closingCostBreakdown');
                store.delete(reportId + ':repairCostBreakdown');
                store.delete(reportId);
            });
            commitLocalUpdate(RelayEnvironment, (store) => {
                initializeRentalReport(store, reportId);
            });
        }
    };

    const applyDefault = () => {
        if (currentReport != null) {
            commitLocalUpdate(RelayEnvironment, (store) => {
                const record = store.get(reportId);
                if (record != null) {
                    const defaultStrategy = getDefaultStrategy();
                    const purchaseInfo = record.getOrCreateLinkedRecord('purchaseInfo', 'RentalReportPurchaseInfo');
                    const purchasePrice = purchaseInfo?.getValue('purchasePrice')
                        ? purchaseInfo?.getValue('purchasePrice')
                        : purchaseInfo?.getValue('listingPrice');
                    purchaseInfo.setValue(purchasePrice, 'purchasePrice');
                    purchaseInfo.setValue(purchasePrice + checkNumber(defaultStrategy.repairCost), 'afterRepairValue');
                    const purchaseInfoFields = [
                        'closingCost',
                        'repairCost',
                        'downPayment',
                        'loanInterestRate',
                        'wrapLoanFeesIntoLoan',
                        'amortizedYears',
                    ];
                    purchaseInfoFields.forEach((field) => {
                        purchaseInfo.setValue(defaultStrategy[field], field);
                    });

                    const rentalInfo = record.getOrCreateLinkedRecord('rentalInfo', 'RentalReportRentalInfo');
                    const rentalInfoFields = [
                        'electricity',
                        'waterAndSewer',
                        'pmi',
                        'garbage',
                        'hoa',
                        'monthlyInsurance',
                        'otherMonthlyExpenses',
                        'vacancy',
                        'maintenance',
                        'capex',
                        'maintenance',
                        'annualIncomeGrowth',
                        'annualPropertyValueGrowth',
                        'annualExpensesGrowth',
                        'salesExpenses',
                    ];
                    rentalInfoFields.forEach((field) => {
                        rentalInfo.setValue(defaultStrategy[field], field);
                    });
                }
            });
        }
    };

    const onChangeRentalType = () => {
        const newIsShortTermRental = currentReport == null ? true : !currentReport.isShortTermRental;
        const rentalInfo = currentReport?.rentalInfo == null ? {} : unwrapRentalInfo(currentReport.rentalInfo);
        const newRentalInfo = newIsShortTermRental
            ? {
                ...rentalInfo,
                monthlyRent: 0,
                vacancy: 0,
            }
            : {
                ...rentalInfo,
                monthlyRent: 0,
                dailyRate: 0,
                occupancyRate: 0,
                internet: 0,
                cleaning: 0,
                supplies: 0,
            };
        if (reportId !== RENTAL_REPORT_ID) {
            return commitMutation<RentalCardControllerSaveMutation>(RelayEnvironment, {
                mutation: rentalReportMutation,
                variables: {
                    record: { isShortTermRental: newIsShortTermRental, rentalInfo: newRentalInfo },
                    id: reportId === RENTAL_REPORT_ID ? undefined : reportId,
                },
                onError: (e) => {
                    message.error('Change rental type failed' + e.message);
                },
            });
        } else {
            return commitLocalUpdate(RelayEnvironment, (store) => {
                const rentalReport = store.get(RENTAL_REPORT_ID);
                rentalReport?.setValue(newIsShortTermRental, 'isShortTermRental');
                const rentalInfo = rentalReport?.getLinkedRecord('rentalInfo');
                for (const [key, value] of Object.entries(newRentalInfo)) {
                    rentalInfo?.setValue(value, key);
                }
            });
        }
    };

    const onChangeBRRRR = () => {
        const newIsBRRRR = !currentReport?.isBRRRR;
        if (reportId !== RENTAL_REPORT_ID) {
            return commitMutation<RentalCardControllerSaveMutation>(RelayEnvironment, {
                mutation: rentalReportMutation,
                variables: {
                    record: { isBRRRR: newIsBRRRR },
                    id: reportId === RENTAL_REPORT_ID ? undefined : reportId,
                },
                onError: (e) => {
                    message.error('Change BRRRR failed' + e.message);
                },
            });
        } else {
            return commitLocalUpdate(RelayEnvironment, (store) => {
                store.get(RENTAL_REPORT_ID)?.setValue(newIsBRRRR, 'isBRRRR');
            });
        }
    };

    return (
        <RentalCard
            loading={loading}
            isSharedCard={!!props.sharedCard}
            reportId={reportId}
            currentReport={currentReport}
            fetchWebsiteLoading={fetchWebsiteLoading}
            resetReport={resetReport}
            saveReport={saveReport}
            applyDefault={applyDefault}
            location={props.location}
            onChangeRentalType={onChangeRentalType}
            onChangeBRRRR={onChangeBRRRR}
        />
    );
};

interface StoreState {
    firebaseAuthLoaded: boolean;
}

const mapStateToProps = (state: AppState): StoreState => ({
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
});

const withSuspense = (props) => (
    <Suspense
        fallback={
            <RentalCard
                loading
                isSharedCard={!!props.sharedCard}
                location={props.location}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChangeRentalType={() => {}}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChangeBRRRR={() => {}}
            />
        }
    >
        <RentalCardController {...props} />
    </Suspense>
);

export default connect(mapStateToProps)(withRouter(withSuspense));
