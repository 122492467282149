import React, { useState, useEffect } from 'react';
import { MapInterface, NeighborhoodStatsInterface } from '../types';

/* eslint-disable no-unused-vars */
declare global {
    interface Window {
        google: any;
    }
}

declare global {
    interface HTMLDivElement {
        fitBounds: any;
        panToBounds: any;
    }
}
/* eslint-ensable no-unused-vars */

// const MAP_MARKER =
//     'M25 0c-8.284 0-15 6.656-15 14.866 0 8.211 15 35.135 15 35.135s15-26.924 15-35.135c0-8.21-6.716-14.866-15-14.866zm-.049 19.312c-2.557 0-4.629-2.055-4.629-4.588 0-2.535 2.072-4.589 4.629-4.589 2.559 0 4.631 2.054 4.631 4.589 0 2.533-2.072 4.588-4.631 4.588z';
// const RED_COLOR_RANGE = [
//     '#fe8080',
//     '#ff5b5b',
//     '#ff3737',
//     '#ff1213',
//     '#f00000',
//     '#cc0000',
//     '#a70000',
//     '#850000',
//     '#610000',
//     '#3c0000',
// ];
// const selectColor = (value, min, max) => {
//     const step = (max - min + 1) / 10;
//     const index = Math.floor((value - min) / step);
//     return RED_COLOR_RANGE[index];
// };

// const stringNotEmpty = (string?: string) => {
//     return string != null && string.length > 0;
// };

interface Props {
    currentMapData?: MapInterface;
    censusData: NeighborhoodStatsInterface[];
}

export default (props: Props) => {
    const googleMapRef = React.createRef<HTMLDivElement>();
    const [map, setMap] = useState(null);

    const currentZipcodeMap = {};

    useEffect(() => {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_FIREBASE_APIKEY}&libraries=visualization`;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', () => {
            setMap(createMap());
            showNeighborhoodMarkers(map, props.censusData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const getRedfinListingInfoWindow = (listing: RedfinListingInterface) => {
    //     return `
    //         <div class="infowindow">
    //             ${listing.listingPrice != null ? `<h2>Price: $${listing.listingPrice}</h2>` : ''}
    //             <p>
    //                 ${listing.bedrooms != null ? `${listing.bedrooms} Beds   ` : ''}
    //                 ${listing.bathrooms != null ? `${listing.bathrooms} Baths   ` : ''}
    //                 ${listing.totalSqft != null ? `${listing.totalSqft} Sq. Ft` : ''}
    //             </p>
    //             <p>
    //                 ${stringNotEmpty(listing.address) ? `${listing.address}, ` : ''}
    //                 ${stringNotEmpty(listing.city) ? `${listing.city}, ` : ''}
    //                 ${stringNotEmpty(listing.state) ? `${listing.state} ` : ''}
    //                 ${stringNotEmpty(listing.zipcode) ? listing.zipcode : ''}
    //             </p>
    //             ${stringNotEmpty(listing.url) ? `<p><a href="${listing.url}">${listing.url}</a></p>` : ''}
    //             <hr>
    //             ${stringNotEmpty(listing.propertyType) ? `<p>Property Type: ${listing.propertyType}</p>` : ''}
    //             ${listing.lotSize != null ? `<p>Lot Size: ${listing.lotSize} sqft</p>` : ''}
    //             ${listing.yearBuilt != null ? `<p>Built in ${listing.yearBuilt}</p>` : ''}
    //             ${listing.daysOnMarket != null ? `<p>Days on market: ${listing.daysOnMarket}</p>` : ''}
    //             ${stringNotEmpty(listing.location) ? `<p>Location: ${listing.location}</p>` : ''}
    //             ${listing.pricePerSqft != null ? `<p>$${listing.pricePerSqft}/sqft</p>` : ''}
    //             ${listing.hoa != null ? `<p>HOA: $${listing.hoa}/month</p>` : ''}
    //             ${stringNotEmpty(listing.status) ? `<p>Status: ${listing.status}</p>` : ''}
    //             ${stringNotEmpty(listing.saleType) ? `<p>Sale Type: ${listing.saleType}</p>` : ''}
    //             ${stringNotEmpty(listing.soldDate) ? `<p>Sold Date: ${listing.soldDate}</p>` : ''}
    //             ${stringNotEmpty(listing.mlsNumber) ? `<p>MLS# ${listing.mlsNumber}</p>` : ''}
    //             ${stringNotEmpty(listing.source) ? `<p>Source: ${listing.source}</p>` : ''}
    //         </div>
    //     `;
    // };

    const getNeighborhoodInfoWindow = (neighborhood: NeighborhoodStatsInterface) => {
        const getPriceToRent = () => {
            if (neighborhood.priceToRentRatio_2018_09 != null) {
                return `<p>Price-to-rent ratio: ${neighborhood.priceToRentRatio_2018_09}</p>`;
            } else {
                return '';
            }
        };

        return `
            <div class="infowindow">
                <h2>${neighborhood.city} ${neighborhood.state}, ${neighborhood.zipcode}</h2>
                <p>
                    Population: ${neighborhood.population16}
                </p>
                <p>
                    Population Growth: ${(neighborhood.populationGrowth * 100).toPrecision(2)}%
                </p>
                <p>
                    Income: $${neighborhood.income16}
                </p>
                <p>
                    Income Growth: ${(neighborhood.incomeGrowth * 100).toPrecision(2)}%
                </p>
                ${getPriceToRent()}
            </div>
        `;
    };

    const showNeighborhoodMarkers = (map: any, censusData: NeighborhoodStatsInterface[]) => {
        const google = window.google;
        const infowindow = new google.maps.InfoWindow();
        censusData.forEach((neighborhood) => {
            if (!currentZipcodeMap[neighborhood.zipcode]) {
                return;
            }
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(neighborhood.lat, neighborhood.lng),
                map,
                title: '' + neighborhood.population16,
                // icon: {
                //     path: MAP_MARKER,
                //     scale: 0.8,
                //     fillColor: selectColor(listing.listingPrice, minPrice, maxPrice),
                //     fillOpacity: 1,
                // },
            });

            marker.addListener('click', () => {
                infowindow.setContent(getNeighborhoodInfoWindow(neighborhood));
                infowindow.open(map, marker);
            });
        });
    };

    const createMap = () => {
        const google = window.google;
        if (google == null || google.maps == null) return;
        const bounds = new google.maps.LatLngBounds();

        const listings = props.currentMapData?.listings;

        if (listings != null && listings.length > 0) {
            let minPrice = listings[0].listingPrice;
            let maxPrice = listings[0].listingPrice;
            const heatMapData: { location: any; weight?: number }[] = [];
            listings.forEach((listing) => {
                const location = new google.maps.LatLng(listing.lat, listing.lng);
                bounds.extend(location);

                currentZipcodeMap[listing.zipcode] = true;

                heatMapData.push({ location, weight: listing.listingPrice });

                minPrice = Math.min(minPrice, listing.listingPrice);
                maxPrice = Math.max(maxPrice, listing.listingPrice);
            });
            const map = new window.google.maps.Map(googleMapRef.current, {
                center: bounds.getCenter(),
                zoom: 12,
            });
            map.fitBounds(bounds);
            map.panToBounds(bounds);
            const heatmap = new google.maps.visualization.HeatmapLayer({
                data: heatMapData,
            });
            heatmap.setMap(map);

            // const infowindow = new google.maps.InfoWindow();
            // listings.forEach(listing => {
            //     const marker = new google.maps.Marker({
            //         position: new google.maps.LatLng(listing.lat, listing.lng),
            //         map,
            //         title: '' + listing.listingPrice,
            //         icon: {
            //             path: MAP_MARKER,
            //             scale: 0.8,
            //             fillColor: selectColor(listing.listingPrice, minPrice, maxPrice),
            //             fillOpacity: 1,
            //         },
            //     });

            //     marker.addListener('click', () => {
            //         infowindow.setContent(getRedfinListingInfoWindow(listing));
            //         infowindow.open(map, marker);
            //     });
            // });

            return map;
        }
    };

    return (
        <div id="google-map" ref={googleMapRef} style={{ display: 'inline-block', width: '80%', height: '700px' }} />
    );
};
