import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button } from 'antd';
import { getAnnualExpensesInfo, getTotalExpenses } from 'redux/selectors';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/budget';
import { mapPropsToFields, transformFormFieldsToExpenses, transformExpensesToFormFields } from 'helpers';
import { connect } from 'react-redux';
import { expensesFormWrapper, ExpensesStoreState, ExpensesDispatch } from './ExpensesFormWrapper';
import FormInputNumber from 'components/FormInputNumber';
import { budgetDisabledStyle } from 'consts/budget';
import { ExpensesInfoInterface } from '../../types';
import { getExpenseFormItems, getOnClickAdd } from './ExpenseFormItems';

interface AnnualExpensesFormProps extends WrappedAnnualExpensesFormProps, StoreState, ExpensesDispatch {}

const AnnualExpenses = (props: AnnualExpensesFormProps) => {
    const [form] = Form.useForm();

    const getFields = () => {
        const fields = mapPropsToFields(transformExpensesToFormFields(props.expensesInfo.expenses));
        fields.push({ name: ['totalAnnualExpenses'], value: props.expensesInfo.totalExpenses });
        fields.push({ name: ['totalExpenses'], value: props.totalExpenses });
        return fields;
    };

    return (
        <Form
            layout="vertical"
            fields={getFields()}
            onValuesChange={(changedValues) => {
                props.setExpenses(transformFormFieldsToExpenses(props.expensesInfo.expenses, changedValues));
            }}
            scrollToFirstError
            form={form}
        >
            <Row>
                <h2 style={{ float: 'left' }}>Annual Expenses</h2>
            </Row>

            {getExpenseFormItems(props)}

            <Row style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Col
                    lg={{
                        span: 12,
                        offset: 3,
                    }}
                    sm={24}
                >
                    <Button type="dashed" onClick={getOnClickAdd(form, props.add)}>
                        <PlusOutlined /> Add Expense Item
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label="Total Annual Expenses" name="totalAnnualExpenses">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                    <Form.Item label="Total Expenses" name="totalExpenses">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

interface WrappedAnnualExpensesFormProps {
    expenses: any;
    remove: any;
    add: any;
    expensesLength: number;
    handleFormChange: any;
}

interface StoreState extends ExpensesStoreState {
    expensesInfo: ExpensesInfoInterface;
    totalExpenses: number;
}

const mapStateToProps = (state: AppState): StoreState => ({
    expensesInfo: getAnnualExpensesInfo(state),
    totalExpenses: getTotalExpenses(state),
});

const mapDispatchToProps = (dispatch): ExpensesDispatch => {
    return {
        setExpenses: (annnualExpenses) => {
            dispatch(actions.setAnnualExpenses(annnualExpenses));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(expensesFormWrapper(AnnualExpenses));
