import React from 'react';
import { render } from 'react-dom';
import { IRentalWatchListItem } from '.';

const markers = {};

const updateMarkers = (allProperties: IRentalWatchListItem[], map, selectedMarkerId: string | undefined, setSelectedMarkerId: any) => {
    class MapMarker extends google.maps.OverlayView {
        position: google.maps.LatLng;
        containerDiv: HTMLDivElement;
        updateSelected: any;
        reportId: string | undefined;
        watchlistStatus: string | undefined;
        innerPushpinClass: string;

        constructor(position: google.maps.LatLng, price: string, watchlistStatus: string | undefined, selected: boolean, updateSelected: any, reportId: string | undefined) {
            super();
            this.position = position;
            this.reportId = reportId;
            this.updateSelected = updateSelected;
            this.watchlistStatus = watchlistStatus;

            // This zero-height div is positioned at the bottom of the tip.
            this.containerDiv = document.createElement('div');
            this.containerDiv.classList.add('Pushpin');
            this.containerDiv.classList.add('homePushpin');
            this.containerDiv.classList.add('clickableHome');
            this.innerPushpinClass = 'inner-pushpin-content ' + watchlistStatus;
            if (selected) {
                this.innerPushpinClass += ' selected';
            }

            const pushpin = (
                <div className="PushpinContent" onClick={() => {
                    this.updateSelected(this.reportId);
                }}>
                    <div className="white-border" />
                    <div className={this.innerPushpinClass}>
                        <div className="price">{price}</div>
                    </div>
                </div>
            );
            render(pushpin, this.containerDiv);
            // Optionally stop clicks, etc., from bubbling up to the map.
            MapMarker.preventMapHitsAndGesturesFrom(this.containerDiv);
        }

        /** Called when the popup is added to the map. */
        onAdd() {
            this.getPanes()!.floatPane.appendChild(this.containerDiv);
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }
        }

        /** Called each frame when the popup needs to draw itself. */
        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)!;

            // Hide the popup when it is far out of view.
            const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                ? 'block'
                : 'none';

            if (display === 'block') {
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.top = divPosition.y + 'px';
            }

            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display;
            }
        }
    }

    const allIds = new Set();

    allProperties.forEach(listing => {
        if (listing?.propertyInfo?.location != null) {
            const price = listing.purchaseInfo?.purchasePrice || listing.purchaseInfo?.listingPrice;
            let priceString = '???';
            if (price != null) {
                if (price >= 1000000) {
                    priceString = (price / 1000000.0).toFixed(1) + 'M';
                } else if (price >= 1000) {
                    priceString = Math.round(price / 1000.0) + 'K';
                } else {
                    priceString = price + '';
                }
            }
            const reportId = listing?._id as string | undefined;
            if (reportId != null) {
                allIds.add(reportId);
                if (markers[reportId] != null) {
                    markers[reportId].onRemove();
                }

                const selected = selectedMarkerId === reportId;
                const marker = new MapMarker(
                    new google.maps.LatLng(listing?.propertyInfo?.location?.lat ?? 0, listing.propertyInfo.location.lng),
                    priceString,
                    listing?.watchlistStatus ?? undefined,
                    selected,
                    (reportId) => {
                        setSelectedMarkerId(reportId);
                    },
                    reportId,
                );
                marker.setMap(map);
                markers[reportId] = marker;
            }
        }
    });

    for (const [reportId, marker] of Object.entries(markers)) {
        if (!allIds.has(reportId)) {
            (marker as any).onRemove();
            delete markers[reportId];
        }
    }
};

export default updateMarkers;