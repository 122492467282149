import React from 'react';
import { Form, Row, Col } from 'antd';
import FormInputNumber from 'components/FormInputNumber';
import { round, mapPropsToFields } from 'helpers';
import { ResultsInterface } from '../../types';
import { getBudgetResults } from 'redux/selectors';
import { AppState } from 'redux/store';
import { connect } from 'react-redux';
import { budgetDisabledStyle } from 'consts/budget';

export const Results = (props) => {
    return (
        <Form
            layout="vertical"
            fields={mapPropsToFields(props.results)}
            onValuesChange={(values) => {
                props.setIncomeInfo(values);
            }}
        >
            <Row>
                <h2 style={{ float: 'left' }}>What's Left</h2>
            </Row>
            <Row>
                <Col lg={12} sm={24}>
                    <Form.Item label="Total Net Savings (excluding retirement accounts)" name="totalNetSavings">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={24}>
                    <Form.Item label="Per Month">
                        <FormInputNumber
                            dollar
                            disabled
                            style={budgetDisabledStyle}
                            value={round(props.results.totalNetSavings / 12)}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={24}>
                    <Form.Item label="Net Savings Rate" name="totalNetSavingsRate">
                        <FormInputNumber percentage disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={12} sm={24}>
                    <Form.Item label="Total Retirement Accounts Savings" name="totalRetirementAccountsSavings">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label="Total Savings" name="totalSavings">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label="Per Month">
                        <FormInputNumber
                            dollar
                            disabled
                            style={budgetDisabledStyle}
                            value={round(props.results.totalSavings / 12)}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label="Savings Rate" name="totalSavingsRate">
                        <FormInputNumber percentage disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

interface StoreState {
    results: ResultsInterface;
}

const mapStateToProps = (state: AppState): StoreState => ({
    results: getBudgetResults(state),
});

export default connect(mapStateToProps)(Results);
