import React from 'react';
import { Tag } from 'antd';

interface Props {
    status?: string | null;
    style?: any;
}

export const getRedfinStatusColor = (status: string | null) => {
    switch (status) {
        case 'Back on Market':
        case 'Price Drop':
        case 'Open House':
        case 'Coming Soon':
        case 'New Listing':
        case 'New Hot Home':
        case 'New Tour Insight':
            return 'green';
        case 'Pending':
        case 'Contingent':
            return 'red';
        case 'Sold':
        case 'Off Market':
            return 'blue';
        default:
            return 'gray';
    }
};

const RedfinStatusTag = (props: Props) => {
    const status = props.status;

    if (status == null) {
        return null;
    } else {
        return <Tag style={props.style} color={getRedfinStatusColor(status)}>{status}</Tag>;
    }
};

export default RedfinStatusTag;
