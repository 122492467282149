import React, { useEffect, useState } from 'react';
import updateMarkers from 'rental/RentalWatchlist/MapMarker';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { IRentalWatchListItem } from '.';

interface Props {
    rentalWatchlist: IRentalWatchListItem[];
    selectedReportId: string;
    setSelectedReportId: any;
}

const loadingMap = <div id="google-map" style={{ display: 'inline-block', height: '100%', width: '100%' }} />;

const WatchlistMap = (props: Props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY ?? '',
    });
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({ lat: 37.7749, lng: -122.4194 });

    const rentalWatchlist = props.rentalWatchlist;

    const onLoad = React.useCallback((map) => {
        setMap(map);
        updateMapCenter(map, rentalWatchlist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const updateMapCenter = (map: any, lists: IRentalWatchListItem[]) => {
        const google = window.google;
        if (google == null || google.maps == null) return;
        const bounds = new google.maps.LatLngBounds();

        if (lists != null && (lists as any).length > 0) {
            lists.forEach((listing: any) => {
                const location = listing.propertyInfo?.location;
                if (location != null && location.lat != null && location.lng) {
                    const latLng = new google.maps.LatLng(
                        location.lat,
                        location.lng
                    );

                    bounds.extend(latLng);
                }
            });
            setCenter(bounds.getCenter());
            map.panToBounds(bounds);
        }

        return map;
    };

    useEffect(() => {
        if (map != null) {
            updateMapCenter(map, rentalWatchlist);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalWatchlist]);

    useEffect(() => {
        if (map != null) {
            updateMarkers(
                rentalWatchlist,
                map,
                props.selectedReportId,
                props.setSelectedReportId
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalWatchlist, props.selectedReportId]);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        isLoaded ?
            <GoogleMap
                id="google-map"
                mapContainerStyle={{ display: 'inline-block', height: '100%', width: '100%' }}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
            />
            : loadingMap
    );
};


export default WatchlistMap;
