/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RentalSider = {
    readonly propertyUrl: string | null;
    readonly watchlistStatus: string | null;
    readonly isShortTermRental: boolean | null;
    readonly isBRRRR: boolean | null;
    readonly propertyInfo: {
        readonly reportTitle: string | null;
        readonly address: string | null;
        readonly city: string | null;
        readonly state: string | null;
        readonly zipcode: string | null;
        readonly bedrooms: number | null;
        readonly bathrooms: number | null;
        readonly totalSqft: number | null;
        readonly annualTax: number | null;
    } | null;
    readonly " $refType": "RentalSider";
};
export type RentalSider$data = RentalSider;
export type RentalSider$key = {
    readonly " $data"?: RentalSider$data;
    readonly " $fragmentRefs": FragmentRefs<"RentalSider">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RentalSider",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watchlistStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isShortTermRental",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBRRRR",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportPropertyInfo",
      "kind": "LinkedField",
      "name": "propertyInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reportTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bedrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bathrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSqft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualTax",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RentalReport",
  "abstractKey": null
};
(node as any).hash = 'adee81acf53a55eaadf549be3232c95d';
export default node;
