import React from 'react';
import { Modal, Form } from 'antd';
import { REPAIR_COST_BREAKDOWN_LABELS } from 'consts/rental';
import { createFormFromArray, mapPropsToFields } from 'helpers';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { RepairCostModal$key } from './__generated__/RepairCostModal.graphql.js';
import RelayEnvironment from 'RelayEnvironment';

const {
    ROOF,
    CONCRETE,
    GUTTERS_SOFFIT_FASCIA,
    GARAGE,
    SIDING,
    LANDSCAPING,
    EXTERIOR_PAINTING,
    SEPTIC,
    DECKS,
    FOUNDATION,
    DEMO,
    SHEETROCK,
    PLUMBING,
    CARPENTRY_WINDOW_DOORS,
    ELECTRICAL,
    INTERIOR_PAINTING,
    HVAC,
    CABINETS_COUNTERTOPS,
    FRAMING,
    FLOORING,
    PERMITS,
    TERMITES,
    MOLD,
    MISCELLANEOUS,
} = REPAIR_COST_BREAKDOWN_LABELS;

interface Props {
    toggleModal: any;
    visible: boolean;
    repairCost?: RepairCostModal$key | null;
    reportId?: string | null;
}

const RepairCostModal = (props: Props) => {
    const repairCostBreakdown = useFragment(
        graphql`
            fragment RepairCostModal on RentalReportRepairCostBreakdown {
                roof
                concrete
                guttersSoffitFascia
                garage
                siding
                landscaping
                exteriorPainting
                septic
                decks
                foundation
                demo
                sheerstock
                plumbing
                carpentryWindowsDoors
                electrical
                interiorPainting
                hvac
                cabinetsCounterTops
                framing
                flooring
                permits
                termites
                mold
                miscellaneous
                totalRepairCost
            }
        `,
        props.repairCost ?? null);
    const [form] = Form.useForm();
    const handleRepairCostModalOk = () => {
        form.validateFields().then((values) => {
            props.toggleModal(false);
            commitLocalUpdate(RelayEnvironment, store => {
                const id = props.reportId as string;
                if (id != null) {
                    const record = store.get(id);
                    if (record != null) {
                        const totalRepairCost = Object.keys(values).reduce((sum, key) => {
                            return sum + (values[key] ?? 0);
                        }, 0);
                        values.totalRepairCost = totalRepairCost;
                        const purchaseInfoRecord = record.getLinkedRecord('purchaseInfo');
                        if (purchaseInfoRecord != null) {
                            purchaseInfoRecord.setValue(totalRepairCost, 'repairCost');
                        }

                        const repairCostBreakdownRecord = record.getLinkedRecord('repairCostBreakdown');
                        if (repairCostBreakdownRecord != null) {
                            Object.keys(values).forEach(key => {
                                repairCostBreakdownRecord?.setValue(values[key], key);
                            });
                        }
                    }
                }
            });
        });
    };

    const handleRepairCostModalCancel = () => {
        props.toggleModal(false);
    };

    const exterior = [
        ROOF,
        CONCRETE,
        GUTTERS_SOFFIT_FASCIA,
        GARAGE,
        SIDING,
        LANDSCAPING,
        EXTERIOR_PAINTING,
        SEPTIC,
        DECKS,
        FOUNDATION,
    ];
    const interior = [
        DEMO,
        SHEETROCK,
        PLUMBING,
        CARPENTRY_WINDOW_DOORS,
        ELECTRICAL,
        INTERIOR_PAINTING,
        HVAC,
        CABINETS_COUNTERTOPS,
        FRAMING,
        FLOORING,
    ];
    const general = [PERMITS, TERMITES, MOLD, MISCELLANEOUS];

    const exteriorPart = createFormFromArray(exterior, 4);
    const interiorPart = createFormFromArray(interior, 4);
    const generalPart = createFormFromArray(general, 4);

    return (
        <Modal
            title="Repair Cost Breakdown"
            visible={props.visible}
            onOk={handleRepairCostModalOk}
            onCancel={handleRepairCostModalCancel}
            width={800}
        >
            <Form
                fields={mapPropsToFields(repairCostBreakdown)}
                form={form}
            >
                <h4> Exterior Repairs </h4>
                {exteriorPart}
                <h4> Interior Repairs </h4>
                {interiorPart}
                <h4> General Components </h4>
                {generalPart}
            </Form>
        </Modal>
    );
};

export default RepairCostModal;
