import React from 'react';
import { AssetsInfoInterface } from '../types';
import _ from 'lodash';

export interface AssetsStoreState {
    assetsInfo: AssetsInfoInterface;
}

export interface AssetsDispatch {
    setAssetsInfo: (assetsInfo: AssetsInfoInterface) => void;
}

interface Props extends AssetsStoreState, AssetsDispatch {}

export function assetsFormWrapper(ChildForm) {
    return (props: Props) => {
        const { assetsInfo, setAssetsInfo } = props;
        const assets = assetsInfo.assets;
        const retirementAccounts = assetsInfo.retirementAccounts;

        const addAsset = () => {
            const newAssets = [...assets, {}];
            setAssetsInfo({ ...assetsInfo, assets: newAssets });
        };

        const addRetirementAccount = () => {
            const newRetirementAccounts = [...retirementAccounts, {}];
            setAssetsInfo({ ...assetsInfo, retirementAccounts: newRetirementAccounts });
        };

        const removeAsset = (key) => {
            const newAssets = [...assets.slice(0, key), ...assets.slice(key + 1)];
            setAssetsInfo({ ...assetsInfo, assets: newAssets });
        };

        const removeRetirementAccount = (key) => {
            const newRetirementAccounts = [...retirementAccounts.slice(0, key), ...retirementAccounts.slice(key + 1)];
            setAssetsInfo({ ...assetsInfo, retirementAccounts: newRetirementAccounts });
        };

        return <ChildForm {...props} addAsset={addAsset} addRetirementAccount={addRetirementAccount} removeAsset={removeAsset} removeRetirementAccount={removeRetirementAccount} assetsLength={assets.length} />;
    };
}

export const transformAssetsToFormFields = (assetsInfo?: AssetsInfoInterface) => {
    if (!assetsInfo) return {};
    const transformed = {};
    const assets = assetsInfo.assets;
    for (let i = 0; i < assets.length; i++) {
        transformed[`asset_keys_${i}`] = assets[i].key;
        transformed[`asset_values_${i}`] = assets[i].value;
    }

    const retirementAccounts = assetsInfo.retirementAccounts;
    for (let i = 0; i < retirementAccounts.length; i++) {
        transformed[`retirement_keys_${i}`] = retirementAccounts[i].key;
        transformed[`retirement_values_${i}`] = retirementAccounts[i].value;
    }

    return transformed;
};

export const transformFormFieldsToAssetsInfo = (assetsInfo: AssetsInfoInterface, changedValues): AssetsInfoInterface => {
    const { assets, retirementAccounts } = assetsInfo;
    const transformedAssets =[...assets];
    const transformedRetirementAccounts = [...retirementAccounts];

    _.forEach(changedValues, (fieldValue, fieldKey) => {
        if (fieldKey.startsWith('asset_keys_')) {
            const i = parseInt(fieldKey.split('asset_keys_')[1]);
            transformedAssets[i].key = fieldValue;
        } else if (fieldKey.startsWith('asset_values_')) {
            const i = parseInt(fieldKey.split('asset_values_')[1]);
            transformedAssets[i].value = Number(fieldValue);
        } else if (fieldKey.startsWith('retirement_keys_')) {
            const i = parseInt(fieldKey.split('retirement_keys_')[1]);
            transformedRetirementAccounts[i].key = fieldValue;
        } else if (fieldKey.startsWith('retirement_values_')) {
            const i = parseInt(fieldKey.split('retirement_values_')[1]);
            transformedRetirementAccounts[i].value = Number(fieldValue);
        }
    });
    return { ...assetsInfo, assets: transformedAssets, retirementAccounts: transformedRetirementAccounts };
};
