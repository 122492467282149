import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Select, Spin, Empty, Row, Col, Input } from 'antd';
import RedfinEmailListingsList from './RedfinEmailListingsList';
import { SortConnectionRedfinEmailListingEnum } from './__generated__/RedfinEmailListingsPaginationQuery.graphql';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { UserContext } from 'App';
import { WindowWidthContext } from 'AppLayout';
import { WindowWidth } from 'helpers';

type Props = StoreState

const RedfinEmailListingsPage = (props: Props) => {
    const [orderBy, setOrderBy] = useState('_ID_DESC' as SortConnectionRedfinEmailListingEnum);
    const [filterByDays, setFilterByDays] = useState(7);
    const [showArchived, setShowArchived] = useState(false);
    const [status, setStatus] = useState([] as string[]);
    const [search, setSearch] = useState(undefined as string | undefined);
    const user = useContext(UserContext);
    const windowWidth = useContext(WindowWidthContext);

    const onRTPChange = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setOrderBy('RENT_TO_PRICE_DATE_DESC');
        } else{
            setOrderBy('_ID_DESC');
        }
    };

    const { firebaseAuthEmpty, firebaseAuthLoaded } = props;

    const getList = () => {
        if (!firebaseAuthLoaded) {
            return <Spin size="large" style={{ margin: '48px auto 0', display: 'block' }} />;
        } else if (firebaseAuthEmpty || !user?.allowRedfinEmail) {
            return <Empty description="Not Accessible" />;
        } else {
            return <RedfinEmailListingsList
                orderBy={orderBy}
                filterByDays={filterByDays}
                showArchived={showArchived}
                status={status}
                search={search}
            />;
        }
    };

    const getHeaderMargin = () => {
        if (windowWidth > WindowWidth.MD) {
            return '0 0 16px 0';
        }
        if (windowWidth === WindowWidth.MD) {
            return '0 20px 10px';
        }
        if (windowWidth <= WindowWidth.SM) {
            return '0 10px 10px';
        }
        return '0';
    };

    return (
        <div className="previous-reports">
            <header
                style={{
                    textAlign: 'center',
                    margin: getHeaderMargin(),
                }}
            >
                <h1> Redfin Listings from Emails </h1>
                <Row style={{ marginBottom: windowWidth === WindowWidth.XS ? '4px' : '12px' }}>
                    <Col
                        lg={8}
                        sm={12}
                        xs={24}
                        style={{
                            marginBottom: windowWidth === WindowWidth.XS ? '4px' : 0,
                        }}
                    >
                        <Input
                            placeholder="Search address, city, zipcode, or filter"
                            allowClear
                            style={{ float: 'left', width: '100%' }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                    <Col md={1} sm={0} />
                    <Col
                        lg={7}
                        sm={4}
                        xs={10}
                        style={{
                            marginBottom: windowWidth === WindowWidth.XS ? '4px' : 0,
                        }}
                    >
                        <Select
                            value={filterByDays}
                            style={{ width: 100, float: 'left' }}
                            onChange={(value) => setFilterByDays(value)}
                        >
                            <Select.Option value={1}>1 day</Select.Option>
                            <Select.Option value={7}>1 week</Select.Option>
                            <Select.Option value={14}>2 weeks</Select.Option>
                            <Select.Option value={30}>1 month</Select.Option>
                            <Select.Option value={-1}>All</Select.Option>
                        </Select>
                    </Col>

                    <Col lg={1} sm={0} xs={4} />

                    <Col
                        lg={7}
                        sm={5}
                        xs={10}
                        style={{
                            paddingTop: '6px',
                        }}
                    >
                        <Checkbox
                            style={{
                                float: 'left',
                            }}
                            checked={showArchived}
                            onChange={(e) => setShowArchived(e.target.value)}
                        >
                            Show Archived
                        </Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={8}
                        sm={6}
                        xs={12}
                        style={{
                            marginBottom: windowWidth === WindowWidth.XS ? '4px' : 0,
                            paddingTop: windowWidth === WindowWidth.XS ? 0 : '5px',
                        }}
                    >
                        <Checkbox
                            checked={orderBy === 'RENT_TO_PRICE_DATE_DESC'}
                            onChange={onRTPChange}
                            style={{ float: 'left' }}
                        >
                            Sort by Rent-to-price
                        </Checkbox>
                    </Col>

                    <Col md={1} sm={0} />

                    <Col lg={7} sm={9} xs={24}>
                        <Select
                            mode="multiple"
                            placeholder="Status"
                            value={status}
                            style={{ width: '100%', marginRight: '12px', textAlign: 'left', float: 'left' }}
                            onChange={(value) => setStatus(value)}
                        >
                            <Select.Option value="Back on Market">Back on Market</Select.Option>
                            <Select.Option value="Price Drop">Price Drop</Select.Option>
                            <Select.Option value="Open House">Open House</Select.Option>
                            <Select.Option value="Coming Soon">Coming Soon</Select.Option>
                            <Select.Option value="New Listing">New Listing</Select.Option>
                            <Select.Option value="New Hot Home">New Hot Home</Select.Option>
                            <Select.Option value="New Tour Insight">New Tour Insight</Select.Option>
                            <Select.Option value="Pending">Pending</Select.Option>
                            <Select.Option value="Contingent">Contingent</Select.Option>
                            <Select.Option value="Sold">Sold</Select.Option>
                            <Select.Option value="Off Market">Off Market</Select.Option>
                        </Select>
                    </Col>
                </Row>
            </header>
            {getList()}
        </div>
    );
};

interface StoreState {
    firebaseAuthLoaded: boolean;
    firebaseAuthEmpty: boolean;
}

const mapStateToProps = (state: AppState): StoreState => ({
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
    firebaseAuthEmpty: !!state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(RedfinEmailListingsPage);
