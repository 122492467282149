/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RedfinEmailListingItemSetArchivedMutationVariables = {
    id: unknown;
    archived?: boolean | null;
};
export type RedfinEmailListingItemSetArchivedMutationResponse = {
    readonly RedfinEmailListingUpdateById: {
        readonly recordId: unknown | null;
        readonly record: {
            readonly id: string;
            readonly archived: boolean | null;
        } | null;
    } | null;
};
export type RedfinEmailListingItemSetArchivedMutation = {
    readonly response: RedfinEmailListingItemSetArchivedMutationResponse;
    readonly variables: RedfinEmailListingItemSetArchivedMutationVariables;
};



/*
mutation RedfinEmailListingItemSetArchivedMutation(
  $id: MongoID!
  $archived: Boolean
) {
  RedfinEmailListingUpdateById(input: {_id: $id, record: {archived: $archived}}) {
    recordId
    record {
      id
      archived
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_id",
            "variableName": "id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "archived",
                "variableName": "archived"
              }
            ],
            "kind": "ObjectValue",
            "name": "record"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateByIdRedfinEmailListingPayload",
    "kind": "LinkedField",
    "name": "RedfinEmailListingUpdateById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RedfinEmailListing",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RedfinEmailListingItemSetArchivedMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RedfinEmailListingItemSetArchivedMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "88dd5f8a8162fdadb96d5056642b2010",
    "id": null,
    "metadata": {},
    "name": "RedfinEmailListingItemSetArchivedMutation",
    "operationKind": "mutation",
    "text": "mutation RedfinEmailListingItemSetArchivedMutation(\n  $id: MongoID!\n  $archived: Boolean\n) {\n  RedfinEmailListingUpdateById(input: {_id: $id, record: {archived: $archived}}) {\n    recordId\n    record {\n      id\n      archived\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '50448f393cac45f027e0b26a9012a00c';
export default node;
