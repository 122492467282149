import React from 'react';
import Income from './Income';
import Taxes from './Taxes';
import RetirementAccounts from './RetirementAccounts';
import MonthlyExpenses from './MonthlyExpenses';
import AnnualExpenses from './AnnualExpenses';
import Results from './Results';
import BudgetButtons from './BudgetButtons';
import BudgetTitleForm from './BudgetTitleForm';
import AnnualDeductibleExpenses from './AnnualDeductibleExpenses';

const BudgetForm = () => {
    return (
        <div className="stepForm">
            <BudgetTitleForm />

            <Income />

            <RetirementAccounts />

            <AnnualDeductibleExpenses />

            <Taxes />

            <MonthlyExpenses />

            <AnnualExpenses />

            <Results />

            <BudgetButtons />
        </div>
    );
};

export default BudgetForm;
