import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Modal } from 'antd';
import SignInOnlyButton from 'components/SignInOnlyButton';
import DefaultStrategyForm from 'rental/DefaultStrategyForm';
import { WindowWidthContext } from 'AppLayout';
import { WindowWidth } from 'helpers';

const HomePage = () => {
    const windowWidth = useContext(WindowWidthContext);
    const [strategyModalVisible, setStrategyModalVisible] = useState(false);

    const openStrategyModal = () => {
        setStrategyModalVisible(true);
    };

    const closeStrategyModal = () => {
        setStrategyModalVisible(false);
    };

    if (windowWidth === WindowWidth.XS) {
        return (
            <div id="homepage-mobile">
                <h1 style={{ marginTop: '16px', textAlign: 'center', marginBottom: 2 }}>Quanti-FI Tools</h1>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>Quantify your Financial Independence</p>

                <div style={{ marginTop: '16px' }}>
                    <Card title="Rental Property Calculator" bodyStyle={{ padding: '16px' }} headStyle={{ padding: '0 16px' }}>
                        <p>
                            Analyze your investment properties.
                        </p>
                        <div style={{ marginBottom: '6px' }}>
                            <SignInOnlyButton style={{ marginRight: '16px' }} onClick={openStrategyModal}>
                                Set Default Strategy
                            </SignInOnlyButton>
                            <SignInOnlyButton link="/rental-watchlist" style={{ marginRight: '16px' }}>
                                Watchlist
                            </SignInOnlyButton>
                        </div>
                        <div>
                            <SignInOnlyButton link="/rental-reports" style={{ marginRight: '16px' }}>
                                Previous Reports
                            </SignInOnlyButton>
                            <Link to="/rental">
                                <Button type="primary">New Rental</Button>
                            </Link>
                        </div>
                    </Card>
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Card bodyStyle={{ padding: '16px' }} headStyle={{ padding: '0 16px' }} title="Annual Budget">
                        <p>
                            Build your annual budget.
                        </p>
                        <div style={{ textAlign: 'start' }}>
                            <SignInOnlyButton link="/budget-reports" style={{ marginRight: '16px' }}>
                                Previous Budgets
                            </SignInOnlyButton>
                            <Link to="/budget">
                                <Button type="primary">New Budget</Button>
                            </Link>
                        </div>
                    </Card>
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Card bodyStyle={{ padding: '16px' }} headStyle={{ padding: '0 16px' }} title="Mortgage">
                        <p>Calculate and compare different mortgages</p>
                        <div style={{ textAlign: 'start' }}>
                            <SignInOnlyButton link="/mortgage-reports" style={{ marginRight: '16px' }}>
                                Previous Reports
                            </SignInOnlyButton>
                            <Link to="/mortgage">
                                <Button type="primary">New Report</Button>
                            </Link>
                        </div>
                    </Card>
                </div>

                {/* <div style={{ marginTop: '16px' }}>
                    <Card bodyStyle={{ padding: '16px' }} headStyle={{ padding: '0 16px' }} title="Monthly Net Worth Tracker (WIP)">
                        <p>
                            Track your net worth monthly.
                        </p>
                        <div style={{ textAlign: 'start' }}>
                            <SignInOnlyButton link="/net-worth-dashboard" style={{ marginRight: '16px' }}>
                                Dashboard
                            </SignInOnlyButton>
                            <SignInOnlyButton link="/net-worth-tracker" style={{ marginRight: '16px' }} type="primary">
                                Start Tracking
                            </SignInOnlyButton>
                        </div>
                    </Card>
                </div> */}
                <Modal
                    title="Default Strategy"
                    visible={strategyModalVisible}
                    width={800}
                    style={{ top: 20 }}
                    footer={null}
                    onCancel={closeStrategyModal}
                >
                    <DefaultStrategyForm onSave={closeStrategyModal} />
                </Modal>
            </div>
        );
    }

    return (
        <div id="homepage">
            <Row style={{ marginTop: '16px', textAlign: 'center' }} gutter={16}>
                <Col lg={{ span: 12, offset: 6 }} sm={24}>
                    <h1>Quanti-FI Tools</h1>
                    <h3>Quantify your Financial Independence</h3>
                    <p style={{ textAlign: 'justify' }}>
                        "A goal without a plan is just a wish." The following tools are created to help you plan your
                        financial freedom by either real estate investing or annual budgeting, in the hope to reduce
                        friction during research/planning stage to minimize risk and maximize your return.
                    </p>
                </Col>
            </Row>
            <Row style={{ marginTop: '16px' }} gutter={16}>
                <Col md={12} sm={24}>
                    <Card title="Rental Property Calculator">
                        <p>
                            Quickly analyze your investment properties potentials using this comprehensive rental
                            calculator.
                        </p>
                        <div style={{ textAlign: 'end', marginBottom: '8px' }}>
                            <SignInOnlyButton style={{ marginRight: '16px' }} onClick={openStrategyModal}>
                                Set Default Strategy
                            </SignInOnlyButton>
                            <SignInOnlyButton link="/rental-watchlist" style={{ marginRight: '16px' }}>
                                Watchlist
                            </SignInOnlyButton>
                            <SignInOnlyButton link="/rental-reports">
                                View Previous Reports
                            </SignInOnlyButton>
                        </div>
                        <div style={{ textAlign: 'end' }}>
                            <Link to="/rental">
                                <Button type="primary">New Report</Button>
                            </Link>
                        </div>
                    </Card>
                </Col>

                <Col md={12} sm={24} className="homepage-col">
                    <Card title="Annual Budget">
                        <p>
                            Build your annual budget. Check out how much you can spend, save and how much tax you need
                            to pay.
                        </p>
                        <div style={{ textAlign: 'end' }}>
                            <SignInOnlyButton link="/budget-reports" style={{ marginRight: '16px' }}>
                                View Previous Budgets
                            </SignInOnlyButton>
                            <Link to="/budget">
                                <Button type="primary">New Budget</Button>
                            </Link>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row style={{ marginTop: '16px' }} gutter={16}>
                <Col md={12} sm={24}>
                    <Card title="Mortgage">
                        <p>Calculate and compare different mortgages to find the most suitable option for you.</p>
                        <div style={{ textAlign: 'end' }}>
                            <SignInOnlyButton link="/mortgage-reports" style={{ marginRight: '16px' }}>
                                View Previous Reports
                            </SignInOnlyButton>
                            <Link to="/mortgage">
                                <Button type="primary">New Report</Button>
                            </Link>
                        </div>
                    </Card>
                </Col>
                {/* <Col md={12} sm={24} className="homepage-col">
                    <Card title="Monthly Net Worth Tracker (WIP)">
                        <p>
                            Track your net worth monthly.
                        </p>
                        <div style={{ textAlign: 'end' }}>
                            <SignInOnlyButton link="/net-worth-dashboard" style={{ marginRight: '16px' }}>
                                Dashboard
                            </SignInOnlyButton>
                            <SignInOnlyButton link="/net-worth-tracker" style={{ marginRight: '16px' }} type="primary">
                                Start Tracking
                            </SignInOnlyButton>
                        </div>
                    </Card>
                </Col> */}
            </Row>
            {/* <Row style={{ marginTop: '16px' }} gutter={16}>
                <Col md={12} sm={24}>
                    <Card title="Real Estate Map (WIP)">
                        <p>
                            Visualize the interested region with listing data and census data to analyze profitability.
                        </p>
                        <div style={{ textAlign: 'end' }}>
                            <SignInOnlyButton link="/real-estate-map" style={{ marginRight: '16px' }}>
                                View Previous Maps
                            </SignInOnlyButton>
                            <Link to="/real-estate-map">
                                <Button type="primary">New Map</Button>
                            </Link>
                        </div>
                    </Card>
                </Col>
            </Row> */}

            <Modal
                title="Default Strategy"
                visible={strategyModalVisible}
                width={800}
                style={{ top: 20 }}
                footer={null}
                onCancel={closeStrategyModal}
            >
                <DefaultStrategyForm onSave={closeStrategyModal} />
            </Modal>
        </div>
    );
};

export default HomePage;
