import { serverURL } from 'consts/serverURL';
import firebase from 'firebase/app';
import 'firebase/auth';

async function fetchGraphQL(text, variables) {
    try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        const body = {
            query: text,
            variables,
            idToken,
        };
        const response = await fetch(serverURL + '/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

export default fetchGraphQL;