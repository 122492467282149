import React from 'react';
import { Form, Row, Col } from 'antd';
import FormInputNumber from 'components/FormInputNumber';
import { round, mapPropsToFields } from 'helpers';
import { IncomeInfoInterface } from '../../types';
import { getIncomeInfo } from 'redux/selectors';
import { AppState } from 'redux/store';
import { connect } from 'react-redux';
import { setIncomeInfo } from 'redux/actions/budget';

const Income = (props) => {
    const [form] = Form.useForm();

    return (
        <Form
            layout="vertical"
            form={form}
            fields={mapPropsToFields(props.incomeInfo)}
            onValuesChange={(values) => {
                props.setIncomeInfo(values);
            }}
        >
            <Row>
                <h2 style={{ float: 'left' }}>Income</h2>
            </Row>

            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label="Gross Salary" name="salary">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={8} xs={24}>
                    <Form.Item label="Bonus (%)" name="bonusPercentage">
                        <FormInputNumber
                            percentage
                            onChange={(value) => {
                                const values = {
                                    bonus: round(form.getFieldValue('salary') * value) / 100,
                                };
                                props.setIncomeInfo(values);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={8} xs={24}>
                    <Form.Item label="Bonus ($)" name="bonus">
                        <FormInputNumber
                            dollar
                            onChange={(value) => {
                                const salary = form.getFieldValue('salary');
                                const values = { bonusPercentage: salary ? round((value / salary) * 100) : 0 };
                                props.setIncomeInfo(values);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={8} xs={24}>
                    <Form.Item label="Extra Bonus ($)" name="extraBonus">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label="Vested RSU" name="rsu">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

interface StoreState {
    incomeInfo: IncomeInfoInterface;
}

const mapStateToProps = (state: AppState): StoreState => ({
    incomeInfo: getIncomeInfo(state),
});

interface Dispatch {
    setIncomeInfo: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        setIncomeInfo: (incomeInfo: IncomeInfoInterface) => {
            dispatch(setIncomeInfo(incomeInfo));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Income);
