import React, { useEffect } from 'react';
import { Button, Card } from 'antd';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { withRouter, RouteComponentProps } from 'react-router';
import * as actions from 'redux/actions/mortgage';
import MortgageForm from './MortgageCard';
import { MortgageReportInterface } from 'mortgage/types';
import MortgageComparisonCard from './MortgageComparisonCard';

interface RouteMatchParams {
    id?: string;
}
interface Props extends RouteComponentProps<RouteMatchParams>, Dispatch {
    currentReportId?: string;
    currentReport: MortgageReportInterface;
    firebaseAuthLoaded: boolean;
}

const MortgageCard = (props: Props) => {
    const { currentReportId, firebaseAuthLoaded, match, populateMortgageCard, currentReport, addNewMortgage } = props;

    useEffect(() => {
        const urlID = match.params.id;
        if (currentReportId !== urlID && firebaseAuthLoaded) {
            populateMortgageCard(urlID);
        }
    }, [currentReportId, firebaseAuthLoaded, match.params.id, populateMortgageCard]);

    const mortgages = currentReport.mortgages;
    const numberOfMortgages = mortgages == null ? 0 : mortgages.length;

    const getListOfMortgages = () => {
        if (mortgages == null) {
            return null;
        }
        return mortgages.map((mortgage, index) =>
            (<MortgageForm style={{ marginTop: index === 0 ? '0' : '8px' }} mortgage={mortgage} index={index} key={index} />));
    };

    return (
        <div>
            <header style={{ height: '10vh', textAlign: 'center' }}>
                <h1 style={{ paddingTop: '4vh' }}> Mortgage </h1>
            </header>
            <Card bordered={false} className="split-card">
                <div className="mortgage-content">
                    {getListOfMortgages()}

                    <Button
                        type="dashed"
                        style={{ width: '200px', marginTop: numberOfMortgages === 0 ? '0' : '12px' }}
                        onClick={addNewMortgage}
                    >
                        Add
                    </Button>
                </div>

                <div className="mortgage-content" style={{ marginTop: '12px' }}>
                    <MortgageComparisonCard />
                </div>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    currentReportId: state.mortgage.currentReportId,
    currentReport: state.mortgage.currentReport,
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
});

interface Dispatch {
    populateMortgageCard: any;
    addNewMortgage: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        populateMortgageCard: (reportId: string) => {
            dispatch(actions.populateMortgageCard(reportId));
        },
        addNewMortgage: () => {
            dispatch(actions.addNewMortgage());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MortgageCard));
