import {
    CurrentRentalReportInterface,
    LoanDetailsInterface,
    MonthlyRepaymentInterface,
    AnnualAnalysis,
    ReportDataInterface,
} from 'rental/types';
import { createSelector } from 'reselect';

export const getIsShortTermRental = (currentReport: { isShortTermRental?: boolean }) => currentReport.isShortTermRental;

export const getIsBRRRR = (currentReport: { isBRRRR?: boolean }) => currentReport.isBRRRR;

export const getPropertyUrl = (currentReport: { propertyUrl?: string | null }) => currentReport.propertyUrl;

export const getPropertyInfo = (currentReport: { propertyInfo?: any | null }) =>
    currentReport.propertyInfo ? currentReport.propertyInfo : {};

export const getPurchaseInfo = (currentReport: { purchaseInfo?: any | null }) =>
    currentReport.purchaseInfo ? currentReport.purchaseInfo : {};

export const getClosingCostBreakdown = (currentReport: CurrentRentalReportInterface) =>
    currentReport.closingCostBreakdown ? currentReport.closingCostBreakdown : {};

export const getRepairCostBreakdown = (currentReport: CurrentRentalReportInterface) =>
    currentReport.repairCostBreakdown ? currentReport.repairCostBreakdown : {};

export const getRentalInfo = (currentReport: { rentalInfo?: any | null }) =>
    currentReport.rentalInfo ? currentReport.rentalInfo : {};

export const checkNumber = (num: any) => (num == null ? 0 : num);

const getReportTitle = (currentReport: { propertyInfo?: { reportTitle?: string | null } | null }) => {
    return currentReport?.propertyInfo?.reportTitle || null;
};

export const getReportSubtitles = (currentReport: { propertyInfo?: any }) => {
    const propertyInfo =
        currentReport.propertyInfo == null
            ? ({} as {
                readonly reportTitle: string | null;
                readonly address: string | null;
                readonly city: string | null;
                readonly state: string | null;
                readonly zipcode: string | null;
                readonly bedrooms: string | null;
                readonly bathrooms: string | null;
                readonly totalSqft: string | null;
                readonly annualTax: number | null;
            })
            : currentReport.propertyInfo;
    const { address, city, state, zipcode, bedrooms, bathrooms, totalSqft } = propertyInfo;
    const firstAddressSubtitle: string | null = address || null;
    let secondAddressSubtitle: string | null = '';
    let bbSubtitle: string | null = '';
    let sqftSubtitle: string | null = '';

    if (city) {
        secondAddressSubtitle += city;
        if (state) {
            secondAddressSubtitle += ', ' + state;
        }
        if (zipcode) {
            secondAddressSubtitle += ' ' + zipcode;
        }
    } else {
        if (propertyInfo.state) {
            secondAddressSubtitle += propertyInfo.state;
            if (propertyInfo.zipcode) {
                secondAddressSubtitle += ' ' + propertyInfo.zipcode;
            }
        } else {
            if (propertyInfo.zipcode) {
                secondAddressSubtitle += propertyInfo.zipcode;
            } else {
                secondAddressSubtitle = null;
            }
        }
    }

    if (bedrooms) {
        bbSubtitle += `${bedrooms} Beds`;
        if (bathrooms) {
            bbSubtitle += ` ${bathrooms} Baths`;
        }
    } else {
        if (bathrooms) {
            bbSubtitle += `${bathrooms} Baths`;
        } else {
            bbSubtitle = null;
        }
    }

    if (totalSqft) {
        sqftSubtitle = `${propertyInfo.totalSqft} sqft`;
    } else {
        sqftSubtitle = null;
    }

    return {
        firstAddressSubtitle,
        secondAddressSubtitle,
        bbSubtitle,
        sqftSubtitle,
    };
};

export const getMarketValue = createSelector(getPurchaseInfo, (purchaseInfo: any) => {
    const arv = checkNumber(purchaseInfo?.afterRepairValue);
    if (arv) return arv;

    const listingPrice = checkNumber(purchaseInfo.listingPrice);
    if (listingPrice) return listingPrice;

    return checkNumber(purchaseInfo.purchasePrice);
});

export const getMonthlyIncome = createSelector(getRentalInfo, ({ monthlyRent, otherMonthlyIncome }) => {
    const rent = checkNumber(monthlyRent);
    const other = checkNumber(otherMonthlyIncome);
    return rent + other;
});

export const getMonthlyOperatingExpenses = createSelector(
    getRentalInfo,
    getPropertyInfo,
    (rentalInfo: any, propertyInfo: any) => {
        const rent = checkNumber(rentalInfo.monthlyRent);
        const vacancy = (checkNumber(rentalInfo.vacancy) / 100) * rent;
        const capex = (checkNumber(rentalInfo.capex) / 100) * rent;
        const maintenance = (checkNumber(rentalInfo.maintenance) / 100) * rent;
        const management = (checkNumber(rentalInfo.managementFees) / 100) * rent;
        const taxes = checkNumber(propertyInfo.annualTax) / 12;
        const insurance = checkNumber(rentalInfo.monthlyInsurance);
        const pmi = checkNumber(rentalInfo.pmi);
        const hoa = checkNumber(rentalInfo.hoa);
        const electricity = checkNumber(rentalInfo.electricity);
        const water = checkNumber(rentalInfo.waterAndSewer);
        const garbage = checkNumber(rentalInfo.garbage);
        const internet = checkNumber(rentalInfo.internet);
        const cleaning = checkNumber(rentalInfo.cleaning);
        const supplies = checkNumber(rentalInfo.supplies);
        const other = checkNumber(rentalInfo.otherMonthlyExpenses);

        return {
            total:
                vacancy +
                capex +
                management +
                taxes +
                maintenance +
                insurance +
                pmi +
                hoa +
                electricity +
                water +
                garbage +
                internet +
                cleaning +
                supplies +
                other,
            vacancy,
            capex,
            management,
            taxes,
            maintenance,
            insurance,
            pmi,
            hoa,
            electricity,
            water,
            garbage,
            internet,
            cleaning,
            supplies,
            other,
        };
    },
);

const getLoanCalculations = createSelector(
    getPurchaseInfo,
    (purchaseInfo: any): LoanDetailsInterface => {
        const purchasePrice = checkNumber(purchaseInfo.purchasePrice);
        let closing = checkNumber(purchaseInfo.closingCost);
        const repair = checkNumber(purchaseInfo.repairCost);

        if (purchaseInfo.isCashPurchase) {
            return { totalCash: purchasePrice + closing + repair };
        }

        let payment = purchasePrice;
        const points = checkNumber(purchaseInfo.pointsChargedByLender) / 100;
        const otherCharges = checkNumber(purchaseInfo.otherChargesFromLender);
        if (purchaseInfo.wrapLoanFeesIntoLoan) {
            payment += points * purchasePrice + otherCharges;
        } else {
            closing += points * purchasePrice + otherCharges;
        }

        const interestRate = checkNumber(purchaseInfo.loanInterestRate) / 100;
        const monthlyInterestRate = interestRate / 12;
        const downPayment = payment * (checkNumber(purchaseInfo.downPayment) / 100);

        const loanAmount = payment - downPayment;
        const numberOfPayments = checkNumber(purchaseInfo.amortizedYears) * 12;
        const discount =
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1) /
            (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments));

        const monthlyMortgage = loanAmount / discount;
        const repaymentSchedule = getRepaymentSchedule(
            loanAmount,
            monthlyInterestRate,
            numberOfPayments,
            monthlyMortgage,
        );

        const totalCash = downPayment + closing + repair;

        return {
            loanAmount,
            downPayment,
            interestRate: interestRate * 100,
            monthlyMortgage,
            totalCash,
            amortizedYears: purchaseInfo.amortizedYears,
            points: points * 100,
            repaymentSchedule,
        };
    },
);

const getRepaymentSchedule = (
    loanAmount: number,
    monthlyRate: number,
    numberOfPayments: number,
    monthlyPayment: number,
): MonthlyRepaymentInterface[] => {
    let balance = loanAmount;
    const schedule: MonthlyRepaymentInterface[] = [];
    for (let month = 1; month <= numberOfPayments; month++) {
        const interest = +(balance * monthlyRate).toFixed(2);
        const principal = +Math.min(monthlyPayment - interest, balance).toFixed(2);
        balance -= principal;
        schedule.push({ month, interest, principal, balance });
    }
    return schedule;
};

const getTotalCashflowByYear = (income, incomeGrowthPercentage, expense, expenseGrowthPercentage, year): number => {
    let totalCashflow = 0;
    let incomeAtYear = income;
    let expenseAtYear = expense;
    for (let i = 0; i < year; i++) {
        totalCashflow += incomeAtYear - expenseAtYear;
        incomeAtYear = income * (1 + checkNumber(incomeGrowthPercentage) / 100);
        expenseAtYear = expense * (1 + checkNumber(expenseGrowthPercentage) / 100);
    }
    return totalCashflow;
};

const getAnalysisOverTime = (
    rentalInfo: {
        annualExpensesGrowth?: number;
        annualIncomeGrowth?: number;
        annualPropertyValueGrowth?: number;
        salesExpenses?: number;
    },
    propertyValue: number,
    monthlyIncome: number,
    monthlyExpense: number,
    totalCash: number,
    loan: LoanDetailsInterface,
): AnnualAnalysis[] => {
    const { annualExpensesGrowth, annualIncomeGrowth, annualPropertyValueGrowth, salesExpenses } = rentalInfo;

    const { monthlyMortgage, repaymentSchedule } = loan;
    const annualMortgage = checkNumber(monthlyMortgage) * 12;
    const years: number[] = [1, 2, 5, 10, 15, 20, 30];
    const baseAnnualIncome = monthlyIncome * 12;
    const baseAnnualExpense = monthlyExpense * 12;
    const analysisOverTime: AnnualAnalysis[] = [];
    years.forEach((year) => {
        const multiplier = (growthRate) => Math.pow(1 + checkNumber(growthRate) / 100, year - 1);
        const propertyValueAtYear = Math.round(propertyValue * multiplier(annualPropertyValueGrowth));
        const incomeAtYear = Math.round(baseAnnualIncome * multiplier(annualIncomeGrowth));
        const expenseAtYear = Math.round(baseAnnualExpense * multiplier(annualExpensesGrowth));
        const mortgage = year <= checkNumber(loan.amortizedYears) ? annualMortgage : 0;
        const totalAnnualCashflow = incomeAtYear - expenseAtYear - mortgage;
        const cocAtYear = totalAnnualCashflow / totalCash;
        let balance = 0;
        if (repaymentSchedule != null && repaymentSchedule.length >= year * 12) {
            balance = repaymentSchedule[year * 12 - 1].balance;
        }
        const equity = propertyValueAtYear - balance;
        const totalCashflow = getTotalCashflowByYear(
            baseAnnualIncome,
            annualIncomeGrowth,
            baseAnnualExpense,
            annualExpensesGrowth,
            year,
        );
        const profitIfSold =
            equity -
            (propertyValueAtYear * checkNumber(salesExpenses)) / 100 -
            checkNumber(loan.totalCash) +
            totalCashflow;
        const roi = profitIfSold / totalCash;
        const cagr = Math.pow((totalCash + profitIfSold) / totalCash, 1 / year) - 1;
        const analysis = {
            year,
            income: incomeAtYear,
            expenses: expenseAtYear,
            mortgage: mortgage,
            propertyValue: propertyValueAtYear,
            coc: cocAtYear,
            annualCashflow: totalAnnualCashflow,
            loanBalance: balance,
            equity,
            profitIfSold,
            roi,
            cagr,
        };
        analysisOverTime.push(analysis);
    });
    return analysisOverTime;
};

export const getCashflow = createSelector(
    [getMonthlyIncome, getMonthlyOperatingExpenses, getLoanCalculations],
    (monthlyIncome, monthlyOperatingExpenses, loan) => {
        return monthlyIncome - monthlyOperatingExpenses.total - checkNumber(loan.monthlyMortgage);
    },
);

export const getCashOnCash = createSelector([getLoanCalculations, getCashflow], (loan, cashflow) => {
    const { totalCash } = loan;
    return totalCash === 0 ? 100 : ((cashflow * 12) / totalCash) * 100;
});

// cashflow + 1st month principal
export const getMonthlyReturn = createSelector([getCashflow, getLoanCalculations], (cashflow, loan) => {
    if (loan.repaymentSchedule != null && loan.repaymentSchedule.length > 0) {
        return cashflow + loan.repaymentSchedule[0].principal;
    }
    return cashflow;
});

// 1st year cashflow + 1st year total principal / total cash
export const getROI = createSelector([getCashflow, getLoanCalculations], (cashflow, loan) => {
    if (loan.totalCash === 0) return Infinity;

    let income = cashflow * 12;

    if (loan.repaymentSchedule != null && loan.repaymentSchedule.length >= 12) {
        income += loan.repaymentSchedule.slice(0, 12).reduce((sum, val) => (sum += val.principal), 0);
    }

    return income / loan.totalCash;
});

// cashflow without considering vacancy, maintenance, and capex
export const getPerfectMonthlyCashflow = createSelector(
    [getCashflow, getMonthlyOperatingExpenses, getLoanCalculations],
    (cashflow, monthlyOperatingExpenses) => {
        return (
            cashflow +
            monthlyOperatingExpenses.vacancy +
            monthlyOperatingExpenses.maintenance +
            monthlyOperatingExpenses.capex
        );
    },
);

export const getPerfectMonthlyReturn = createSelector(
    [getPerfectMonthlyCashflow, getLoanCalculations],
    (perfectCashflow, loan) => {
        if (loan.repaymentSchedule != null && loan.repaymentSchedule.length > 0) {
            return perfectCashflow + loan.repaymentSchedule[0].principal;
        }
        return perfectCashflow;
    },
);

export const getPerfectROI = createSelector([getPerfectMonthlyCashflow, getLoanCalculations], (cashflow, loan) => {
    if (loan.totalCash === 0) return Infinity;

    let income = cashflow * 12;

    if (loan.repaymentSchedule != null && loan.repaymentSchedule.length >= 12) {
        income += loan.repaymentSchedule.slice(0, 12).reduce((sum, val) => (sum += val.principal), 0);
    }

    return income / loan.totalCash;
});

export const getAllReturns = createSelector(
    [
        getCashflow,
        getMonthlyReturn,
        getROI,
        getPerfectMonthlyCashflow,
        getPerfectMonthlyReturn,
        getPerfectROI],
    (
        cashflow,
        monthlyReturn,
        roi,
        perfectMonthlyCashflow,
        perfectMonthlyReturn,
        perfectROI) => {
        return { cashflow, monthlyReturn, roi, perfectMonthlyCashflow, perfectMonthlyReturn, perfectROI };
    }
);

export const getReport = createSelector(
    [
        getPropertyUrl,
        getPropertyInfo,
        getPurchaseInfo,
        getRentalInfo,
        getReportTitle,
        getReportSubtitles,
        getMarketValue,
        getMonthlyIncome,
        getMonthlyOperatingExpenses,
        getLoanCalculations,
        getAllReturns,
    ],
    (
        propertyUrl,
        propertyInfo: any,
        purchaseInfo: any,
        rentalInfo: any,
        title,
        subtitles,
        marketValue,
        monthlyIncome,
        monthlyOperatingExpenses,
        loan,
        allReturns,
    ): ReportDataInterface => {
        const { monthlyMortgage, totalCash } = loan;
        const { cashflow, monthlyReturn, roi, perfectMonthlyCashflow, perfectMonthlyReturn, perfectROI } = allReturns;
        const noi = (monthlyIncome - monthlyOperatingExpenses.total) * 12;
        const coc = totalCash === 0 ? 100 : ((cashflow * 12) / totalCash) * 100;
        const monthlyExpense = monthlyOperatingExpenses.total + monthlyMortgage;
        const capRate = (noi / marketValue) * 100;

        const price = purchaseInfo.purchasePrice == null ? purchaseInfo.listingPrice : purchaseInfo.purchasePrice;
        const analysisOverTime = getAnalysisOverTime(
            rentalInfo,
            checkNumber(price),
            monthlyIncome,
            monthlyOperatingExpenses.total,
            totalCash,
            loan,
        );

        return {
            propertyUrl,
            propertyInfo,
            purchaseInfo,
            rentalInfo,
            title,
            subtitles,
            monthlyIncome,
            monthlyExpense,
            monthlyOperatingExpenses,
            cashflow,
            noi,
            totalCash,
            coc,
            capRate,
            loan,
            analysisOverTime,
            monthlyReturn,
            roi,
            perfectMonthlyCashflow,
            perfectMonthlyReturn,
            perfectROI,
        };
    },
);
