import React from 'react';
import { Tag } from 'antd';

interface Props {
    isShortTermRental?: boolean | null;
    style?: any;
}

const ShortTermRentalTag = (props: Props) => {
    const isShortTermRental = props.isShortTermRental;
    if (isShortTermRental) {
        return (
            <Tag style={{ ...props.style, color: '#ff385c', borderColor: '#ff385c' }} color="white">
                Short-term Rental
            </Tag>
        );
    }
    return null;
};

export default ShortTermRentalTag;
