/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RefinanceInfoForm = {
    readonly afterRepairValue: number | null;
    readonly closingCost: number | null;
    readonly isCashPurchase: boolean | null;
    readonly downPayment: number | null;
    readonly loanInterestRate: number | null;
    readonly pointsChargedByLender: number | null;
    readonly otherChargesFromLender: number | null;
    readonly wrapLoanFeesIntoLoan: boolean | null;
    readonly isInterestOnly: boolean | null;
    readonly amortizedYears: number | null;
    readonly " $refType": "RefinanceInfoForm";
};
export type RefinanceInfoForm$data = RefinanceInfoForm;
export type RefinanceInfoForm$key = {
    readonly " $data"?: RefinanceInfoForm$data;
    readonly " $fragmentRefs": FragmentRefs<"RefinanceInfoForm">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefinanceInfoForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "afterRepairValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closingCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCashPurchase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loanInterestRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsChargedByLender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherChargesFromLender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wrapLoanFeesIntoLoan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInterestOnly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amortizedYears",
      "storageKey": null
    }
  ],
  "type": "RentalReportRefinanceInfo",
  "abstractKey": null
};
(node as any).hash = 'eb37dbcf7c2b6362a88c0ea653152688';
export default node;
