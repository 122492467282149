import React, { useContext } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Row, Col, Button, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import { round, WindowWidth } from 'helpers';
import { getColorForStatus, getStatusForCashflow, getStatusForCOC, addThousandSeparatorForNumber } from 'helpers';
import PropertyTags from './PropertyTags';
import { WindowWidthContext } from 'AppLayout';

interface Props {
    reportId?: string | null;
    address?: string | null;
    linkToPath: string;
    timeAgoString: string | null;
    purchasePrice?: number| null;
    monthlyRent?: number | null;
    cashflow?: number | null;
    coverPhoto?: { original?: string | null; thumbnail?: string } | null;
    cashOnCash?: number| null;
    watchlistStatus?: string | null;
    isShortTermRental?: boolean | null;
    from?: string
    deleteReport: any;
}

const PreviousReportCard = (props: Props) => {
    const windowWidth = useContext(WindowWidthContext);

    const onDeleteReportSuccess = () => {
        const msg = 'Delete report of ' + props.address + ' succeeded!';
        message.success(msg);
    };

    const onDeleteReportFailure = (e) => {
        const msg = 'Delete report of ' + props.address + ' failed: ' + e.message;
        message.error(msg);
    };

    const onConfirmDeleteReport = () => {
        props.deleteReport(onDeleteReportSuccess, onDeleteReportFailure, props.reportId);
    };

    const from = props.from;

    const getSmCard = () => {
        return (
            <Card style={{ width: '100%' }}>
                {props.coverPhoto && (
                    <img
                        src={props.coverPhoto?.thumbnail}
                        style={{ width: '100%', marginBottom: '8px' }}
                        alt="property cover"
                    />
                )}
                <div>
                    <Link to={{
                        pathname: props.linkToPath,
                        state: { from }
                    }}>
                        <h3>{props.address}</h3>
                    </Link>
                </div>

                <PropertyTags watchlistStatus={props.watchlistStatus} isShortTermRental={props.isShortTermRental} />

                <Row>
                    <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                        Purchase Price
                    </Col>
                    <Col span={12} style={{ fontSize: '20px' }}>
                        {'$ ' + addThousandSeparatorForNumber(round(props.purchasePrice))}
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                        Rent
                    </Col>
                    <Col span={12} style={{ fontSize: '20px' }}>
                        {'$ ' + addThousandSeparatorForNumber(round(props.monthlyRent))}
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                        Cashflow
                    </Col>
                    <Col
                        span={12}
                        style={{
                            color: getColorForStatus(getStatusForCashflow(props.cashflow)),
                            fontSize: '20px',
                        }}
                    >
                        {'$ ' + addThousandSeparatorForNumber(round(props.cashflow))}
                    </Col>
                </Row>
                {props.cashOnCash != null && (
                    <Row>
                        <Col span={12} style={{ fontSize: '16px', paddingTop: '4px' }}>
                            COC
                        </Col>
                        <Col
                            span={12}
                            style={{
                                color: getColorForStatus(getStatusForCOC(props.cashOnCash)),
                                fontSize: '20px',
                            }}
                        >
                            {round(props.cashOnCash == null ? 0 : props.cashOnCash) + '%'}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col span={20} style={{ fontSize: '12px', color: 'gray', paddingTop: '10px' }}>
                        {'Updated ' + props.timeAgoString}
                    </Col>
                    <Col offset={1} span={2}>
                        <Popconfirm
                            title="Do you want to delete this report?"
                            onConfirm={onConfirmDeleteReport}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Card>
        );
    };

    const getLgCard = () => {
        return (
            <Card style={{ width: '100%' }}>
                <Row>
                    <Col span={6}>
                        <img
                            src={props.coverPhoto == null ? '/empty.jpg' : props.coverPhoto.thumbnail}
                            style={{
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                marginBottom: '-12px',
                                width: 'calc(100% + 40px)',
                            }}
                            alt="property cover"
                        />
                    </Col>
                    <Col span={16} offset={2}>
                        <Row>
                            <Col span={21}>
                                <div>
                                    <Link
                                        to={{
                                            pathname: props.linkToPath,
                                            state: { from },
                                        }}
                                    >
                                        <h3 style={{ marginBottom: '2px' }}>{props.address}</h3>
                                    </Link>
                                </div>

                                <div>
                                    {'Updated ' + props.timeAgoString}{' '}
                                    <PropertyTags
                                        style={{ marginLeft: '4px' }}
                                        watchlistStatus={props.watchlistStatus}
                                        isShortTermRental={props.isShortTermRental}
                                    />
                                </div>
                            </Col>
                            <Col span={2}>
                                <Popconfirm
                                    title="Do you want to delete this report?"
                                    onConfirm={onConfirmDeleteReport}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col span={7}>
                                <p
                                    style={{
                                        fontSize: '24px',
                                        lineHeight: '30px',
                                        marginBottom: '6px',
                                    }}
                                >
                                    {'$ ' + addThousandSeparatorForNumber(round(props.purchasePrice))}
                                </p>

                                <p>Purchase Price</p>
                            </Col>

                            <Col span={6}>
                                <p
                                    style={{
                                        fontSize: '24px',
                                        lineHeight: '30px',
                                        marginBottom: '6px',
                                    }}
                                >
                                    {'$ ' + addThousandSeparatorForNumber(round(props.monthlyRent))}
                                </p>

                                <p>Rent</p>
                            </Col>

                            <Col span={6}>
                                <p
                                    style={{
                                        color: getColorForStatus(getStatusForCashflow(props.cashflow)),
                                        fontSize: '24px',
                                        lineHeight: '30px',
                                        marginBottom: '6px',
                                    }}
                                >
                                    {'$ ' + addThousandSeparatorForNumber(round(props.cashflow))}
                                </p>

                                <p>Cashflow</p>
                            </Col>
                            {props.cashOnCash != null && (
                                <Col span={5}>
                                    <p
                                        style={{
                                            color: getColorForStatus(getStatusForCOC(props.cashOnCash)),
                                            fontSize: '24px',
                                            lineHeight: '30px',
                                            marginBottom: '6px',
                                        }}
                                    >
                                        {round(props.cashOnCash == null ? 0 : props.cashOnCash) + '%'}
                                    </p>

                                    <p>Cash On Cash</p>
                                </Col>
                            )}
                        </Row>
                    </Col>

                </Row>
            </Card>
        );
    };

    if (windowWidth <= WindowWidth.SM) {
        return getSmCard();
    } else {
        return getLgCard();
    }
};


export default PreviousReportCard;
