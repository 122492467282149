import React, { useEffect, useState } from 'react';
import RentalCard from './rental/RentalCard/RentalCardController';
import RentalWatchlist from './rental/RentalWatchlist';
import HeaderMenu, { MenuRoute } from 'components/HeaderMenu';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import HomePage from './home/HomePage';
import RentalReportPage from './rental/PreviousReports/RentalReportPage';
import BudgetCard from './budget/BudgetCard';
import PreviousBudgetReports from './budget/PreviousReports/PreviousBudgetReports';
import MapCard from './map/MapCard';
import RedfinEmailListingsPage from 'redfinEmailListings/RedfinEmailListingsPage';
import MortgageCard from 'mortgage/MortgageCard/MortgageList';
import PreviousMortgageReports from 'mortgage/PreviousReports/PreviousMortgageReports';
import NetWorthTracker from 'netWorthTracker/NetWorthTracker';
import NetWorthDashboard from 'netWorthTracker/dashboard/NetWorthDashboard';
import { getWindowWidth, getWindowWidthPx, WindowWidth } from 'helpers';

const { Content } = Layout;

export const baseMenuRoutes: MenuRoute[] = [
    {
        title: 'Rental Analysis',
        subcomponents: [
            {
                exact: true,
                title: 'Create New Report',
                path: '/rental',
                component: RentalCard,
            },
            {
                exact: false,
                title: 'View Previous Reports',
                path: '/rental-reports',
                component: RentalReportPage,
            },
            {
                exact: false,
                title: 'Watchlist',
                path: '/rental-watchlist',
                component: RentalWatchlist,
            },
        ],
    },
    // {
    //     title: 'Real Estate Map',
    //     subcomponents: [
    //         {
    //             exact: true,
    //             title: 'Create New Map',
    //             path: '/real-estate-map',
    //             component: MapCard,
    //         },
    //         {
    //             title: 'View Previous Maps',
    //             path: '/real-estate-map-list',
    //             component: PreviousMaps,
    //         },
    //     ],
    // },
    {
        title: 'Annual Budget',
        subcomponents: [
            {
                exact: true,
                title: 'Create New Budget',
                path: '/budget',
                component: BudgetCard,
            },
            {
                title: 'View Previous Budgets',
                path: '/budget-reports',
                component: PreviousBudgetReports,
            },
        ],
    },
    {
        title: 'Mortgage',
        subcomponents: [
            {
                exact: true,
                title: 'Create New Report',
                path: '/mortgage',
                component: MortgageCard,
            },
            {
                title: 'View Previous Reports',
                path: '/mortgage-reports',
                component: PreviousMortgageReports,
            },
        ],
    },
    // {
    //     title: 'Net Worth Tracker',
    //     subcomponents: [
    //         {
    //             exact: true,
    //             title: 'Add new record',
    //             path: '/net-worth-tracker',
    //             component: NetWorthTracker,
    //         },
    //         {
    //             exact: true,
    //             title: 'Dashboard',
    //             path: '/net-worth-dashboard',
    //             component: NetWorthDashboard,
    //         },
    //     ],
    // },
];

interface Props {
    allowRedfinEmail: boolean;
}

export const WindowWidthContext = React.createContext<WindowWidth>(WindowWidth.SM);

export const WindowWidthPxContext = React.createContext<WindowWidth>(0);

const AppLayout = (props: Props) => {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth(window));
    const [windowWidthPx, setWindowWidthPx] = useState(getWindowWidthPx(window));

    const updateWindowDimensions = () => {
        setWindowWidth(getWindowWidth(window));
        setWindowWidthPx(getWindowWidthPx(window));
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);
    const [menuRoutes, setMenuRoutes] = useState(baseMenuRoutes);

    useEffect(() => {
        if (props.allowRedfinEmail) {
            const newMenuRoutes = [
                ...menuRoutes,
                {
                    title: 'Redfin Email Listings',
                    path: '/redfin-email-listings',
                    component: RedfinEmailListingsPage,
                }
            ];
            setMenuRoutes(newMenuRoutes);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allowRedfinEmail]);

    const mobile = windowWidth === WindowWidth.XS;

    return (
        <WindowWidthPxContext.Provider value={windowWidthPx}>
            <WindowWidthContext.Provider value={windowWidth}>
                <Layout>
                    <Route path="/" render={(props) => <HeaderMenu {...props} menuRoutes={menuRoutes} />} />
                    <Content style={{ backgroundColor: '#fbfbfb', marginTop: mobile ? 64 : 'unset' }}>
                        <Switch>
                            <Route path="/" exact component={HomePage} />
                            {menuRoutes.map((route) => {
                                if (route.subcomponents != null) {
                                    return route.subcomponents.map((component) => (
                                        <Route
                                            key={component.path}
                                            path={component.path}
                                            exact={component.exact}
                                            component={component.component}
                                        />
                                    ));
                                } else {
                                    return (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    );
                                }
                            })}
                            <Route path="/rental/shared/:id" render={
                                routeProps =>
                                    <RentalCard sharedCard {...routeProps} />
                            } />
                            <Route path="/rental/:id" component={RentalCard} />
                            <Route path="/real-estate-map/:id" component={MapCard} />
                            <Route path="/budget/:id" component={BudgetCard} />
                            <Route path="/mortgage/:id" component={MortgageCard} />
                            <Route path="/net-worth-tracker" component={NetWorthTracker} />
                            <Route path="/net-worth-dashboard" component={NetWorthDashboard} />
                            <Route path="/redfin-email-listings" component={RedfinEmailListingsPage} />
                        </Switch>
                    </Content>
                </Layout>
            </WindowWidthContext.Provider>
        </WindowWidthPxContext.Provider>
    );
};

export default AppLayout;
