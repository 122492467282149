/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PurchaseInfoForm = {
    readonly purchasePrice: number | null;
    readonly afterRepairValue: number | null;
    readonly closingCost: number | null;
    readonly repairCost: number | null;
    readonly isCashPurchase: boolean | null;
    readonly downPayment: number | null;
    readonly loanInterestRate: number | null;
    readonly pointsChargedByLender: number | null;
    readonly otherChargesFromLender: number | null;
    readonly wrapLoanFeesIntoLoan: boolean | null;
    readonly isInterestOnly: boolean | null;
    readonly amortizedYears: number | null;
    readonly typicalCapRate: number | null;
    readonly listingPrice: number | null;
    readonly " $refType": "PurchaseInfoForm";
};
export type PurchaseInfoForm$data = PurchaseInfoForm;
export type PurchaseInfoForm$key = {
    readonly " $data"?: PurchaseInfoForm$data;
    readonly " $fragmentRefs": FragmentRefs<"PurchaseInfoForm">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseInfoForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchasePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "afterRepairValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closingCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repairCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCashPurchase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loanInterestRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsChargedByLender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherChargesFromLender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wrapLoanFeesIntoLoan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInterestOnly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amortizedYears",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typicalCapRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listingPrice",
      "storageKey": null
    }
  ],
  "type": "RentalReportPurchaseInfo",
  "abstractKey": null
};
(node as any).hash = '5756083ebd23bac605c5738b10c6f14b';
export default node;
