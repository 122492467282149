import React from 'react';
import { Form, Row, Col, Input, Checkbox } from 'antd';
import { getBudgetTitle, getIsNRA, getTaxTreaty } from 'redux/selectors';
import { AppState } from 'redux/store';
import { connect } from 'react-redux';
import { setBudgetTitle, setIsNRA, setTaxTreaty } from 'redux/actions/budget';
import FormInputNumber from 'components/FormInputNumber';
import { FieldData } from 'helpers';

const BudgetTitleForm = (props) => {
    const getFields = () => {
        const fields: FieldData[] = [];
        fields.push({ name: ['reportTitle'], value: props.reportTitle });
        fields.push({ name: ['isNRA'], value: props.isNRA });
        fields.push({ name: ['taxTreaty'], value: props.taxTreaty });
        return fields;
    };
    return (
        <Form
            layout="vertical"
            fields={getFields()}
            onValuesChange={(values) => {
                if (values.reportTitle) {
                    props.setBudgetTitle(values.reportTitle);
                }

                if (values.isNRA !== undefined) {
                    props.setIsNRA(values.isNRA);
                }

                if (values.taxTreaty) {
                    props.setTaxTreaty(values.taxTreaty);
                }
            }}
        >
            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label="Title" name="reportTitle" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <h2 style={{ float: 'left' }}>Foreign Status</h2>
            </Row>

            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item style={{ float: 'left', marginBottom: '12px' }} name="isNRA" valuePropName="checked">
                        <Checkbox>I am a Non-Resident Alien</Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label="Tax Treaty" name="taxTreaty">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

interface StoreState {
    reportTitle?: string;
    isNRA?: boolean;
    taxTreaty: number;
}

const mapStateToProps = (state: AppState): StoreState => ({
    reportTitle: getBudgetTitle(state),
    isNRA: getIsNRA(state),
    taxTreaty: getTaxTreaty(state),
});

interface Dispatch {
    setBudgetTitle: any;
    setIsNRA: any;
    setTaxTreaty: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        setBudgetTitle: (title: string) => {
            dispatch(setBudgetTitle(title));
        },
        setIsNRA: (isNRA: boolean) => {
            dispatch(setIsNRA(isNRA));
        },
        setTaxTreaty: (taxTreaty: number) => {
            dispatch(setTaxTreaty(taxTreaty));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTitleForm);
