import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button } from 'antd';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/netWorthTracker';
import { mapPropsToFields } from 'helpers';
import { connect } from 'react-redux';
import { liabilitiesFormWrapper, LiabilitiesStoreState, LiabilitiesDispatch, transformLiabilitiesToFormFields, transformFormFieldsToLiabilitiesInfo } from './LiabilitiesFormWrapper';
import FormInputNumber from 'components/FormInputNumber';
import { budgetDisabledStyle } from 'consts/budget';
import { getLiabilitiesFormItems, getOnClickAddLiability } from './LiabilitiesFormItems';

interface WrappedLiabilitiesFormProps {
    removeLiability: any;
    removeRetirementAccount: any;
    addLiability: any;
    addRetirementAccount: any;
    expensesLength: number;
    handleFormChange: any;
}

interface LiabilitiesFormProps extends WrappedLiabilitiesFormProps, LiabilitiesStoreState, LiabilitiesDispatch {}

const Liabilities = (props: LiabilitiesFormProps) => {
    const [form] = Form.useForm();
    const getFields = () => {
        const fields = mapPropsToFields(transformLiabilitiesToFormFields(props.liabilitiesInfo));
        fields.push({ name: ['totalLiabilities'], value: props.liabilitiesInfo.totalLiabilities });
        fields.push({ name: ['totalNetWorth'], value: props.totalNetWorth });
        return fields;
    };
    const liabilitiesInfo = props.liabilitiesInfo;
    const liabilitiesLength = liabilitiesInfo.liabilities.length;
    return (
        <Form
            layout="vertical"
            form={form}
            fields={getFields()}
            onValuesChange={(changedValues) => {
                props.setLiabilitiesInfo(transformFormFieldsToLiabilitiesInfo(props.liabilitiesInfo, changedValues));
            }}
            scrollToFirstError
        >
            <Row>
                <h2 style={{ float: 'left' }}>Liabilities</h2>
            </Row>

            {getLiabilitiesFormItems({ liabilitiesLength, removeLiability: props.removeLiability })}

            <Row style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Col
                    lg={{
                        span: 12,
                        offset: 3,
                    }}
                    sm={24}
                >
                    <Button type="dashed" onClick={getOnClickAddLiability(form, props.addLiability)}>
                        <PlusOutlined /> Add Liability Category
                    </Button>
                </Col>
            </Row>

            <Form.Item label="Total Liabilities" name="totalLiabilities">
                <FormInputNumber dollar disabled style={budgetDisabledStyle} />
            </Form.Item>

            <Form.Item label="Current Net Worth" name="totalNetWorth">
                <FormInputNumber min={Number.MIN_SAFE_INTEGER} dollar disabled style={budgetDisabledStyle} />
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state: AppState): LiabilitiesStoreState => ({
    liabilitiesInfo: state.netWorthTracker.currentNetWorthInfo.liabilitiesInfo,
    totalNetWorth: state.netWorthTracker.currentNetWorthInfo.summary.totalNetWorth,
});

const mapDispatchToProps = (dispatch): LiabilitiesDispatch => {
    return {
        setLiabilitiesInfo: (liabilities) => {
            dispatch(actions.setLiabilitiesInfo(liabilities));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(liabilitiesFormWrapper(Liabilities));
