/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect } from 'react';
import { Card, Form } from 'antd';
import { REPAIR_COST_BREAKDOWN_LABELS } from 'consts/rental';
import { createFormFromArray, mapPropsToFields } from 'helpers';
import { FormInstance } from 'antd/lib/form';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { RehabInfoForm$key } from './__generated__/RehabInfoForm.graphql';
import RelayEnvironment from 'RelayEnvironment';
import FormItem from 'antd/lib/form/FormItem';
import FormInputNumber from 'components/FormInputNumber';

const {
    ROOF,
    CONCRETE,
    GUTTERS_SOFFIT_FASCIA,
    GARAGE,
    SIDING,
    LANDSCAPING,
    EXTERIOR_PAINTING,
    SEPTIC,
    DECKS,
    FOUNDATION,
    DEMO,
    SHEETROCK,
    PLUMBING,
    CARPENTRY_WINDOW_DOORS,
    ELECTRICAL,
    INTERIOR_PAINTING,
    HVAC,
    CABINETS_COUNTERTOPS,
    FRAMING,
    FLOORING,
    PERMITS,
    TERMITES,
    MOLD,
    MISCELLANEOUS,
} = REPAIR_COST_BREAKDOWN_LABELS;

export interface RehabInfoFormProps {
    rehabInfo?: RehabInfoForm$key | null;
    onValidationSuccess: () => void;
    onValidationFailed: () => void;
    shouldValidate: boolean;
    setValidated: () => void;
    reportId?: string | null;
}

const RehabInfoForm = (props: RehabInfoFormProps) => {
    const formRef = useRef<FormInstance | null>(null);
    const rehabInfo = useFragment(
        graphql`
            fragment RehabInfoForm on RentalReportRepairCostBreakdown {
                roof
                concrete
                guttersSoffitFascia
                garage
                siding
                landscaping
                exteriorPainting
                septic
                decks
                foundation
                demo
                sheerstock
                plumbing
                carpentryWindowsDoors
                electrical
                interiorPainting
                hvac
                cabinetsCounterTops
                framing
                flooring
                permits
                termites
                mold
                miscellaneous
                totalRepairCost
            }
        `,
        props.rehabInfo ?? null,
    );

    useEffect(() => {
        if (props.shouldValidate) {
            props.setValidated();
            formRef.current
                ?.validateFields()
                .then(() => {
                    props.onValidationSuccess();
                })
                .catch((error) => {
                    props.onValidationFailed();
                });
        }
    }, [props, formRef]);

    const handleSubmit = (values) => {
        commitLocalUpdate(RelayEnvironment, (store) => {
            const id = props.reportId as string;
            if (id != null) {
                const record = store.get(id);
                if (record != null && record.getLinkedRecord('repairCostBreakdown') != null) {
                    const repairCostBreakdown = record.getLinkedRecord('repairCostBreakdown');

                    const newRehabInfo = { ...rehabInfo };
                    Object.keys(values).forEach((key) => {
                        newRehabInfo[key] = values[key];
                    });
                    if (!('totalRepairCost' in values)) {
                        values.totalRepairCost =
                            Object.keys({ ...newRehabInfo }).reduce((sum, key) => {
                                if (key === 'totalRepairCost') { return sum; }
                                return sum + (newRehabInfo[key] ?? 0);
                            }, 0);
                    }
                    Object.keys(values).forEach((key) => {
                        repairCostBreakdown?.setValue(values[key], key);
                    });
                }
            }
        });
    };

    const exterior = [
        ROOF,
        CONCRETE,
        GUTTERS_SOFFIT_FASCIA,
        GARAGE,
        SIDING,
        LANDSCAPING,
        EXTERIOR_PAINTING,
        SEPTIC,
        DECKS,
        FOUNDATION,
    ];
    const interior = [
        DEMO,
        SHEETROCK,
        PLUMBING,
        CARPENTRY_WINDOW_DOORS,
        ELECTRICAL,
        INTERIOR_PAINTING,
        HVAC,
        CABINETS_COUNTERTOPS,
        FRAMING,
        FLOORING,
    ];
    const general = [PERMITS, TERMITES, MOLD, MISCELLANEOUS];

    const exteriorPart = createFormFromArray(exterior, 4);
    const interiorPart = createFormFromArray(interior, 4);
    const generalPart = createFormFromArray(general, 4);

    return (
        <Form
            layout="horizontal"
            className="stepForm"
            onFinish={handleSubmit}
            ref={formRef}
            fields={mapPropsToFields(rehabInfo)}
            onValuesChange={handleSubmit}
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{ required: 'Please fill out ${name}.' }}
        >
            <h3>Exterior Repairs</h3>
            <Card className="form-card">{exteriorPart}</Card>

            <h3 style={{ marginTop: '24px' }}>Interior Repairs</h3>
            <Card className="form-card">{interiorPart}</Card>

            <h3 style={{ marginTop: '24px' }}>General Components</h3>
            <Card className="form-card">{generalPart}</Card>

            <h3 style={{ marginTop: '24px' }}>Total Cost</h3>
            <FormItem name="totalRepairCost">
                <FormInputNumber dollar zeroMarginTop />
            </FormItem>
        </Form>
    );
};

export default RehabInfoForm;
