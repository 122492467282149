import React, { useRef, useEffect } from 'react';
import { Form, Checkbox, Row, Col, Card } from 'antd';
import { PURCHASE_LABELS } from 'consts/rental';
// import ClosingCostModal from '../PurchaseInfoForm/ClosingCostModal';
import { mapPropsToFields } from 'helpers';
import FormInputNumber from 'components/FormInputNumber';
import FormItem from 'components/RentalFormItem';
import { FormInstance } from 'antd/lib/form';
import { useFragment, commitLocalUpdate } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
// import { ClosingCostModal$key } from './__generated__/ClosingCostModal.graphql';
import { RefinanceInfoForm$key } from './__generated__/RefinanceInfoForm.graphql';
import RelayEnvironment from 'RelayEnvironment';

const {
    AFTER_REPAIR_VALUE,
    REFINANCE_CLOSING_COST,
    DOWN_PAYMENT,
    LOAN_INTEREST_RATE,
    POINTS_CHARGED_BY_LENDER,
    OTHER_CHARGES_FROM_THE_LENDER,
    AMORTIZED_YEARS,
    TYPICAL_CAP_RATE,
} = PURCHASE_LABELS;

export interface RefinanceInfoFormProps {
    refinanceInfo?: RefinanceInfoForm$key | null,
    // closingCost?: ClosingCostModal$key | null,
    onValidationSuccess: () => void;
    onValidationFailed: () => void;
    shouldValidate: boolean;
    setValidated: () => void;
    reportId?: string | null;
}

const RefinanceInfoForm = (props: RefinanceInfoFormProps) => {
    // const [closingCostModalVisible, setClosingCostModalVisible] = useState(false);
    const formRef = useRef<FormInstance | null>(null);
    const refinanceInfo = useFragment(
        graphql`
            fragment RefinanceInfoForm on RentalReportRefinanceInfo {
                afterRepairValue
                closingCost
                isCashPurchase
                downPayment
                loanInterestRate
                pointsChargedByLender
                otherChargesFromLender
                wrapLoanFeesIntoLoan
                isInterestOnly
                amortizedYears
            }
        `,
        props.refinanceInfo ?? null);

    useEffect(() => {
        if (props.shouldValidate) {
            props.setValidated();
            formRef.current
                ?.validateFields()
                .then(() => {
                    props.onValidationSuccess();
                })
                .catch((error) => {
                    props.onValidationFailed();
                });
        }
    }, [props, formRef]);

    // const showClosingCostModal = (e) => {
    //     e.preventDefault();
    //     setClosingCostModalVisible(true);
    // };

    // const toggleClosingCostModal = (visible: boolean) => {
    //     setClosingCostModalVisible(visible);
    // };

    const handleSubmit = (values) => {
        commitLocalUpdate(RelayEnvironment, store => {
            const id = props.reportId as string;
            if (id != null) {
                const record = store.get(id);
                if (record != null && record.getLinkedRecord('refinanceInfo') != null) {
                    const refinanceInfo = record.getLinkedRecord('refinanceInfo');
                    Object.keys(values).forEach(key => {
                        refinanceInfo?.setValue(values[key], key);
                    });
                }
            }
        });
    };

    const getLoanDetailsComponent = () => {
        const isCashPurchase = refinanceInfo?.isCashPurchase;
        if (!isCashPurchase) {
            return (
                <Card className='form-card'>
                    <Row gutter={12}>
                        <Col lg={12} xs={24}>
                            <FormItem label={DOWN_PAYMENT} name="downPayment" rules={[{ required: true }]}>
                                <FormInputNumber percentage zeroMarginTop />
                            </FormItem>
                        </Col>
                        <Col lg={12} xs={24}>
                            <FormItem label={LOAN_INTEREST_RATE} name="loanInterestRate" rules={[{ required: true }]}>
                                <FormInputNumber percentage zeroMarginTop />
                            </FormItem>
                        </Col>
                    </Row>

                    <FormItem label={AMORTIZED_YEARS} name="amortizedYears" rules={[{ required: true }]}>
                        <FormInputNumber zeroMarginTop />
                    </FormItem>

                    <FormItem label={POINTS_CHARGED_BY_LENDER} name="pointsChargedByLender">
                        <FormInputNumber percentage zeroMarginTop />
                    </FormItem>

                    <FormItem label={OTHER_CHARGES_FROM_THE_LENDER} name="otherChargesFromLender">
                        <FormInputNumber dollar zeroMarginTop />
                    </FormItem>

                    <Row style={{ marginTop: '12px' }}>
                        <h4 style={{ float: 'left' }}>Are loan fees & points wrapped into the loan?</h4>
                        <FormItem name="wrapLoanFeesIntoLoan" valuePropName="checked" noStyle>
                            <Checkbox style={{ float: 'left', marginLeft: '10px' }} />
                        </FormItem>
                    </Row>

                    <Row>
                        <h4 style={{ float: 'left' }}>Interest Only?</h4>
                        <FormItem name="isInterestOnly" valuePropName="checked" noStyle>
                            <Checkbox disabled style={{ float: 'left', marginLeft: '10px' }} />
                        </FormItem>
                    </Row>

                    <FormItem label={TYPICAL_CAP_RATE} name="typicalCapRate">
                        <FormInputNumber percentage zeroMarginTop />
                    </FormItem>
                </Card>
            );
        } else {
            return null;
        }
    };

    return (
        <Form
            layout="horizontal"
            className="stepForm"
            onFinish={handleSubmit}
            ref={formRef}
            fields={mapPropsToFields(refinanceInfo)}
            onValuesChange={handleSubmit}
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{ required: 'Please fill out ${name}.' }}
        >
            <Card className='form-card'>
                <Row gutter={12}>
                    <Col xs={24}>
                        <FormItem
                            label={AFTER_REPAIR_VALUE}
                            name="afterRepairValue"
                        >
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>

                    <Col xs={24}>
                        <FormItem
                            label={REFINANCE_CLOSING_COST}
                            name="closingCost"
                            // extra={
                            //     <p className="closing-cost-extra">
                            //         Enter the total value or provide a <a onClick={showClosingCostModal}>cost breakdown</a>.
                            //     </p>
                            // }
                            style={{ marginBottom: 0 }}
                        >
                            <FormInputNumber dollar zeroMarginTop />
                        </FormItem>
                    </Col>
                </Row>
            </Card>

            {/* <ClosingCostModal
                visible={closingCostModalVisible}
                toggleModal={toggleClosingCostModal}
                closingCost={props.closingCost}
                reportId={props.reportId}
            /> */}

            <h3 style={{ textAlign: 'left', marginTop: '24px' }}>Financing</h3>

            {getLoanDetailsComponent()}
        </Form>
    );
};

export default RefinanceInfoForm;
