import React from 'react';
import { connect } from 'react-redux';
import { MortgageInterface } from 'mortgage/types';
import { AppState } from 'redux/store';
import { checkNumber } from 'redux/selectors';
import { Axis, Chart, LineAdvance, Tooltip } from 'bizcharts';

interface EquityData {
    year: number;
    equity: number;
    mortgage: string;
}

const getData = (mortgages: MortgageInterface[], maxYears: number) => {
    let data: EquityData[] = [];
    mortgages.forEach((mortgage, index) => {
        data = [...data, ...getEquityArray(mortgage, maxYears, mortgage.title == null ? '' : mortgage.title)];
    });
    return data;
};

const getEquityArray = (mortgage: MortgageInterface, maxYears: number, mortgageTitle: string): EquityData[] => {
    if (mortgage.isCashPurchase) {
        const equityArray: EquityData[] = [];

        for (let year = 1; year <= maxYears; year++) {
            equityArray.push({ year: year, equity: 1, mortgage: mortgageTitle });
        }
        return equityArray;
    }

    if (mortgage.annualRepaymentSchedule == null) {
        return [];
    }

    const purchasePrice = checkNumber(mortgage.purchasePrice);
    let equityArray: EquityData[] = [];

    for (let year = 1; year <= maxYears; year++) {
        if (year < checkNumber(mortgage.amortizedYears)) {
            const equity = 1 - mortgage.annualRepaymentSchedule[year - 1].balance / purchasePrice;
            const newEle = { year, equity, mortgage: mortgageTitle };
            equityArray = [...equityArray, newEle];
        } else {
            equityArray = [...equityArray, { equity: 1, mortgage: mortgageTitle, year }];
        }
    }
    return equityArray;
};

const MortgageEquityComparisonCard = (props: StoreState) => {
    const { mortgages } = props;

    if (mortgages == null || mortgages.length === 0) return null;
    const maxYears = mortgages.reduce((max, mortgage) => {
        return Math.max(max, checkNumber(mortgage.amortizedYears));
    }, 0);
    const data = getData(mortgages, maxYears);
    if (data == null || data.length === 0) {
        return null;
    }

    return (
        <div>
            <h3 style={{ textAlign: 'center', marginTop: '16px' }}>Equity</h3>
            <Chart padding={[50, 20, 50, 80]} autoFit height={400} data={data} >
                <LineAdvance
                    shape="smooth"
                    point
                    area
                    position="year*equity"
                    color="mortgage"
                />
                <Axis name="equity" label={{ formatter: (text, item, index) => { return Math.round(parseFloat(text) * 100 * 10) / 10 + '%';
                } }}/>
                <Axis name="year" label={{ formatter: (text, item, index) => { return `Year ${text}`; } }}/>
                <Tooltip>
                    {(title,items) => {
                        return (
                            <>
                                <div style={{ marginBottom: '12px', marginTop: '12px' }}>
                                    {'Year ' + title}
                                </div>
                                <ul style={{ margin: '0', listStyleType: 'none', padding: '0' }}>
                                    {items?.map((item, index) => {
                                        return (
                                            <li key={index} style={{ listStyleType: 'none', padding: '0', margin: '12px 0px' }}>
                                                <span style={{ background: item.color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px' }}></span>
                                                <span>{item.name}</span>:
                                                <span style={{ display: 'inline-block', float: 'right', marginLeft: '30px' }}>{Math.round(item.value * 100 * 10) / 10 + '%'}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        );
                    }}
                </Tooltip>
            </Chart>
        </div>
    );
};

interface StoreState {
    mortgages?: MortgageInterface[],
}

const mapStateToProps = (state: AppState): StoreState => ({
    mortgages: state.mortgage.currentReport.mortgages,
});


export default connect(mapStateToProps, null)(MortgageEquityComparisonCard);
