import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import { ConnectedRouter } from 'connected-react-router';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import RelayEnvironment from './RelayEnvironment';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import AppLayout from 'AppLayout';

ReactDOM.render(
    <RelayEnvironmentProvider environment={RelayEnvironment}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <BrowserRouter>
                    <ErrorBoundary
                        fallback={<AppLayout allowRedfinEmail={false} />}
                        // fallback={fallback(<h2>Sorry, something went wrong</h2>)}
                    >
                        <Suspense
                            fallback={<AppLayout allowRedfinEmail={false} />}
                        >
                            <App />
                        </Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </ConnectedRouter>
        </Provider>
    </RelayEnvironmentProvider>,
    document.getElementById('root'),
);
