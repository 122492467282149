import React, { useState, useMemo, useContext } from 'react';
import PreviousRentalReportCard from '../PreviousReports/PreviousRentalReportCard';
import { List, Empty, Spin, Radio, Breadcrumb } from 'antd';
import { getCashflow, getCashOnCash } from 'redux/selectors';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { RentalWatchlistListFragment_reports } from './__generated__/RentalWatchlistListFragment_reports.graphql';
import { RentalWatchlistQueryResponse } from './__generated__/RentalWatchlistQuery.graphql';
import { WindowWidthPxContext } from 'AppLayout';


export type RentalWatchlistReportType = NonNullable<NonNullable<RentalWatchlistListFragment_reports>[number]>;

interface Props {
    selectedReportId: string | undefined;
    setSelectedReportId: any;
    firebaseAuthLoaded: boolean;
    firebaseAuthEmpty: boolean;
    rentalWatchlist: NonNullable<RentalWatchlistQueryResponse['RentalWatchlist']>;
    loading: boolean;
    refs: any;
    watchlistType: string;
    setWatchlistType: (string) => void;
}

const rentalWatchlistReportsFragment = graphql`
    fragment RentalWatchlistListFragment_reports on RentalReport @relay(plural: true, mask: false) {
        id
        _id
        createTime
        watchlistStatus
        isShortTermRental
        isBRRRR
        propertyUrl
        propertyInfo {
            reportTitle
            address
            city
            state
            zipcode
            bedrooms
            bathrooms
            totalSqft
            annualTax
            photos
            location {
                lat
                lng
            }
        }
        purchaseInfo {
            purchasePrice
            listingPrice
            closingCost
            repairCost
            isCashPurchase
            pointsChargedByLender
            wrapLoanFeesIntoLoan
            otherChargesFromLender
            loanInterestRate
            downPayment
            amortizedYears
            afterRepairValue
        }
        rentalInfo {
            monthlyRent
            dailyRate
            occupancyRate
            otherMonthlyIncome
            vacancy
            capex
            maintenance
            managementFees
            monthlyInsurance
            pmi
            hoa
            electricity
            waterAndSewer
            garbage
            internet
            cleaning
            supplies
            otherMonthlyExpenses
            annualExpensesGrowth
            annualIncomeGrowth
            annualPropertyValueGrowth
            salesExpenses
        }
    }
`;

const RentalWatchListList = (props: Props) => {
    const windowWidthPx = useContext(WindowWidthPxContext);
    const [sortCriteria, setSortCriteria] = useState('default');

    const rentalWatchlist = props.rentalWatchlist;
    const watchlist = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.watchlist) as (RentalWatchlistListFragment_reports | null);
    const backup = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.backup) as (RentalWatchlistListFragment_reports | null);
    const madeOffer = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.madeOffer) as (RentalWatchlistListFragment_reports | null);
    const portfolio = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.portfolio) as (RentalWatchlistListFragment_reports | null);
    const madeOfferButFailed = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.madeOfferButFailed) as (RentalWatchlistListFragment_reports | null);
    const pending = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.pending) as (RentalWatchlistListFragment_reports | null);
    const offMarket = useFragment(rentalWatchlistReportsFragment, rentalWatchlist?.offMarket) as (RentalWatchlistListFragment_reports | null);

    const cashflowInfoMapper = (list: RentalWatchlistReportType[]): any[] =>
        list.map((property) => ({
            ...property,
            cashflow: getCashflow(property),
            cashOnCash: getCashOnCash(property),
        }));

    const getSortedList = (list: RentalWatchlistReportType[]) => {
        const enhancedList = cashflowInfoMapper(list);
        switch (sortCriteria) {
            case 'coc':
                enhancedList.sort((a, b) => {
                    const aval = a.cashOnCash != null ? a.cashOnCash : 0;
                    const bval = b.cashOnCash != null ? b.cashOnCash : 0;
                    return bval - aval;
                });
                break;
            case 'cashflow':
                enhancedList.sort((a, b) => {
                    const aval = a.cashflow != null ? a.cashflow : 0;
                    const bval = b.cashflow != null ? b.cashflow : 0;
                    return bval - aval;
                });
                break;
            case 'default':
            default:
                break;
        }
        return enhancedList;
    };

    const getList = (list) => {
        if (list == null) {
            list = [];
        }
        return <List
            itemLayout="vertical"
            split={false}
            grid={{ gutter: 16, column: 1 }}
            dataSource={getSortedList(list)}
            renderItem={(item: RentalWatchlistReportType) => (
                <List.Item
                    onMouseOver={() => {
                        if(item._id != null) props.setSelectedReportId(item._id);
                    }}
                >
                    <div
                        ref={item._id == null ? null : props.refs[item._id as string]}
                    >
                        <PreviousRentalReportCard
                            from="/rental-watchlist"
                            reportInfo={item}
                        />
                    </div>

                </List.Item>
            )}
            loading={props.loading}
        />;
    };

    const AllListContainer = (props) =>
        (
            <div style={{
                height: (window.innerHeight - 195) + 'px',
                overflowY: 'auto',
                paddingRight: '8px',
                width: '100%',
            }}>
                {props.children}
            </div>
        );

    const getAllLists = useMemo(() => {
        if (props.loading || !props.firebaseAuthLoaded) {
            return <Spin size="large" style={{ margin: '0 auto', display: 'block' }} />;
        } else if (props.firebaseAuthEmpty) {
            return <Empty description="You need to log in first" />;
        } else {
            switch (props.watchlistType) {
                case 'portfolio':
                    return <AllListContainer>
                        {
                            (madeOffer != null && madeOffer.length > 0) &&<div>
                                <h2>Made offer</h2>
                                {getList(madeOffer)}
                            </div>
                        }
                        <div>
                            <h2>Portfolio</h2>
                            {getList(portfolio == null ? [] : portfolio)}
                        </div>
                    </AllListContainer>;
                case 'pending':
                    return (
                        <AllListContainer>
                            <div>
                                <h2>Pending</h2>
                                {getList(pending == null ? [] : pending)}
                            </div>
                        </AllListContainer>
                    );
                case 'history':
                    return <AllListContainer>
                        {
                            (madeOfferButFailed != null && madeOfferButFailed.length > 0) &&
                            <div>
                                <h2>Made offer but failed</h2>
                                {getList(madeOfferButFailed)}
                            </div>
                        }
                        {
                            <div>
                                <h2>Pending</h2>
                                {getList(pending == null ? [] : pending)}
                            </div>
                        }
                        {
                            <div>
                                <h2>Off market</h2>
                                {getList(offMarket == null ? [] : offMarket)}
                            </div>
                        }
                    </AllListContainer>;
                case 'watchlist':
                default:
                    return <AllListContainer>
                        <h2>Watchlist</h2>
                        {getList(watchlist == null ? [] : watchlist)}
                        {
                            (backup != null && backup.length > 0) &&
                            <div>
                                <h2>Backup</h2>
                                {getList(backup)}
                            </div>
                        }
                    </AllListContainer>;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loading, props.firebaseAuthEmpty, props.firebaseAuthLoaded, props.watchlistType, watchlist, backup, offMarket, pending, madeOffer, madeOfferButFailed]);

    return (
        <div className="rental-watchlist">
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Rental Watchlist
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Content>
                <div style={{ marginBottom: '16px', marginTop: '8px' }}>
                    <Radio.Group
                        value={props.watchlistType}
                        onChange={(e) => {
                            props.setWatchlistType(e.target.value);
                        }}
                    >
                        <Radio.Button value="watchlist">Watchlist</Radio.Button>
                        <Radio.Button value="portfolio">Portfolio</Radio.Button>
                        <Radio.Button value="pending">Pending</Radio.Button>
                        <Radio.Button value="history">History</Radio.Button>
                    </Radio.Group>
                    <div style={windowWidthPx > 1380 ?
                        { display: 'inline-block', float: 'right' } : {
                            display: 'block', marginTop: '16px'
                        }
                    }>
                        <span style={{ fontSize: '16px', marginRight: '8px' }}>Sort by:</span>
                        <Radio.Group
                            value={sortCriteria}
                            onChange={(e) => {
                                setSortCriteria(e.target.value);
                            }}
                        >
                            <Radio.Button value="default">Default</Radio.Button>
                            <Radio.Button value="coc">Cash on Cash</Radio.Button>
                            <Radio.Button value="cashflow">Cashflow</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                {getAllLists}
            </Content>
        </div>
    );
};

export default RentalWatchListList;
