import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button } from 'antd';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/netWorthTracker';
import { mapPropsToFields } from 'helpers';
import { connect } from 'react-redux';
import { assetsFormWrapper, AssetsStoreState, AssetsDispatch, transformAssetsToFormFields, transformFormFieldsToAssetsInfo } from './AssetsFormWrapper';
import FormInputNumber from 'components/FormInputNumber';
import { budgetDisabledStyle } from 'consts/budget';
import { getAssetsFormItems, getRetirementAccountsFormItems, getOnClickAddAsset, getOnClickAddRetirementAccount } from './AssetsFormItems';

interface AssetsFormProps extends WrappedAssetsFormProps, AssetsStoreState, AssetsDispatch {}

const Assets = (props: AssetsFormProps) => {
    const [form] = Form.useForm();
    const getFields = () => {
        const fields = mapPropsToFields(transformAssetsToFormFields(props.assetsInfo));
        fields.push({ name: ['totalAssetsWithoutRetirementAccounts'], value: props.assetsInfo.totalAssetsWithoutRetirementAccounts });
        fields.push({ name: ['totalRetirementAccounts'], value: props.assetsInfo.totalRetirementAccounts });
        fields.push({ name: ['totalAssets'], value: props.assetsInfo.totalAssets });
        return fields;
    };
    const assetsInfo = props.assetsInfo;
    const assetsLength = assetsInfo.assets.length;
    const retirementAccountsLength = assetsInfo.retirementAccounts.length;

    return (
        <Form
            layout="vertical"
            form={form}
            fields={getFields()}
            onValuesChange={(changedValues) => {
                props.setAssetsInfo(transformFormFieldsToAssetsInfo(props.assetsInfo, changedValues));
            }}
            scrollToFirstError
        >
            <Row>
                <h2 style={{ float: 'left' }}>Assets</h2>
            </Row>

            {getAssetsFormItems({ assetsLength, removeAsset: props.removeAsset })}

            <Row style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Col
                    lg={{
                        span: 12,
                        offset: 3,
                    }}
                    sm={24}
                >
                    <Button type="dashed" onClick={getOnClickAddAsset(form, props.addAsset)}>
                        <PlusOutlined /> Add Asset Class
                    </Button>
                </Col>
            </Row>

            <Row>
                <h3 style={{ float: 'left' }}>Retirement Accounts</h3>
            </Row>

            {getRetirementAccountsFormItems({ retirementAccountsLength, removeRetirementAccount: props.removeRetirementAccount })}

            <Row style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Col
                    lg={{
                        span: 12,
                        offset: 3,
                    }}
                    sm={24}
                >
                    <Button type="dashed" onClick={getOnClickAddRetirementAccount(form, props.addRetirementAccount)}>
                        <PlusOutlined /> Add Retirement Accounts
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col lg={12} sm={24}>
                    <Form.Item label="Total Assets (Without Retirement Accounts)" name="totalAssetsWithoutRetirementAccounts">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
                <Col lg={12} sm={24}>
                    <Form.Item label="Total Retirement Accounts" name="totalRetirementAccounts">
                        <FormInputNumber dollar disabled style={budgetDisabledStyle} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item label="Total Assets" name="totalAssets">
                <FormInputNumber dollar disabled style={budgetDisabledStyle} />
            </Form.Item>
        </Form>
    );
};

interface WrappedAssetsFormProps {
    removeAsset: any;
    removeRetirementAccount: any;
    addAsset: any;
    addRetirementAccount: any;
    expensesLength: number;
    handleFormChange: any;
}

const mapStateToProps = (state: AppState): AssetsStoreState => ({
    assetsInfo: state.netWorthTracker.currentNetWorthInfo.assetsInfo,
});

const mapDispatchToProps = (dispatch): AssetsDispatch => {
    return {
        setAssetsInfo: (assets) => {
            dispatch(actions.setAssetsInfo(assets));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(assetsFormWrapper(Assets));
