import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import AppLayout from './AppLayout';
import { AppState } from 'redux/store';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { AppQuery, AppQueryResponse } from '__generated__/AppQuery.graphql';

const GraphQLAppQuery = graphql`
    query AppQuery($uid: String!, $userNotLoggedIn: Boolean!) {
        UserByUid(filter: { uid: $uid }) @skip(if: $userNotLoggedIn) {
            uid
            allowRedfinEmail
            allowFetchWebsite
        }
        DefaultStrategy @skip(if: $userNotLoggedIn) {
            id
            _id
            closingCost
            repairCost
            downPayment
            loanInterestRate
            wrapLoanFeesIntoLoan
            pointsChargedByLender
            amortizedYears
            electricity
            waterAndSewer
            pmi
            garbage
            hoa
            monthlyInsurance
            otherMonthlyExpenses
            vacancy
            maintenance
            capex
            managementFees
            annualIncomeGrowth
            annualPropertyValueGrowth
            annualExpensesGrowth
            salesExpenses
        }
    }
`;

interface Props {
    firebaseAuthLoaded: boolean;
    uid: string;
}

export type DefaultStrategyContextType = NonNullable<AppQueryResponse['DefaultStrategy']>[number];
export const DefaultStrategyContext = React.createContext<AppQueryResponse['DefaultStrategy']>([]);
export const UserContext = React.createContext<AppQueryResponse['UserByUid']>({
    uid: null,
    allowRedfinEmail: false,
    allowFetchWebsite: false,
});


const App = (props: Props) => {
    const { uid } = props;

    const query = useLazyLoadQuery<AppQuery>(
        GraphQLAppQuery,
        {
            uid: uid ?? '',
            userNotLoggedIn: uid == null || uid.length === 0,
        });
    const user = query.UserByUid;
    const defaultStrategy = query.DefaultStrategy;

    return (
        <UserContext.Provider value={user}>
            <DefaultStrategyContext.Provider value={defaultStrategy ?? []}>
                <AppLayout allowRedfinEmail={!!user?.allowRedfinEmail} />
            </DefaultStrategyContext.Provider>
        </UserContext.Provider>
    );
};

const mapStateToProps = (state: AppState) => ({
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
    uid: state.firebase.auth.uid,
});

export default connect(mapStateToProps)(App);
