import React, { useState, Suspense, useContext } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Row, Col, Layout } from 'antd';
import WatchlistMap from './WatchlistMap';
import RentalWatchlistList from './RentalWatchlistList';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { RentalWatchlistQuery } from './__generated__/RentalWatchlistQuery.graphql';
import { WindowWidthContext } from 'AppLayout';
import { WindowWidth } from 'helpers';

type Props = StoreState;

export interface IRentalWatchListItem {
    _id: string;
    watchlistStatus?: string;
    propertyInfo: {
        location?: {
            lat?: number;
            lng?: number;
        }
    };
    purchaseInfo: {
        purchasePrice?: number;
        listingPrice?: number;
    };
}

const RentalWatchList = (props: Props) => {
    const { firebaseAuthLoaded, firebaseAuthEmpty } = props;

    const rentalWatchlist = useLazyLoadQuery<RentalWatchlistQuery>(
        graphql`
          query RentalWatchlistQuery($userNotLoaded: Boolean!) {
                RentalWatchlist @skip(if: $userNotLoaded) {
                    watchlist {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                    backup {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                    madeOffer {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                    portfolio {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                    madeOfferButFailed {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                    pending {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                    offMarket {
                        _id
                        id
                        watchlistStatus
                        propertyInfo {
                            location {
                                lat
                                lng
                            }
                        }
                        purchaseInfo {
                            purchasePrice
                            listingPrice
                        }
                        ...RentalWatchlistListFragment_reports
                    }
                }
            }
        `,
        { userNotLoaded: !firebaseAuthLoaded || firebaseAuthEmpty },
    ).RentalWatchlist;

    const refs = {};
    if (rentalWatchlist != null) {
        Object.keys(rentalWatchlist).forEach(watchlistStatus => {
            if (rentalWatchlist[watchlistStatus] != null) {
                rentalWatchlist[watchlistStatus].forEach(listing => {
                    if (listing._id != null) {
                        refs[listing._id] = React.createRef();
                    }
                });
            }
        });
    }

    return <RentalWatchlistUI
        refs={refs}
        rentalWatchlist={rentalWatchlist}
        firebaseAuthLoaded={firebaseAuthLoaded}
        firebaseAuthEmpty={firebaseAuthEmpty}
    />;
};

interface StoreState {
    firebaseAuthLoaded: boolean;
    firebaseAuthEmpty: boolean;
}

const mapStateToProps = (state: AppState): StoreState => ({
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
    firebaseAuthEmpty: state.firebase.auth.isEmpty,
});

interface RentalWatchlistUIProps {
    loading?: boolean;
    refs?: any;
    rentalWatchlist?: any;
    firebaseAuthLoaded: boolean;
    firebaseAuthEmpty: boolean;
}

const RentalWatchlistUI = (props: RentalWatchlistUIProps) => {
    const windowWidth = useContext(WindowWidthContext);
    const [selectedReportId, setSelectedReportId] = useState('');
    const [watchlistType, setWatchlistType] = useState('watchlist');
    const { loading, refs, rentalWatchlist, firebaseAuthEmpty, firebaseAuthLoaded } = props;
    const onSelectMarker = reportId => {
        setSelectedReportId(reportId);
        if (refs[reportId] != null && refs[reportId].current != null) {
            refs[reportId].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const getRentalWatchlistList = () => {
        return <RentalWatchlistList
            rentalWatchlist={rentalWatchlist ?? {
                watchlist: [],
                backup: null,
                pending: null,
                madeOffer: null,
                portfolio: null,
                madeOfferButFailed: null,
                offMarket: null,
            }}
            selectedReportId={selectedReportId}
            setSelectedReportId={setSelectedReportId}
            firebaseAuthLoaded={firebaseAuthLoaded}
            firebaseAuthEmpty={firebaseAuthEmpty}
            loading={loading || rentalWatchlist == null}
            refs={refs}
            watchlistType={watchlistType}
            setWatchlistType={setWatchlistType}
        />;
    };

    const convertLists = (lists): IRentalWatchListItem[] => {
        const filteredLists = lists.filter(
            (list) => {return list != null; }
        );

        return filteredLists.reduce((results, list) => {
            if (list == null) return results;
            const filteredList = list?.filter(listing => {
                return listing.propertyInfo?.location != null;
            });
            return [
                ...results,
                ...filteredList,
            ];
        }, [] as IRentalWatchListItem[]);
    };

    const getRentalWatchlistForMap = () => {
        if (rentalWatchlist == null) return [] as IRentalWatchListItem[];
        switch (watchlistType) {
            case 'portfolio':
                return convertLists([rentalWatchlist.madeOffer, rentalWatchlist.portfolio]);
            case 'pending':
                return convertLists([rentalWatchlist.pending]);
            case 'history':
                return convertLists([
                    rentalWatchlist.madeOfferButFailed,
                    rentalWatchlist.offMarket,
                ]);
            case 'watchlist':
            default:
                return convertLists([
                    rentalWatchlist.watchlist,
                    rentalWatchlist.backup,
                ]);
        }
    };

    return (
        <Layout style={{ background: '#fbfbfb' }}>
            {windowWidth >= WindowWidth.MD ?
                (<Row>
                    <Col span={11} style={{ height: (window.innerHeight - 70)+ 'px' }}>
                        <WatchlistMap
                            rentalWatchlist={getRentalWatchlistForMap()}
                            selectedReportId={selectedReportId}
                            setSelectedReportId={onSelectMarker}
                        />
                    </Col>
                    <Col span={13} style={{ padding: '0 16px' }}>
                        {getRentalWatchlistList()}
                    </Col>
                </Row>) :
                <div style={{ padding: '0 12px' }}>
                    {getRentalWatchlistList()}
                </div>
            }
        </Layout>
    );
};

const withSuspense = props =>
    <Suspense fallback={
        <RentalWatchlistUI
            loading
            firebaseAuthEmpty={props.firebaseAuthEmpty}
            firebaseAuthLoaded={props.firebaseAuthLoaded}
        />
    }>
        <RentalWatchList {...props} />
    </Suspense>;

export default connect(mapStateToProps)(withSuspense);
