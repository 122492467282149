import React, { Suspense } from 'react';
import { List, Spin } from 'antd';
import RedfinEmailListingItem from './RedfinEmailListingItem';
import InfiniteScroll from 'react-infinite-scroller';
import graphql from 'babel-plugin-relay/macro';
import { usePaginationFragment, useLazyLoadQuery } from 'react-relay';
import { RedfinEmailListingsListQuery } from './__generated__/RedfinEmailListingsListQuery.graphql';
import { RedfinEmailListingsListFragment, RedfinEmailListingsListFragment$key } from './__generated__/RedfinEmailListingsListFragment.graphql';
import { RedfinEmailListingsPaginationQuery, SortConnectionRedfinEmailListingEnum } from './__generated__/RedfinEmailListingsPaginationQuery.graphql';

interface Props {
    orderBy: SortConnectionRedfinEmailListingEnum;
    filterByDays: number;
    showArchived: boolean;
    status: string[],
    search?: string,
}
type RedfinEmailListingsListType = NonNullable<NonNullable<RedfinEmailListingsListFragment['RedfinEmailListingConnection']>['edges'][number]>['node'];

const RedfinEmailListingsList = (props: Props) => {
    const { orderBy, filterByDays, showArchived, status, search } = props;

    const queryData = useLazyLoadQuery<RedfinEmailListingsListQuery>(
        graphql`
            query RedfinEmailListingsListQuery(
                $count: Int = 15
                $cursor: String
                $days: Int
                $orderBy: SortConnectionRedfinEmailListingEnum = _ID_DESC
                $showArchived: Boolean,
                $status: [String!],
                $search: String,
            ) {
                ...RedfinEmailListingsListFragment
            }
        `,
        {
            count: 30,
            cursor: '',
            days: filterByDays,
            orderBy,
            showArchived,
            status,
            search,
        },
    );
    const { data, loadNext, hasNext } = usePaginationFragment<RedfinEmailListingsPaginationQuery, RedfinEmailListingsListFragment$key>(
        graphql`
            fragment RedfinEmailListingsListFragment on Query
            @refetchable(queryName: "RedfinEmailListingsPaginationQuery") {
                RedfinEmailListingConnection(
                    first: $count
                    after: $cursor
                    sort: $orderBy
                    filter: { days: $days, showArchived: $showArchived, statusArr: $status, search: $search }
                ) @connection(key: "Query_RedfinEmailListingConnection") {
                    __id
                    edges {
                        node {
                            _id
                            rentToPrice
                            ...RedfinEmailListingItemFragment
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        queryData,
    );
    const getEdges = data => data.RedfinEmailListingConnection?.edges.map(edge => edge.node) ?? [];
    const connectionID = data.RedfinEmailListingConnection?.__id;

    return (
        <InfiniteScroll
            initialLoad
            pageStart={0}
            loadMore={() => loadNext(15)}
            hasMore={hasNext}
        >
            <List
                itemLayout="vertical"
                split={false}
                grid={{ gutter: 16, column: 1 }}
                dataSource={getEdges(data)}
                renderItem={(item: RedfinEmailListingsListType) => (
                    <List.Item>

                        <RedfinEmailListingItem
                            queryData={item}
                            connectionID={connectionID}
                            showArchived={showArchived}
                        />
                    </List.Item>
                )}
                loading={getEdges(data) == null}
            />
        </InfiniteScroll>
    );
};

const withSuspense = (props: Props) => <Suspense
    fallback={
        <Spin size="large" style={{ margin: '48px auto 0', display: 'block' }} />
    }>
    <RedfinEmailListingsList {...props} />
</Suspense>;

export default withSuspense;
