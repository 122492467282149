import React from 'react';
import _ from 'lodash';
import { Card } from 'antd';
import ExpenseDonutChart from 'components/ExpenseDonutChart';

interface Props {
    expenses: any;
}

const ExpenseReport = (props: Props) => {
    return (
        <Card className='form-card' style={{ marginTop: '12px' }}>
            <h3>Monthly Expenses</h3>
            <ExpenseDonutChart expense={props.expenses} />
            <ul style={{ textAlign: 'start', fontSize: '16px', lineHeight: '28px' }}>
                {_.map(_.omit(props.expenses, 'total'), (value: number, key: string) => {
                    if (value > 0) {
                        return <li key={key}>{`${_.startCase(key)}: $${value.toLocaleString('en')}`}</li>;
                    }
                })}
            </ul>
        </Card>
    );
};

export default ExpenseReport;
