/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RepairCostModal = {
    readonly roof: number | null;
    readonly concrete: number | null;
    readonly guttersSoffitFascia: number | null;
    readonly garage: number | null;
    readonly siding: number | null;
    readonly landscaping: number | null;
    readonly exteriorPainting: number | null;
    readonly septic: number | null;
    readonly decks: number | null;
    readonly foundation: number | null;
    readonly demo: number | null;
    readonly sheerstock: number | null;
    readonly plumbing: number | null;
    readonly carpentryWindowsDoors: number | null;
    readonly electrical: number | null;
    readonly interiorPainting: number | null;
    readonly hvac: number | null;
    readonly cabinetsCounterTops: number | null;
    readonly framing: number | null;
    readonly flooring: number | null;
    readonly permits: number | null;
    readonly termites: number | null;
    readonly mold: number | null;
    readonly miscellaneous: number | null;
    readonly totalRepairCost: number | null;
    readonly " $refType": "RepairCostModal";
};
export type RepairCostModal$data = RepairCostModal;
export type RepairCostModal$key = {
    readonly " $data"?: RepairCostModal$data;
    readonly " $fragmentRefs": FragmentRefs<"RepairCostModal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepairCostModal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roof",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "concrete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guttersSoffitFascia",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "garage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landscaping",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exteriorPainting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "septic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "foundation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sheerstock",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plumbing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carpentryWindowsDoors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "electrical",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interiorPainting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hvac",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinetsCounterTops",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "framing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flooring",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "termites",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "miscellaneous",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRepairCost",
      "storageKey": null
    }
  ],
  "type": "RentalReportRepairCostBreakdown",
  "abstractKey": null
};
(node as any).hash = '7e01ea3bfa4a0500ac32699bdabf36c5';
export default node;
