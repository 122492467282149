import { LiabilitiesInfoInterface, MonthlyNetWorthSummaryInterface } from './../../netWorthTracker/types';
import { MonthlyNetWorthInterface, AssetsInfoInterface } from 'netWorthTracker/types';
import { createSelector } from 'reselect';
import { round } from 'helpers';

const getCurrentNetWorthInfo = (currentNetWorthInfo: MonthlyNetWorthInterface) => currentNetWorthInfo;
export const getAssetsInfo = (currentNetWorthInfo: MonthlyNetWorthInterface) => currentNetWorthInfo.assetsInfo;
export const getLiabilitiesInfo = (currentNetWorthInfo: MonthlyNetWorthInterface) => currentNetWorthInfo.liabilitiesInfo;

export const getComments = createSelector(getCurrentNetWorthInfo, (currentNetWorthInfo) => currentNetWorthInfo.comments);

export const getDerivedAssetsInfo = createSelector(getAssetsInfo, (assetsInfo: AssetsInfoInterface): AssetsInfoInterface => {
    const { assets, retirementAccounts } = assetsInfo;

    const totalAssetsWithoutRetirementAccounts = assets.reduce((sum, asset) => sum += round(asset.value), 0);
    const totalRetirementAccounts = retirementAccounts.reduce((sum, retirementAccount) => sum += round(retirementAccount.value), 0);
    const totalAssets = totalAssetsWithoutRetirementAccounts + totalRetirementAccounts;

    return {
        assets,
        retirementAccounts,
        totalAssetsWithoutRetirementAccounts,
        totalRetirementAccounts,
        totalAssets,
    };
});

export const getDerivedLiabilitiesInfo = createSelector(getLiabilitiesInfo, (liabilitiesInfo: LiabilitiesInfoInterface): LiabilitiesInfoInterface => {
    const { liabilities } = liabilitiesInfo;

    const totalLiabilities = liabilities.reduce((sum, liability) => sum += round(liability.value), 0);

    return {
        liabilities,
        totalLiabilities,
    };
});

export const getDerivedSummary = createSelector([getDerivedAssetsInfo, getDerivedLiabilitiesInfo], (assetsInfo, liabilitiesInfo): MonthlyNetWorthSummaryInterface => {
    const { totalAssets, totalAssetsWithoutRetirementAccounts, totalRetirementAccounts } = assetsInfo;
    const { totalLiabilities } = liabilitiesInfo;
    return {
        totalAssets,
        totalAssetsWithoutRetirementAccounts,
        totalRetirementAccounts: totalRetirementAccounts,
        totalLiabilities: totalLiabilities,
        totalNetWorth: (totalAssets == null ? 0 : totalAssets) - (totalLiabilities == null ? 0 : totalLiabilities),
    };
});

export const getDerivedNetWorthInfo = createSelector(
    [
        getCurrentNetWorthInfo,
        getDerivedAssetsInfo,
        getDerivedLiabilitiesInfo,
        getDerivedSummary,
    ],
    (
        currentNetWorthInfo,
        assetsInfo,
        liabilitiesInfo,
        summary,
    ): MonthlyNetWorthInterface => {
        return {
            ...currentNetWorthInfo,
            assetsInfo,
            liabilitiesInfo,
            summary,
        };
    },
);
