import React from 'react';
import { Form, Input } from 'antd';
import { AppState } from 'redux/store';
import { connect } from 'react-redux';
import { setComments } from 'redux/actions/netWorthTracker';
import { FieldData } from 'helpers';

interface Props extends StoreState, Dispatch {}

const Comments = (props: Props) => {
    const getFields = () => {
        const fields: FieldData[] = [];
        fields.push({ name: ['comments'], value: props.comments });
        return fields;
    };
    return (
        <Form
            layout="vertical"
            fields={getFields()}
            onValuesChange={(values) => {
                props.setComments(values.comments);
            }}
            style={{ textAlign: 'left' }}
        >

            <h2>Comments</h2>
            <Form.Item name="comments">
                <Input.TextArea autoSize={{ minRows: 2 }} style={{ maxWidth: '800px' }} />
            </Form.Item>
        </Form>
    );
};

interface StoreState {
    comments?: string;
}

const mapStateToProps = (state: AppState): StoreState => ({
    comments: state.netWorthTracker.currentNetWorthInfo.comments,
});

interface Dispatch {
    setComments: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        setComments: (comments: string) => {
            dispatch(setComments(comments));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
