import { Environment, Network, RecordSource, Store, commitLocalUpdate, RecordSourceProxy } from 'relay-runtime';
import fetchGraphQL from 'api/graphql';

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(params, variables) {
    return fetchGraphQL(params.text, variables);
}

// Export a singleton instance of Relay Environment configured with our network function:
const environment = new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});

export const initializeRentalReport = (store: RecordSourceProxy, dataID: string) => {
    const record = store.create(dataID, 'RentalReport');

    record.setValue(dataID, 'id');
    record.setValue(dataID, '_id');
    record.getOrCreateLinkedRecord('propertyInfo', 'RentalReportPropertyInfo');
    record.getOrCreateLinkedRecord('purchaseInfo', 'RentalReportPurchaseInfo');
    record.getOrCreateLinkedRecord('rentalInfo', 'RentalReportRentalInfo');
    record.getOrCreateLinkedRecord('closingCostBreakdown', 'RentalReportClosingCostBreakdown');
    record.getOrCreateLinkedRecord('repairCostBreakdown', 'RentalReportRepairCostBreakdown');

    return record;
};

// Set initial values
commitLocalUpdate(environment, store => {
    const dataID = 'client:RentalReport';
    const record = initializeRentalReport(store, dataID);

    store.getRoot().setLinkedRecord(record, `RentalReport(_id:"${dataID}")`);
});

export default environment;