/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RentalWatchlistListFragment_reports = ReadonlyArray<{
    readonly id: string;
    readonly _id: unknown;
    readonly createTime: number | null;
    readonly watchlistStatus: string | null;
    readonly isShortTermRental: boolean | null;
    readonly isBRRRR: boolean | null;
    readonly propertyUrl: string | null;
    readonly propertyInfo: {
        readonly reportTitle: string | null;
        readonly address: string | null;
        readonly city: string | null;
        readonly state: string | null;
        readonly zipcode: string | null;
        readonly bedrooms: number | null;
        readonly bathrooms: number | null;
        readonly totalSqft: number | null;
        readonly annualTax: number | null;
        readonly photos: unknown | null;
        readonly location: {
            readonly lat: number | null;
            readonly lng: number | null;
        } | null;
    } | null;
    readonly purchaseInfo: {
        readonly purchasePrice: number | null;
        readonly listingPrice: number | null;
        readonly closingCost: number | null;
        readonly repairCost: number | null;
        readonly isCashPurchase: boolean | null;
        readonly pointsChargedByLender: number | null;
        readonly wrapLoanFeesIntoLoan: boolean | null;
        readonly otherChargesFromLender: number | null;
        readonly loanInterestRate: number | null;
        readonly downPayment: number | null;
        readonly amortizedYears: number | null;
        readonly afterRepairValue: number | null;
    } | null;
    readonly rentalInfo: {
        readonly monthlyRent: number | null;
        readonly dailyRate: number | null;
        readonly occupancyRate: number | null;
        readonly otherMonthlyIncome: number | null;
        readonly vacancy: number | null;
        readonly capex: number | null;
        readonly maintenance: number | null;
        readonly managementFees: number | null;
        readonly monthlyInsurance: number | null;
        readonly pmi: number | null;
        readonly hoa: number | null;
        readonly electricity: number | null;
        readonly waterAndSewer: number | null;
        readonly garbage: number | null;
        readonly internet: number | null;
        readonly cleaning: number | null;
        readonly supplies: number | null;
        readonly otherMonthlyExpenses: number | null;
        readonly annualExpensesGrowth: number | null;
        readonly annualIncomeGrowth: number | null;
        readonly annualPropertyValueGrowth: number | null;
        readonly salesExpenses: number | null;
    } | null;
}>;
export type RentalWatchlistListFragment_reports$data = RentalWatchlistListFragment_reports;
export type RentalWatchlistListFragment_reports$key = ReadonlyArray<{
    readonly " $data"?: RentalWatchlistListFragment_reports$data;
    readonly " $fragmentRefs": FragmentRefs<"RentalWatchlistListFragment_reports">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false,
    "plural": true
  },
  "name": "RentalWatchlistListFragment_reports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watchlistStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isShortTermRental",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBRRRR",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportPropertyInfo",
      "kind": "LinkedField",
      "name": "propertyInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reportTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bedrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bathrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSqft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualTax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photos",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RentalReportPropertyInfoLocation",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lng",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportPurchaseInfo",
      "kind": "LinkedField",
      "name": "purchaseInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "purchasePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "listingPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closingCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repairCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCashPurchase",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pointsChargedByLender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wrapLoanFeesIntoLoan",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherChargesFromLender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loanInterestRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downPayment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amortizedYears",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "afterRepairValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalReportRentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyRent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dailyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "occupancyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherMonthlyIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vacancy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maintenance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managementFees",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyInsurance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pmi",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hoa",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "electricity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waterAndSewer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "garbage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cleaning",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supplies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherMonthlyExpenses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualExpensesGrowth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualIncomeGrowth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualPropertyValueGrowth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesExpenses",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RentalReport",
  "abstractKey": null
};
(node as any).hash = 'fe1cd1abb13ef21042a339a74a39f2e0';
export default node;
