import React, { useContext, useState } from 'react';
import { Form, Checkbox, Row, Col, Button, message } from 'antd';
import { PURCHASE_LABELS, RENTAL_LABELS } from 'consts/rental';
import { mapPropsToFields } from 'helpers';
import FormInputNumber from 'components/FormInputNumber';
import BasicRow from 'components/BasicRow';
import { DefaultStrategyContext } from '../App';
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import RelayEnvironment from '../RelayEnvironment';
import { DefaultStrategyFormMutation } from './__generated__/DefaultStrategyFormMutation.graphql';

const {
    ELECTRICITY,
    WATER_AND_SEWER,
    PMI,
    GARBAGE,
    HOA,
    MONTHLY_INSURANCE,
    OTHER_MONTHLY_EXPENSES,
    VACANCY,
    MAINTENANCE,
    CAPEX,
    MANAGEMENT_FEES,
    ANNUAL_INCOME_GROWTH,
    ANNUAL_PV_GROWTH,
    ANNUAL_EXPENSES_GROWTH,
    SALES_EXPENSES,
} = RENTAL_LABELS;

const {
    CLOSING_COST,
    REPAIR_COST,
    DOWN_PAYMENT,
    LOAN_INTEREST_RATE,
    POINTS_CHARGED_BY_LENDER,
    OTHER_CHARGES_FROM_THE_LENDER,
    AMORTIZED_YEARS,
} = PURCHASE_LABELS;

const mutation = graphql`
    mutation DefaultStrategyFormMutation($id: MongoID, $record: SaveDefaultStrategyInput!) {
        DefaultStrategySave(_id: $id, record: $record) {
            recordId
            record {
                id
                _id
                closingCost
                repairCost
                downPayment
                loanInterestRate
                wrapLoanFeesIntoLoan
                pointsChargedByLender
                amortizedYears
                electricity
                waterAndSewer
                pmi
                garbage
                hoa
                monthlyInsurance
                otherMonthlyExpenses
                vacancy
                maintenance
                capex
                managementFees
                annualIncomeGrowth
                annualPropertyValueGrowth
                annualExpensesGrowth
                salesExpenses
            }
        }
    }
`;

interface Props {
    onSave: any;
}

const DefaultStrategyForm = (props: Props) => {
    const context = useContext(DefaultStrategyContext);
    const strategy = (context != null && context.length > 0) ? context[0] : null;

    const [form] = Form.useForm();
    const handleSubmit = (values) => {
        commitMutation<DefaultStrategyFormMutation>(RelayEnvironment, {
            mutation: mutation,
            variables: {
                id: strategy?._id,
                record: values,
            },
            onCompleted: () => {
                props.onSave();
            },
            onError: e => { message.error('Save default strategy failed' + e.message); }
        });
    };

    const [isCashPurchase, setIsCashPurchase] = useState(mapPropsToFields(strategy)['isCashPurchase']);

    return (
        <Form
            layout="vertical"
            className="stepForm"
            onFinish={handleSubmit}
            style={{ margin: '20px' }}
            scrollToFirstError
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            fields={mapPropsToFields(strategy)}
            form={form}
        >
            <BasicRow>
                <Col lg={12} xs={24}>
                    <Form.Item label={CLOSING_COST} name="closingCost">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={REPAIR_COST} name="repairCost">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </BasicRow>

            <Row>
                <h2 style={{ float: 'left' }}>Loan Details</h2>
            </Row>

            <Row style={{ marginTop: '12px' }}>
                <h4 style={{ float: 'left' }}>Cash Purchase?</h4>
                <Form.Item name="isCashPurchase" valuePropName="checked">
                    <Checkbox style={{ float: 'left', marginLeft: '10px' }} onChange={e => {
                        setIsCashPurchase(e.target.checked);
                    }}/>
                </Form.Item>
            </Row>

            {!isCashPurchase && (
                <>
                    <BasicRow>
                        <Col lg={12} xs={24}>
                            <Form.Item label={DOWN_PAYMENT} name="downPayment">
                                <FormInputNumber percentage max={100} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item label={LOAN_INTEREST_RATE} name="loanInterestRate">
                                <FormInputNumber percentage />
                            </Form.Item>
                        </Col>
                    </BasicRow>

                    <Row>
                        <Col lg={12} xs={24}>
                            <Form.Item label={POINTS_CHARGED_BY_LENDER} name="pointsChargedByLender">
                                <FormInputNumber percentage />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item label={OTHER_CHARGES_FROM_THE_LENDER} name="otherChargesFromLender">
                                <FormInputNumber dollar />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '12px' }}>
                        <h4 style={{ float: 'left' }}>Are loan fees & points wrapped into the loan?</h4>
                        <Form.Item name="wrapLoanFeesIntoLoan" valuePropName="checked">
                            <Checkbox style={{ float: 'left', marginLeft: '10px' }} />
                        </Form.Item>
                    </Row>

                    <Row>
                        <Form.Item label={AMORTIZED_YEARS} name="amortizedYears">
                            <FormInputNumber />
                        </Form.Item>
                    </Row>
                </>
            )}

            <Row>
                <h2 style={{ float: 'left' }}>Fixed Landlord-Paid Expenses</h2>
            </Row>

            <BasicRow>
                <Col lg={12} xs={24}>
                    <Form.Item label={ELECTRICITY} name="electricity">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={WATER_AND_SEWER} name="waterAndSewer">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={GARBAGE} name="garbage">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={HOA} name="hoa">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                    <Form.Item label={PMI} name="pmi">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={MONTHLY_INSURANCE} name="monthlyInsurance">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </BasicRow>

            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item label={OTHER_MONTHLY_EXPENSES} name="other_monthlyExpenses">
                        <FormInputNumber dollar />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <h2 style={{ float: 'left' }}>Variable Landlord-Paid Expenses</h2>
            </Row>

            <BasicRow>
                <Col lg={12} xs={24}>
                    <Form.Item label={VACANCY} name="vacancy">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                    <Form.Item label={MAINTENANCE} name="maintenance">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={CAPEX} name="capex">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={MANAGEMENT_FEES} name="managementFees">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>
            </BasicRow>

            <Row>
                <h2 style={{ float: 'left' }}>Future Assumptions</h2>
            </Row>

            <BasicRow>
                <Col lg={12} xs={24}>
                    <Form.Item label={ANNUAL_INCOME_GROWTH} name="annualIncomeGrowth">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                    <Form.Item label={ANNUAL_PV_GROWTH} name="annualPropertyValueGrowth">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={ANNUAL_EXPENSES_GROWTH} name="annualExpensesGrowth">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                    <Form.Item label={SALES_EXPENSES} name="salesExpenses">
                        <FormInputNumber percentage />
                    </Form.Item>
                </Col>
            </BasicRow>


            <BasicRow>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </BasicRow>
        </Form>
    );
};

export default DefaultStrategyForm;
