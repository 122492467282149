import React, { useContext, useState } from 'react';
import { WindowWidthContext } from 'AppLayout';
import RentalLgCard from './RentalLgCard';
import RentalSmCard from './RentalSmCard';
import RentalMdCard from './RentalMdCard';
import PropertyInfoForm from '../PropertyInfoForm';
import PurchaseInfoForm from '../PurchaseInfoForm';
import RentalInfoForm from '../RentalInfoForm';
import RehabInfoForm from '../RehabInfoForm';
import Report from '../Report/Report';
import { Breadcrumb, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import RentalSider from './RentalSider';
import { WindowWidth } from 'helpers';

export interface RentalCardProps {
    loading: boolean;
    isSharedCard: boolean;
    reportId?: string;
    currentReport?: any;
    fetchWebsiteLoading?: boolean;
    resetReport?: any;
    saveReport?: any;
    applyDefault?: any;
    location: any;
    onChangeRentalType: any;
    onChangeBRRRR: any;
}

export interface RentalCardUIProps extends RentalCardProps {
    shouldValidate: boolean;
    setValidated: any;
    onValidationSuccess: any;
    onValidationFailed: any;
    isBRRRR: boolean;

    step: number;
    setStep: any;
    onSetStep: (_nextStep: number) => void;

    getPhotos: () => JSX.Element;
    getBreadcrumbs: () => JSX.Element;
    getSkeleton: () => JSX.Element;

    propertyUrl?: string | null;

    elements: {
        PropertyInfoForm: (props: any) => JSX.Element | null;
        PurchaseInfoForm: (props: any) => JSX.Element;
        RehabInfoForm: (props: any) => JSX.Element;
        RentalInfoForm: (props: any) => JSX.Element;
        RentalCardSider: (props: any) => JSX.Element;
        Report: (props: any) => JSX.Element;
    }
}

export const RentalCard = (props: RentalCardProps) => {
    const windowWidth = useContext(WindowWidthContext);

    const [step, setStep] = useState(0);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [nextStep, setNextStep] = useState(0);
    const setValidated = () => { setShouldValidate(false); };

    const getPhotos = () => {
        const photos: any = props.currentReport?.propertyInfo?.photos;
        if (photos != null && photos.length > 0) {
            return photos;
        } else {
            return null;
        }
    };

    const getFromBreadcrumb = (from) => {
        if (from == null) return null;
        switch (from) {
            case '/rental-watchlist':
                return <Breadcrumb.Item><Link to={from}>Watchlist</Link></Breadcrumb.Item>;
            case '/rental-reports':
                return <Breadcrumb.Item><Link to={from}>Previous Reports</Link></Breadcrumb.Item>;
        }
    };
    const getBreadcrumbs = () => {
        return <Breadcrumb style={{ margin: '16px 0', display: 'inline-block' }}>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            {getFromBreadcrumb(props.location.state?.from)}
            <Breadcrumb.Item>
                Rental Analysis
            </Breadcrumb.Item>
        </Breadcrumb>;
    };

    const getSkeleton = () =>
        <div style={{ padding: '16px' }}>
            <Skeleton active paragraph={{ rows: 5 }} />
            <Skeleton active paragraph={{ rows: 5 }} />
            <Skeleton active paragraph={{ rows: 5 }} />
        </div>;

    const isBRRRR = !!props.currentReport?.isBRRRR;
    const onSetStep = (_nextStep: number) => {
        const max = isBRRRR ? 4 : 2;
        if (_nextStep > max || step > max) {
            setStep(_nextStep);
            return;
        }
        setShouldValidate(true);
        setNextStep(_nextStep);
    };

    const onValidationSuccess = () => {
        setStep(nextStep);
    };

    const onValidationFailed = () => {
        setShouldValidate(false);
    };

    const elements = {
        PropertyInfoForm,
        PurchaseInfoForm,
        RehabInfoForm,
        RentalInfoForm,
        RentalCardSider: RentalSider,
        Report,
    };

    if (windowWidth === WindowWidth.LG) {
        return <RentalLgCard
            {...props}
            shouldValidate={shouldValidate}
            setValidated={setValidated}
            onValidationFailed={onValidationFailed}
            onValidationSuccess={onValidationSuccess}
            step={step}
            setStep={setStep}
            onSetStep={onSetStep}
            getBreadcrumbs={getBreadcrumbs}
            getPhotos={getPhotos}
            getSkeleton={getSkeleton}
            isBRRRR={isBRRRR}
            elements={elements}
        />;
    } else if (windowWidth === WindowWidth.MD) {
        return <RentalMdCard
            {...props}
            shouldValidate={shouldValidate}
            setValidated={setValidated}
            onValidationFailed={onValidationFailed}
            onValidationSuccess={onValidationSuccess}
            step={step}
            setStep={setStep}
            onSetStep={onSetStep}
            getBreadcrumbs={getBreadcrumbs}
            getPhotos={getPhotos}
            getSkeleton={getSkeleton}
            isBRRRR={isBRRRR}
            elements={elements}
        />;
    } else {
        return <RentalSmCard
            {...props}
            shouldValidate={shouldValidate}
            setValidated={setValidated}
            onValidationFailed={onValidationFailed}
            onValidationSuccess={onValidationSuccess}
            step={step}
            setStep={setStep}
            onSetStep={onSetStep}
            getBreadcrumbs={getBreadcrumbs}
            getPhotos={getPhotos}
            getSkeleton={getSkeleton}
            propertyUrl={props.currentReport?.propertyUrl}
            isBRRRR={isBRRRR}
            elements={elements}
        />;
    }
};
