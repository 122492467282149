import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Input, InputNumber, Form } from 'antd';
import _ from 'lodash';
import { FormInstance } from 'antd/lib/form';

const InputGroup = Input.Group;

export const getAssetsFormItems = ({ assetsLength, removeAsset }: { assetsLength: number; removeAsset }) => {
    return _.range(assetsLength).map((id) => (
        <Row justify="space-between" key={id} style={{ marginBottom: '12px' }}>
            <Col lg={12} sm={16} xs={20}>
                <InputGroup compact style={{ float: 'left' }}>
                    <Form.Item
                        name={`asset_keys_${id}`}
                        rules={[{ required: true, message: 'Please enter asset category' }]}
                        noStyle
                    >
                        <Input className="expense-input-group-left" placeholder="Asset class" />
                    </Form.Item>

                    <Form.Item
                        name={`asset_values_${id}`}
                        rules={[{ required: true, message: 'Please enter asset amount' }]}
                        noStyle
                    >
                        <InputNumber
                            className="expense-input-group-right"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => (value == null ? '' : value.replace(/\$\s?|(,*)/g, ''))}
                        />
                    </Form.Item>
                </InputGroup>
            </Col>
            <Col span={4}>
                <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ float: 'right', marginTop: '8px' }}
                    onClick={() => removeAsset(id)}
                />
            </Col>
        </Row>
    ));
};


export const getRetirementAccountsFormItems = ({ retirementAccountsLength, removeRetirementAccount }: { retirementAccountsLength: number; removeRetirementAccount }) => {
    return _.range(retirementAccountsLength).map((id) => (
        <Row justify="space-between" key={id} style={{ marginBottom: '12px' }}>
            <Col lg={12} sm={16} xs={20}>
                <InputGroup compact style={{ float: 'left' }}>
                    <Form.Item
                        name={`retirement_keys_${id}`}
                        rules={[{ required: true, message: 'Please enter retirement account category' }]}
                        noStyle
                    >
                        <Input className="expense-input-group-left" placeholder="Retirement account" />
                    </Form.Item>

                    <Form.Item
                        name={`retirement_values_${id}`}
                        rules={[{ required: true, message: 'Please enter retirement account amount' }]}
                        noStyle
                    >
                        <InputNumber
                            className="expense-input-group-right"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => (value == null ? '' : value.replace(/\$\s?|(,*)/g, ''))}
                        />
                    </Form.Item>
                </InputGroup>
            </Col>
            <Col span={4}>
                <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ float: 'right', marginTop: '8px' }}
                    onClick={() => removeRetirementAccount(id)}
                />
            </Col>
        </Row>
    ));
};

export const getOnClickAddAsset = (form: FormInstance, addAsset: () => void) => {
    return () => {
        form.validateFields()
            .then(() => {
                addAsset();
            })
            .catch((errors) => {
                form.scrollToField(errors.errorFields[0].name);
            });
    };
};

export const getOnClickAddRetirementAccount = (form: FormInstance, addRetirementAccount: () => void) => {
    return () => {
        form.validateFields()
            .then(() => {
                addRetirementAccount();
            })
            .catch((errors) => {
                form.scrollToField(errors.errorFields[0].name);
            });
    };
};

