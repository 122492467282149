import React from 'react';
import { Form, DatePicker } from 'antd';
import { AppState } from 'redux/store';
import { connect } from 'react-redux';
import { setDate } from 'redux/actions/netWorthTracker';
import { FieldData } from 'helpers';
import moment from 'moment';

const monthFormat = 'YYYY/MM';

const Date = (props) => {
    const getFields = () => {
        const fields: FieldData[] = [];
        if (props.date != null) {
            fields.push({ name: ['date'], value: moment(props.date, monthFormat) });
        } else {
            fields.push({ name: ['date'], value: moment() });
        }
        return fields;
    };
    return (
        <Form
            layout="horizontal"
            fields={getFields()}
            onValuesChange={(values) => {
                if (values.date) {
                    props.setDate(values.date.format(monthFormat));
                }
            }}
            style={{ textAlign: 'left' }}
        >
            <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                <DatePicker format="YYYY/MM" picker="month" />
            </Form.Item>
        </Form>
    );
};

interface StoreState {
    date?: string;
}

const mapStateToProps = (state: AppState): StoreState => ({
    date: state.netWorthTracker.currentNetWorthInfo.date,
});

interface Dispatch {
    setDate: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        setDate: (date: string) => {
            dispatch(setDate(date));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Date);
