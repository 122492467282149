import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Button, Modal, message } from 'antd';
import firebase from 'firebase/app';
import 'firebase/auth';
import SignInOnlyButton from './SignInOnlyButton';
import DefaultStrategyForm from 'rental/DefaultStrategyForm';
import { commitLocalUpdate } from 'react-relay';
import RelayEnvironment from '../RelayEnvironment';

interface Props {
    visible?: boolean;
    onCancelModal: any;
}

interface State {
    isSignedIn: boolean;
    strategyModalVisible: boolean;
}

export class SignInModal extends React.Component<Props, State> {
    state = {
        isSignedIn: false,
        strategyModalVisible: false,
    };

    unregisterAuthObserver: any;

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => {
                this.props.onCancelModal();
                message.success('Sign In Successful');
                return false;
            },
        },
    };

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
        this.unregisterAuthObserver = firebase
            .auth()
            .onAuthStateChanged((user) => this.setState({ isSignedIn: !!user }));
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    signOut = () => {
        firebase.auth().signOut();
        commitLocalUpdate(RelayEnvironment, store => {
            store.getRoot().invalidateRecord();
        });

        this.props.onCancelModal();
        message.success('Sign Out Successful');
    };

    openStrategyModal = () => {
        this.setState({ strategyModalVisible: true });
    };

    closeStrategyModal = () => {
        this.setState({ strategyModalVisible: false });
    };

    render() {
        if (!this.state.isSignedIn) {
            return (
                <Modal
                    title="Sign In"
                    visible={this.props.visible}
                    onOk={this.props.onCancelModal}
                    onCancel={this.props.onCancelModal}
                    footer={null}
                >
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                </Modal>
            );
        } else {
            const user = firebase.auth().currentUser || { displayName: '' };
            return (
                <Modal
                    title="Sign Out"
                    visible={this.props.visible}
                    onOk={this.props.onCancelModal}
                    onCancel={this.props.onCancelModal}
                    footer={null}
                >
                    <p>Welcome {user.displayName}! You are now signed-in!</p>
                    <div style={{ textAlign: 'center' }}>
                        <SignInOnlyButton className="report-button" onClick={this.openStrategyModal}>
                            Set Default Strategy
                        </SignInOnlyButton>
                    </div>

                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                        <Button onClick={this.signOut}>Sign-out</Button>
                    </div>
                    <Modal
                        title="Default Strategy"
                        visible={this.state.strategyModalVisible}
                        width={800}
                        style={{ top: 20 }}
                        footer={null}
                        onCancel={this.closeStrategyModal}
                    >
                        <DefaultStrategyForm onSave={this.closeStrategyModal} />
                    </Modal>
                </Modal>
            );
        }
    }
}
