/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RedfinEmailListingItemFragment = {
    readonly _id: unknown;
    readonly id: string;
    readonly address: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly zipcode: string | null;
    readonly listingPrice: number | null;
    readonly status: string | null;
    readonly url: string | null;
    readonly date: unknown | null;
    readonly totalSqft: number | null;
    readonly bedrooms: number | null;
    readonly bathrooms: number | null;
    readonly estimatedRent: number | null;
    readonly rentToPrice: number | null;
    readonly photo: string | null;
    readonly filterLabel: string | null;
    readonly archived: boolean | null;
    readonly listingHistory: ReadonlyArray<{
        readonly status: string | null;
        readonly listingPrice: number | null;
        readonly date: unknown | null;
    } | null> | null;
    readonly " $refType": "RedfinEmailListingItemFragment";
};
export type RedfinEmailListingItemFragment$data = RedfinEmailListingItemFragment;
export type RedfinEmailListingItemFragment$key = {
    readonly " $data"?: RedfinEmailListingItemFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"RedfinEmailListingItemFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "listingPrice",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RedfinEmailListingItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipcode",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSqft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bedrooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bathrooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedRent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentToPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filterLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RedfinEmailListingListingHistory",
      "kind": "LinkedField",
      "name": "listingHistory",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "RedfinEmailListing",
  "abstractKey": null
};
})();
(node as any).hash = '5c34b840985af624c9c28ea8754a2efc';
export default node;
