import React, { useEffect } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import * as actions from 'redux/actions/netWorthTracker';
import NetWorthChart from './NetWorthChart';

interface Props {
    populateNetWorthDashboard: any;
    firebaseAuthLoaded: boolean;
}

const NetWorthDashboard = (props: Props) => {
    const { firebaseAuthLoaded, populateNetWorthDashboard } = props;

    useEffect(() => {
        if (firebaseAuthLoaded) {
            populateNetWorthDashboard();
        }
    }, [firebaseAuthLoaded, populateNetWorthDashboard]);

    return (
        <div>
            <header style={{ height: '10vh', textAlign: 'center' }}>
                <h1 style={{ paddingTop: '4vh' }}> Net Worth Dashboard </h1>
            </header>
            <Card bordered={false} className="split-card">
                <div className="budget-content">
                    <NetWorthChart />
                </div>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    currentReportId: state.budget.currentReportId,
    firebaseAuthLoaded: state.firebase.auth.isLoaded,
});

interface Dispatch {
    populateNetWorthDashboard: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        populateNetWorthDashboard: () => {
            dispatch(actions.populateNetWorthCard());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetWorthDashboard);
