import React from 'react';
import { message } from 'antd';
import PreviousReportCard from 'components/PreviousReportCard';
import { getTimeAgoString } from 'helpers';
import { EnhancedRentalReportNode } from './RentalReportList';
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import RelayEnvironment from '../../RelayEnvironment';
import { PreviousRentalReportCardMutation } from './__generated__/PreviousRentalReportCardMutation.graphql';

interface Props {
    reportInfo: EnhancedRentalReportNode;
    from?: string;
    connectionID?: string;
}

const mutation = graphql`
    mutation PreviousRentalReportCardMutation(
        $id: MongoID!,
        $connections: [ID!]!,
    ) {
        RentalReportRemoveById(input: {_id: $id }) {
            recordId
            record {
                id @deleteEdge(connections: $connections)
            }
        }
    }
`;

const PreviousRentalReportCard = (props: Props) => {
    const getDerivedProps = (props: Props): {
        reportId: string | null,
        address: string | null,
        linkToPath: string,
        timeAgoString: string | null,
        purchasePrice?: number | null,
        cashflow?: number | null,
        cashOnCash?: number | null,
        monthlyRent?: number | null,
        watchlistStatus?: string | null;
        isShortTermRental?: boolean | null;
    } => {
        const reportInfo = props.reportInfo;
        const propertyInfo = reportInfo?.propertyInfo;
        if (propertyInfo == null) {
            return {
                reportId: '',
                address: '',
                linkToPath: '',
                timeAgoString: '',
            };
        }
        let address = propertyInfo.address;
        if (propertyInfo.city != null && propertyInfo.city.length > 0) {
            address += ', ' + propertyInfo.city;
        }
        if (propertyInfo.state != null && propertyInfo.state.length > 0) {
            address += ' ' + propertyInfo.state;
        }
        if (propertyInfo.zipcode != null && propertyInfo.zipcode.length > 0) {
            address += ' ' + propertyInfo.zipcode;
        }

        return {
            reportId: reportInfo._id as string,
            address,
            linkToPath: '/rental/' + (reportInfo._id as string),
            timeAgoString: getTimeAgoString(reportInfo.createTime),
            purchasePrice: reportInfo.purchaseInfo?.purchasePrice,
            cashflow: reportInfo.cashflow,
            cashOnCash: reportInfo.cashOnCash,
            monthlyRent: reportInfo.rentalInfo?.monthlyRent,
            watchlistStatus: reportInfo.watchlistStatus,
            isShortTermRental: reportInfo.isShortTermRental,
        };
    };

    const getCoverPhoto = () => {
        const photos = props.reportInfo?.propertyInfo?.photos as any[];
        if (photos == null || photos.length === 0) {
            return null;
        } else {
            return photos[0];
        }
    };

    const { reportId, address, linkToPath, timeAgoString, purchasePrice, cashflow, cashOnCash, monthlyRent, watchlistStatus, isShortTermRental } = getDerivedProps(
        props,
    );

    const removeRentalReport = () => {
        if (props.connectionID != null) {
            commitMutation<PreviousRentalReportCardMutation>(RelayEnvironment, {
                mutation: mutation,
                variables: {
                    id: reportId === 'client:RentalReport' ? undefined : reportId,
                    connections: [props.connectionID],
                },
                onCompleted: null,
                onError: e => { message.error('Delete report failed' + e.message); }
            });
        }
    };

    return (
        <PreviousReportCard
            reportId={reportId}
            address={address}
            linkToPath={linkToPath}
            timeAgoString={timeAgoString}
            purchasePrice={purchasePrice}
            monthlyRent={monthlyRent}
            cashflow={cashflow}
            cashOnCash={cashOnCash}
            watchlistStatus={watchlistStatus}
            isShortTermRental={isShortTermRental}
            coverPhoto={getCoverPhoto()}
            from={props.from}
            deleteReport={removeRentalReport}
        />
    );
};

export default PreviousRentalReportCard;
