import React from 'react';
import { Button, Row } from 'antd';
import { RentalStep } from './types';

interface Props {
    previousStep: any;
    nextStep: any;
    step: RentalStep;
    disabled?: boolean;
    isBRRRR?: boolean;
}

const NavigationButtons = (props: Props) => {
    const prev = () => {
        props.previousStep(true);
    };

    const next = () => {
        props.nextStep(true);
    };

    const current = props.step;
    const maxStep = props.isBRRRR ? 5 : 3;

    return (
        <Row style={{ marginTop: '16px' }} justify="end">
            {current > 0 && (
                <Button style={{ marginRight: 8 }} onClick={prev} disabled={props.disabled}>
                    Previous
                </Button>
            )}
            {current < maxStep && (
                <Button type="primary" onClick={next} disabled={props.disabled}>
                    Next
                </Button>
            )}
        </Row>
    );
};

export default NavigationButtons;
