import React from 'react';
import { Layout } from 'antd';
import SplitPane from 'react-split-pane';
import ReportButtons from '../Report/ReportButtons';
import { RentalCardUIProps } from './RentalCard';
import RefinanceInfoForm from 'rental/RefinanceInfoForm';

const { Content } = Layout;

const RentalLgCard = (props: RentalCardUIProps) => {
    const { loading, isSharedCard, currentReport, reportId, fetchWebsiteLoading, resetReport, saveReport, applyDefault, onValidationSuccess, onValidationFailed, shouldValidate, step, onSetStep, setValidated, getPhotos, getBreadcrumbs, getSkeleton, elements, isBRRRR } = props;

    const { PropertyInfoForm, PurchaseInfoForm, RentalInfoForm, RentalCardSider, RehabInfoForm, Report } = elements;

    const propertyInfo = (index) => ({
        title: 'Property',
        content: (
            <PropertyInfoForm
                propertyInfo={currentReport?.propertyInfo}
                onValidationSuccess={onValidationSuccess}
                onValidationFailed={onValidationFailed}
                fetchWebsiteLoading={fetchWebsiteLoading}
                shouldValidate={shouldValidate && step === index}
                setValidated={setValidated}
                reportId={currentReport?.id}
            />
        ),
    });

    const purchaseInfo = (index) => ({
        title: 'Purchase',
        content: (
            <PurchaseInfoForm
                purchaseInfo={currentReport?.purchaseInfo}
                closingCost={currentReport?.closingCostBreakdown}
                repairCost={currentReport?.repairCostBreakdown}
                onValidationSuccess={onValidationSuccess}
                onValidationFailed={onValidationFailed}
                shouldValidate={shouldValidate && step === index}
                setValidated={setValidated}
                reportId={currentReport?.id}
                isBRRRR={isBRRRR}
            />
        ),
    });

    const rentalInfo = (index) => ({
        title: 'Rental',
        content: (
            <RentalInfoForm
                rentalInfo={currentReport?.rentalInfo}
                onValidationSuccess={onValidationSuccess}
                onValidationFailed={onValidationFailed}
                shouldValidate={shouldValidate && step === index}
                setValidated={setValidated}
                reportId={currentReport?.id}
                isShortTermRental={currentReport?.isShortTermRental}
            />
        ),
    });

    const steps = isBRRRR ? [
        propertyInfo(0),
        purchaseInfo(1),
        {
            title: 'Rehab',
            content: (
                <RehabInfoForm
                    rehabInfo={currentReport?.repairCostBreakdown}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 2}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
        rentalInfo(3),
        {
            title: 'Refinance',
            content: (
                <RefinanceInfoForm
                    refinanceInfo={currentReport?.refinanceInfo}
                    onValidationSuccess={onValidationSuccess}
                    onValidationFailed={onValidationFailed}
                    shouldValidate={shouldValidate && step === 4}
                    setValidated={setValidated}
                    reportId={currentReport?.id}
                />
            ),
        },
    ] : [
        propertyInfo(0),
        purchaseInfo(1),
        rentalInfo(2),
    ];
    const current = Math.min(steps.length, step);

    return (
        <Layout>
            <RentalCardSider
                setStep={onSetStep}
                step={current}
                currentReport={currentReport}
                photos={getPhotos()}
                width={250}
                loading={loading}
                sharedCard={!!isSharedCard}
            />
            <Layout style={{ padding: '0 24px', backgroundColor: '#fbfbfb' }}>
                <div>
                    {getBreadcrumbs()}
                    {!isSharedCard && (
                        <ReportButtons
                            graphqlId={currentReport?.id}
                            mobile
                            reportId={reportId}
                            onSave={saveReport}
                            onReset={resetReport}
                            watchlistStatus={currentReport?.watchlistStatus ?? null}
                            applyDefault={applyDefault}
                            enableRentalTypeButton
                            rentalTypeLabel={
                                currentReport == null || !currentReport.isShortTermRental
                                    ? 'Switch to Short Term Rental'
                                    : 'Switch to Long Term Rental'
                            }
                            onChangeRentalType={props.onChangeRentalType}
                            enableBRRRRButton
                            brrrrLabel={
                                currentReport?.isBRRRR
                                    ? 'Switch to Rental'
                                    : 'Switch to BRRRR'
                            }
                            onChangeBRRRR={props.onChangeBRRRR}
                        />
                    )}
                </div>
                <Content>
                    <SplitPane
                        split="vertical"
                        minSize={600}
                        defaultSize={700}
                        maxSize={window.innerWidth - 800}
                        style={{ position: 'static', backgroundColor: '#fbfbfb' }}
                    >
                        <div className="split-card" style={{ paddingRight: '24px' }}>
                            <div>{loading ? getSkeleton() : steps[current].content}</div>
                        </div>

                        <div className="split-card">
                            {loading ? getSkeleton() : <Report currentReport={currentReport} />}
                        </div>
                    </SplitPane>
                </Content>
            </Layout>
        </Layout>
    );
};

export default RentalLgCard;
