import React from 'react';
import { Table } from 'antd';
import { AnnualAnalysis } from '../types';
import { formatDollar, formatPercentage, formatDollarRounded } from '../../helpers';

interface Props {
    dataSource: AnnualAnalysis[];
}

const AnalysisOverTime = (props: Props) => {
    const { dataSource } = props;

    let convertedData: any[] = [
        {
            key: 'propertyValue',
            title: 'Property Value',
            transformer: formatDollar,
        },
        {
            key: 'income',
            title: 'Income',
            transformer: formatDollar,
        },
        {
            key: 'expenses',
            title: 'Operating Expenses',
            transformer: formatDollar,
        },
        {
            key: 'mortgage',
            title: 'Mortgage Payments',
            transformer: formatDollarRounded,
        },
        {
            key: 'coc',
            title: 'Annual Cash on Cash',
            transformer: formatPercentage,
        },
        {
            key: 'loanBalance',
            title: 'Loan Balance',
            transformer: formatDollarRounded,
        },
        {
            key: 'equity',
            title: 'Equity',
            transformer: formatDollarRounded,
        },
        {
            key: 'profitIfSold',
            title: 'Total Profit if Sold',
            transformer: formatDollarRounded,
        },
        {
            key: 'roi',
            title: 'ROI',
            transformer: formatPercentage,
        },
        {
            key: 'cagr',
            title: 'CAGR',
            transformer: formatPercentage,
        },
    ];

    dataSource.forEach((analysis) => {
        const year = analysis.year;
        convertedData = convertedData.map((column) => {
            const value = analysis[column.key];
            if (column.transformer) {
                column['year' + year] = column.transformer(value);
            } else {
                column['year' + year] = value;
            }
            return column;
        });
    });

    const columns = [
        {
            title: '',
            dataIndex: 'title',
            key: 'fieldName',
        },
        {
            title: 'Year 1',
            dataIndex: 'year1',
            key: 'year1',
        },
        {
            title: 'Year 2',
            dataIndex: 'year2',
            key: 'year2',
        },
        {
            title: 'Year 5',
            dataIndex: 'year5',
            key: 'year5',
        },
        {
            title: 'Year 10',
            dataIndex: 'year10',
            key: 'year10',
        },
        {
            title: 'Year 15',
            dataIndex: 'year15',
            key: 'year15',
        },
        {
            title: 'Year 20',
            dataIndex: 'year20',
            key: 'year20',
        },
        {
            title: 'Year 30',
            dataIndex: 'year30',
            key: 'year30',
        },
    ];

    return <Table style={{ overflowX: 'auto' }} dataSource={convertedData} columns={columns} pagination={false} showHeader />;
};

export default AnalysisOverTime;
