import React from 'react';
import { Tag } from 'antd';

interface Props {
    watchlistStatus?: string | null;
    style?: any;
}

const WatchlistStatusTag = (props: Props) => {
    const watchlistStatus = props.watchlistStatus;
    if (watchlistStatus == null) {
        return null;
    } else {
        switch (watchlistStatus) {
            case 'watchlist':
                return <Tag style={props.style} color='green'>Watchlist</Tag>;
            case 'backup':
                return <Tag style={props.style} color='lime'>Backup</Tag>;
            case 'madeOffer':
                return <Tag style={props.style} color='blue'>Made offer</Tag>;
            case 'portfolio':
                return <Tag style={props.style} color='green'>Portfolio</Tag>;
            case 'madeOfferButFailed':
                return <Tag style={props.style} color='orange'>Made offer but failed</Tag>;
            case 'pending':
                return <Tag style={props.style} color='magenta'>Pending</Tag>;
            case 'offMarket':
                return <Tag style={props.style} color='red'>Off market</Tag>;
        }
    }
    return null;
};

export default WatchlistStatusTag;
