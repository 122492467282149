import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Input, InputNumber, Form } from 'antd';
import _ from 'lodash';
import { FormInstance } from 'antd/lib/form';

const InputGroup = Input.Group;
export const getExpenseFormItems = ({ expensesLength, remove }: { expensesLength: number; remove }) => {
    return _.range(expensesLength).map((id) => (
        <Row justify="space-between" key={id}>
            <Col lg={12} sm={16} xs={20}>
                <InputGroup compact style={{ float: 'left' }}>
                    <Form.Item
                        name={`keys_${id}`}
                        rules={[{ required: true, message: 'Please enter expense description' }]}
                        noStyle
                    >
                        <Input className="expense-input-group-left" placeholder="Please enter expense description" />
                    </Form.Item>

                    <Form.Item
                        name={`values_${id}`}
                        rules={[{ required: true, message: 'Please enter expense amount' }]}
                        noStyle
                    >
                        <InputNumber
                            className="expense-input-group-right"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => (value == null ? '' : value.replace(/\$\s?|(,*)/g, ''))}
                        />
                    </Form.Item>
                </InputGroup>
            </Col>
            <Col span={4}>
                <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ float: 'right', marginTop: '8px' }}
                    onClick={() => remove(id)}
                />
            </Col>
        </Row>
    ));
};

export const getOnClickAdd = (form: FormInstance, add: any) => {
    return () => {
        form.validateFields()
            .then((values) => {
                add();
            })
            .catch((errors) => {
                form.scrollToField(errors.errorFields[0].name);
            });
    };
};
