import React from 'react';
import { connect } from 'react-redux';
import { Row, Button, message } from 'antd';
import { RESET_REPORT, addNewNetWorth } from 'redux/actions/netWorthTracker';
import { AppState } from 'redux/store';
import { MonthlyNetWorthInterface } from '../types';
import SignInOnlyButton from 'components/SignInOnlyButton';

interface Props extends StoreState, Dispatch {}

const Buttons = (props: Props) => {
    const reset = () => {
        props.resetReport();
    };

    const onSaveReportSuccess = () => {
        message.success('Add net worth succeeded');
    };

    const onSaveReportFailure = (e) => {
        message.error('Add net worth failed' + e.message);
    };

    const onClickSave = () => {
        props.addNewNetWorth(onSaveReportSuccess, onSaveReportFailure, props.currentNetWorthInfo);
    };

    return (
        <Row>
            <div className="report-buttons">
                <Button className="report-button" onClick={reset}>
                    Reset
                </Button>
                <SignInOnlyButton type="primary" className="report-button" onClick={onClickSave}>
                    Save
                </SignInOnlyButton>
            </div>
        </Row>
    );
};

interface StoreState {
    currentNetWorthInfo: MonthlyNetWorthInterface;
}

const mapStateToProps = (state: AppState): StoreState => ({
    currentNetWorthInfo: state.netWorthTracker.currentNetWorthInfo,
});

interface Dispatch {
    resetReport: any;
    addNewNetWorth: any;
}

const mapDispatchToProps = (dispatch): Dispatch => {
    return {
        resetReport: () => {
            dispatch({ type: RESET_REPORT });
        },
        addNewNetWorth: (onSuccess, onFailure, reportInfo) => {
            dispatch(addNewNetWorth(onSuccess, onFailure, reportInfo));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
