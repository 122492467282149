import { combineReducers } from 'redux';
import rental from './rental';
import budget from './budget';
import map from './map';
import mortgage from './mortgage';
import netWorthTracker from './netWorthTracker';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        rental,
        budget,
        map,
        mortgage,
        netWorthTracker,
        firebase: firebaseReducer,
    });
