const setMapNamespace = (actionType: string) => 'map/' + actionType;

// actionTypes
export const SET_MAP_TITLE = setMapNamespace('SET_MAP_TITLE');
export const POPULATE_MAP_CARD = setMapNamespace('POPULATE_MAP_CARD');
export const POPULATE_MAP_CARD_ASYNC = setMapNamespace('POPULATE_MAP_CARD_ASYNC');
export const FETCH_MAPS_SUCCEEDED = setMapNamespace('FETCH_MAPS_SUCCEEDED');
export const FETCH_MAPS_FAILED = setMapNamespace('FETCH_MAPS_FAILED');
export const FETCH_CENSUS_DATA = setMapNamespace('FETCH_CENSUS_DATA');
export const FETCH_CENSUS_DATA_SUCCEEDED = setMapNamespace('FETCH_CENSUS_DATA_SUCCEEDED');
export const FETCH_CENSUS_DATA_FAILED = setMapNamespace('FETCH_CENSUS_DATA_FAILED');

// actions
export const setMapTitle = (title) => ({ type: SET_MAP_TITLE, payload: { title } });

export const populateMapCard = (mapId: string) => ({
    type: POPULATE_MAP_CARD_ASYNC,
    payload: {
        mapId,
    },
});

export const fetchCensusData = () => ({
    type: FETCH_CENSUS_DATA,
});
