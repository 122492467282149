import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Axis, Chart, LineAdvance, Tooltip } from 'bizcharts';
import { MonthlyNetWorthInterface } from 'netWorthTracker/types';
import { checkNumber } from 'redux/selectors';

const invalidate = (netWorthRecords: MonthlyNetWorthInterface[]) => {
    return netWorthRecords.filter(record => record.date != null && record.summary.totalNetWorth != null);
};

const getData = (netWorthRecords: MonthlyNetWorthInterface[]) => {
    const data = netWorthRecords
        .map((netWorthRecord) => {
            return {
                date: netWorthRecord.date,
                netWorth: netWorthRecord.summary.totalNetWorth,
                info: netWorthRecord,
            };
        });
    return data;
};

const NetWorthChart = (props: StoreState) => {
    const { netWorthRecords } = props;

    if (netWorthRecords == null || netWorthRecords.length === 0) return null;
    const validatedRecords = invalidate(netWorthRecords);
    const data = getData(validatedRecords);
    if (data == null || data.length === 0) {
        return null;
    }
    const min = data.reduce((_min, record) => Math.min(checkNumber(record.netWorth), _min), 0);

    return (
        <div>
            <h3 style={{ textAlign: 'center', marginTop: '16px' }}>Net Worth</h3>
            <Chart
                padding={[50, 20, 50, 80]}
                autoFit
                height={400}
                scale={{ netWorth: { min: min } }}
                data={data}
            >
                <LineAdvance
                    shape="smooth"
                    point
                    area
                    position="date*netWorth"
                />
                <Axis name="netWorth" label={{ formatter: (text) => { return `$${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','); } }} />
                <Axis name="date" />
                <Tooltip>
                    {(title, items) => {
                        if (items == null || items.length < 1) return {};
                        const info: MonthlyNetWorthInterface = items[0].data.info;
                        const color = items[0].color;
                        return (
                            <>
                                <div style={{ marginBottom: '12px', marginTop: '12px' }}>
                                    {title}
                                </div>
                                <ul style={{ margin: '0', listStyleType: 'none', padding: '0' }}>
                                    <li key={0} style={{ listStyleType: 'none', padding: '0', margin: '12px 0px', textAlign: 'left' }}>
                                        <span style={{ background: color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px' }}></span>
                                        <span style={{ display: 'inline-block', marginLeft: '0px' }}>Net Worth: {`$${info.summary.totalNetWorth}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                    </li>

                                    <li key={1} style={{ listStyleType: 'none', padding: '0', margin: '12px 0px', textAlign: 'left' }}>
                                        <span style={{ background: color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px' }}></span>
                                        <span style={{ display: 'inline-block', marginLeft: '0px' }}>Assets: {`$${info.summary.totalAssets}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                    </li>

                                    <li key={2} style={{ listStyleType: 'none', padding: '0', margin: '12px 0px', textAlign: 'left' }}>
                                        <span style={{ background: color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px' }}></span>
                                        <span style={{ display: 'inline-block', marginLeft: '0px' }}>Liabilities: {`$${info.summary.totalLiabilities}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                    </li>
                                </ul>
                            </>
                        );
                    }}
                </Tooltip>
            </Chart>
        </div>
    );
};

interface StoreState {
    netWorthRecords?: MonthlyNetWorthInterface[],
}

const mapStateToProps = (state: AppState): StoreState => ({
    netWorthRecords: state.netWorthTracker.netWorthRecords,
});


export default connect(mapStateToProps, null)(NetWorthChart);
